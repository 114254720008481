import {dateToIsoString, formatDate} from "../../../../Utils/DateTimeUtils";
import {CButton} from "@coreui/react";
import moment from "moment";

const leadsColumns = [
    {
        name: "Customer",
        selector: row => row.form_submission?.name,
        format: row => {
            const fieldNames = ["name", "email", "phone"];
            const attributes = [];
            fieldNames.map(fieldName => {
                const value = row.form_submission?.[fieldName];
                if (value) attributes.push(value);
            });
            return <span class="display-line-break">{attributes.length ? attributes.join('\n') : "N/A"}</span>;
        },
        width: "250px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
];

const commonColumns = [
    {
        name: 'Subject',
        selector: row => row.messages?.find(message => message?.role === "user")?.content,
        width: "300px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Time',
        selector: row => new Date(row.created_at),
        format: row => {
            const date = moment(row.created_at);
            return formatDate(date.format("YYYY-MM-DD"), date.format("HH:mm:ss"));
        },
        width: "300px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Messages',
        selector: row => row.messages.length,
        width: "125px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
];

export const columns = (displayLeads, onViewButtonClick) => [
    ...commonColumns,
    ...(displayLeads ? leadsColumns : []),
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onViewButtonClick(row)}>
                    View
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    }
];

export const filterCategories = [
    {
        name: 'Name',
        category: 'name',
        type: 'exact',
    },
    {
        name: 'Email',
        category: 'email',
        type: 'exact',
    },
    {
        name: 'Phone',
        category: 'phone',
        type: 'exact',
    },
    {
        name: 'Date',
        category: ['start_date', 'end_date'],
        type: 'range',
    },
    {
        name: 'Messages',
        category: ['min_length', 'max_length'],
        type: 'range',
    },
];

export const applyFilters = (conversations, filters) => {
    return conversations.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                entry.messages?.find(message => message?.role === "user")?.content?.toLowerCase().includes(searchTerm) ||
                entry.form_submission?.name.toLowerCase().includes(searchTerm) ||
                entry.form_submission?.email.toLowerCase().includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.name) {
            const searchTerm = filters.name.toLowerCase();
            if (!entry.form_submission?.name.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email.toLowerCase();
            if (!entry.form_submission?.email.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.phone) {
            const searchTerm = filters.phone.toLowerCase();
            if (!entry.form_submission?.phone.includes(searchTerm)) return false;
        }

        const createdDate = dateToIsoString(new Date(entry.created_at));
        if (filters.start_date) {
            if (createdDate < filters.start_date) return false;
        }

        if (filters.end_date) {
            if (createdDate > filters.end_date) return false;
        }

        if (filters.min_length) {
            if (entry.messages.length < filters.min_length) return false;
        }

        if (filters.max_length) {
            if (entry.messages.length > filters.max_length) return false;
        }

        return true;
    });
};
