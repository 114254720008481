import {
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CImage
} from "@coreui/react";
import {formatDate} from "../../../../Utils/DateTimeUtils";
import {bookvenues, users} from "constants";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {cilPencil, cilPlus, cilTrash} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    updateTask,
    SITE_URL
} from "../../../../redux/action/action";
import {useNavigate} from "react-router-dom";
import {attendeesColumns} from "../../../../Utils/ProgramUtils";
import AddSessionModal from "../ProgramList/AddSessionModal";


const Session = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    
    const [sessions, setSessions] = useState([]);
    const [addSessionModalVisible, setAddSessionModalVisible] = useState(false);
    const [sessionFormData, setSessionFormData] = useState({});
    const [venues, setVenues] = useState([]);

    const onTaskStatusChange = (taskId, newStatus) => {
        dispatch(updateTask({
            id: taskId,
            status: newStatus,
        }));
    }

    const closeAddSessionModal = () => {
        setSessionFormData({});
        setAddSessionModalVisible(false);
    }

    useEffect(() => {
        setVenues([
            {
                id: 0,
                name: "No Change",
            },
            ...venueListings
        ]);
    }, [venueListings]);

    useEffect(() => {
        setSessions([
            {
                name: "John Doe",
                email: "jdoe0101@gmail.com",
                phone: "(555) 123-4567",
            },
            {
                name: "Ethan Carter",
                email: "ecarter8805@gmail.com",
                phone: "(375) 791-5330",
            },
            {
                name: "Mia Anderson",
                email: "mia23551@gmail.com",
                phone: "(639) 465-5731",
            },
            {
                name: "Ava Mitchell",
                email: "amitchell777@gmail.com",
                phone: "(391) 435-2201",
            },
            {
                name: "Lucas Bennett",
                email: "lucas2213@gmail.com",
                phone: "(835) 430-2947",
            },
            {
                name: "Sophia Hayes",
                email: "shayes2070@gmail.com",
                phone: "(220) 725-3570",
            },
        ])
    }, [])

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <h2>Session: Beginner Pickleball</h2>
                        <div className="flex-row">
                            <CButton color="light" onClick={() => navigate("/business/programs/create-program/1")}>
                                <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit
                            </CButton>
                            <CButton color="danger" className="white ms-2">
                                <CIcon icon={cilTrash} className="white"/> &nbsp; Delete
                            </CButton>
                        </div>       
                    </div>
                    <hr/>
                    <div>
                        <h4 className="mb-3 pe-2">Session Detail</h4>
                        <div className="flex-row">
                            <div style={{width: "35%"}}>
                                <CCard className="me-3 shadow-3">
                                    <CCardBody className="p-4">
                                        <div>
                                            <h5>Schedule Information</h5>
                                            <p>
                                                <b className="event-modal-information-title">Time</b><br/>
                                                5:00pm - 6:30pm
                                            </p>
                                            <p>
                                                <b className="event-modal-information-title">Day</b><br/>
                                                M W F
                                            </p>
                                            <p>
                                                <b className="event-modal-information-title">Start Date</b><br/>
                                                Monday, November 4, 2024
                                            </p>
                                            <p>
                                                <b className="event-modal-information-title">End Date</b><br/>
                                                Friday, March 7, 2025
                                            </p>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </div>
                            <div style={{width: "35%"}}>
                            <CCard className="me-3 shadow-3 h-100">
                                    <CCardBody className="p-4">
                                        <div>
                                            <h5>Logistics Information</h5>
                                            <p>
                                                <b className="event-modal-information-title">Instructor</b><br/>
                                                Jerry Tudor
                                            </p>
                                            <p>
                                                <b className="event-modal-information-title">Venue</b><br/>
                                                Highland Park Pickleball Court
                                            </p>
                                        </div>
                                    </CCardBody>
                                </CCard>
                            </div>
                            <div style={{width: "30%"}}>
                                <CCard className="shadow-3 h-100">
                                    <CCardBody className="p-4">
                                        <p>
                                            <h5>Enrollment Information</h5>
                                            <b className="event-modal-information-title">Availability</b><br/>
                                            12/30
                                        </p>
                                        <p>
                                            <b className="event-modal-information-title">Price</b><br/>
                                            $1500.00
                                        </p>
                                    </CCardBody>
                                </CCard>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row justify-content-between align-items-end mt-4">
                        <div>
                            <h4>Attendees</h4>
                        </div>
                    </div>
                    <DataTable
                        columns={[
                            ...attendeesColumns(onTaskStatusChange)
                        ]}
                        data={sessions}
                    />
                </CCardBody>
            </CCard>
        </div>
    );
};

export default Session;
