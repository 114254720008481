import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getNotification } from '../../redux/action/action'

function Notification() {
    document.title = "Notifications | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Manage and view your notifications on DoubleSpot to stay informed."
    );

    const dispatch = useDispatch()
    const notification = useSelector(state => state.Apis.notificatioList)

    const [data, setData] = useState([])
    // notificatioList
    useEffect(() => {
        setData(notification)
    }, [notification])

    useEffect(() => {
        dispatch(getNotification())
    }, [])

    const capitalizeFirstLetter = str => str.charAt(0).toUpperCase();

    function getTimeAgo(createdAt) {
        const currentTime = new Date();
        const createdTime = new Date(createdAt);
        const timeDifference = currentTime - createdTime;
      
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
      
        if (years > 0) {
          return years === 1 ? "1 year ago" : years + " years ago";
        } else if (months > 0) {
          return months === 1 ? "1 month ago" : months + " months ago";
        } else if (days > 0) {
          return days === 1 ? "1 day ago" : days + " days ago";
        } else if (hours > 0) {
          return hours === 1 ? "1 hour ago" : hours + " hours ago";
        } else {
          return minutes === 1 ? "1 minute ago" : minutes + " minutes ago";
        }
    }
      

    return (
        <>
            <section className="notify">
                <div className="container">
                    <h3 className="text-center py-2 mb-2">Notifications</h3>
                    <ul className="list-unstyled border rounded-4 not_box">
                        {notification && notification.length > 0 ?
                            notification.map((val, key) => {
                                return <>
                                    <li className="border-bottom">
                                        <div className="d-flex bd-highlight align-items-md-center gap-md-3 gap-2">
                                            <div className="img_cont">
                                                {capitalizeFirstLetter(val.senderName)}
                                            </div>
                                            <div className="user_info">
                                                <span className="d-md-flex align-items-md-center gap-md-2">{val?.senderName} <p className="mb-0 fw-normal">
                                                    {val?.message}.</p></span>
                                                <small className="fs-6 text-muted">{getTimeAgo(val.createdAt)}</small>
                                            </div>
                                        </div>
                                    </li>
                                </>

                            })
                            :
                            <>
                                <h4 className='text-danger'>No record found.</h4>
                            </>
                        }
                    </ul>
                    {/* <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-start">
                            <li className="page-item">
                                <a className="page-link text-black" href="#" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            <li className="page-item"><a className="page-link text-black" href="#">1</a></li>
                            <li className="page-item"><a className="page-link text-black" href="#">2</a></li>
                            <li className="page-item"><a className="page-link text-black" href="#">3</a></li>
                            <li className="page-item"><a className="page-link text-black" href="#">4</a></li>
                            <li className="page-item"><a className="page-link text-black" href="#">5</a></li>
                            <li className="page-item">
                                <a className="page-link text-black" href="#" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav> */}
                </div>
            </section>
        </>
    )
}

export default Notification
