import {  
    GET_USER_PROFILE_SUCCESS,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
    SUCCESS_LOGOUT,
    USER_LOGIN_API_SUCCESS, 
    USER_SIGNUP_API_SUCCESS,  
} from "../action/action";

    const intialState = {
    token: localStorage.getItem('token'),
    userRole: localStorage.getItem('userRole'),
    userId: localStorage.getItem('userId'),
    userDetail : {},
    isUserAuthenticated: '',
    isProviderAuthenticated: false,
    }

    const Login = (state = intialState , action) => {
        const { type, data } = action;
        switch(type){
            case USER_SIGNUP_API_SUCCESS:
                localStorage.setItem('token' , data["body"].token)
                localStorage.setItem('userRole' , data["body"]?.role)
                localStorage.setItem('userId' , data["body"]?.id)
                return {
                    ...state, 
                    userDetail : data["body"],
                } 
            case USER_LOGIN_API_SUCCESS:
                localStorage.setItem('token' , data[["body"]].token)
                localStorage.setItem('userRole' , data["body"]?.role)
                localStorage.setItem('userId' , data["body"]?.id)
                data["body"]?.verify_account == 1 && localStorage.setItem('va' , data["body"]?.verify_account)
                return {
                     ...state, 
                     userDetail : data["body"],
                 }  
            case VERIFY_SUCCESS:    
                data["body"]?.verify_account == 1 && localStorage.setItem('va' , data["body"]?.verify_account)
                return {
                    ...state, 
                    userDetail: data["body"],
                    isUserAuthenticated:data["body"]?.verify_account
                }
            case VERIFY_FAIL:
                return {
                    ...state,
                    isUserAuthenticated: false
                }
            case GET_USER_PROFILE_SUCCESS:
                data["body"]?.verify_account == 1 && localStorage.setItem('va' , data["body"]?.verify_account)
                return {
                    ...state,
                    userDetail : data["body"],
                    isUserAuthenticated:data["body"]?.verify_account
                } 
            case SUCCESS_LOGOUT:
                return {
                    ...state,
                    token: "",
                    userRole: "",
                    userId: "",
                    userDetail : {},
                    isUserAuthenticated: '',
                    isProviderAuthenticated: '',

                }
            default:
                return state;
        }
    }

export default Login