import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import {
    DEFAULT_URL,
    GOOGLE_PLACES_API_KEY,
    SITE_URL,
    recomendedVenues
} from "../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SearchPage() {
    document.title = "Search Results | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Find venues matching your specific preferences effortlessly on DoubleSpot's venue search page."
    );

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [placeIds, setPlaceIds] = useState([])
    const [searchPlace, setPlace] = useState(null);
    const [page, setPage] = useState(1)
    const venuesData = useSelector(state => state.Apis)
    const [formData, setFormData] = useState({
        date: "",
        place: "",
        time: "",
        size: "",
        facility: ""
    })
    const handlePlaceSelect = (place) => {
        setPlace(place?.formatted_address)
    }
    const [ libraries ] = useState(['places']);

    useEffect(() => {
        setFormData()
    }, [])


    const handleData = (e) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })

    function getMatchingVenues(data = null) {
        const searchData = JSON.parse(localStorage.getItem("searchData"))
        const searchObject = {
            pageNo: page,
            place: !data ? !searchData ? "" : searchData.place : data?.place,
            date: !data ? !searchData ? "" : searchData.date : data?.date,
            time: data ? data.time : "",
            size: !data ? !searchData ? "" : searchData.size : data?.size,
        }
        dispatch(recomendedVenues(searchObject))
        console.log(venuesData.list);
    }

    function getCity(location) {
        if(location === undefined){
            return 'Undefined';
        }else{
            const addressArr = location.split(',');
            return addressArr[addressArr.length - 4] + ", " + addressArr[addressArr.length - 3];
        }
    }

    useEffect(() => {
        setData(venuesData.list)
        const placeId = [];

        if (venuesData?.list.length > 0) {
            venuesData.list.forEach((val , key) => {
                if (!placeId.includes(val.placeId)) {
                    var obj = {
                        id : key,
                        placeId : val.placeId
                    }
                    placeId.push(obj);
                }
            });
            setPlaceIds(placeId);
        }
    }, [venuesData]);

    useEffect(() => {
        data.forEach((val) => {
            const description_paragraph = document.getElementById(`description_${val.id}`);
            if (description_paragraph) {
                description_paragraph.innerHTML = val.description;
            }
        });
    }, [data]);

    function searchAgain(e) {
        e.preventDefault()

        const searchData = {
            place: searchPlace,
            date: formData?.date,
            // time: formData?.time,
            // facility: formData?.facility,
            size: formData?.size
        }

        localStorage.removeItem("searchData")
        getMatchingVenues(searchData)
    }

    useEffect(() => {
        getMatchingVenues()
    }, [])

    const getSingleVenueDetail = (id) => {
        localStorage.setItem("venueId", id)
        navigate(`/venue/${id}`)
    }

    //@des - for map and multiple marker code
    const [markers, setMarkers] = useState([]);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCYDFyBePkwdOkXW-mXzNLnR_okUyO4rXg',
        libraries,
    });

    async function GetLocationMap(sendVal) {
        try {
            const queryParams = new URLSearchParams(sendVal);
            const url = `${DEFAULT_URL}getLocation?${queryParams}`;

            const response = await axios.get(url);
            return response?.data?.body?.geometry?.location

        } catch (error) {
            console.log("Check the error", error);
        }
    }

    useEffect(() => {
        if (isLoaded) {
            // const mmr = [
            //     { id: 1, lat: 28.704060, lng: 77.102493 },
            //     { id: 2, lat: 30.704649, lng: 76.717873 },
            //     { id: 3, lat: 48.422112, lng: -1.682480 },
            //     { id: 4, lat: 31.536779, lng: 75.898209 },
            //     { id: 5, lat: 30.710490, lng: 76.852390 }
            // ];
            const mmr = placeIds
            // setMarkers(mmr);
            const fetchMarkers = async () => {
                const updatedMarkers = [];
                let i = 0
                for (const marker of mmr) {
                    try {
                        const { lat, lng } = await GetLocationMap({ place_id: marker.placeId });
                        //console.log(lat , lng)
                        updatedMarkers.push({
                            id :i++,
                            lat: lat,
                            lng: lng
                        });
                    } catch (error) {
                        console.error(`Error fetching coordinates for place ID ${marker.placeId}`, error);
                    }
                }
                setMarkers(updatedMarkers);
            };

            fetchMarkers()
        }
    }, [isLoaded]);

    const onLoad = (map) => {
        // const bounds = new window.google.maps.LatLngBounds();
        // markers.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        // map.fitBounds(bounds);
        console.log(venuesData.list);
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        const centerPlace = searchData? searchData.place : searchPlace;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode( { address: centerPlace}, function(results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                map.setCenter({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()});
            }
        });
        map.setZoom(12);
    };

    if (loadError) {
        return <div>Error loading Google Maps API</div>;
    }


    return (<>
        <section className="search_page">
            <div className="search_header">
                <form onSubmit={(e) => searchAgain(e)}>
                    <div className="container-fluid mainSearchBox">
                        <div className="form_input mx-2 box_shadow">
                            <img src="./../../assets/images/locataion_icon.png" alt="DoubleSpot.com" width="15" />
                            <div>
                                <Autocomplete
                                    apiKey={GOOGLE_PLACES_API_KEY}
                                    onPlaceSelected={(place) => {
                                        handlePlaceSelect(place);
                                    }}
                                    value={searchPlace || ""}
                                    onChange={(e) => setPlace(e.target.value)}
                                    required="true"
                                    placeholder="Enter the location"
                                    className="searchBoxinput"
                                    id="date"
                                />
                            </div>
                        </div>
                        <div className="form_input mx-2 box_shadow px-3">
                            <p className="mb-0">
                                <input
                                    type="date"
                                    placeholder="select date"
                                    name="date"
                                    onChange={handleData}
                                /></p>
                            {/* | <p className="mb-0">
                                <input
                                    type="time"
                                    placeholder="select time"
                                    onChange={handleData}
                                />
                            </p> */}
                        </div>
                        <div className="form_input mx-2 box_shadow">
                            <select
                                name="size"
                                className="pe-2"
                                id="party_size"
                                onChange={handleData}
                            >
                                <option value="">Party Size</option>
                                <option value="10">1-10 people</option>
                                <option value="30">1-30 people</option>
                                <option value="50">1-50 people</option>
                                <option value="100">1-100 people</option>
                                <option value="101">100+ people</option>
                            </select>
                        </div>
                        {/* <div className="form_input mx-2 ev_type box_shadow">
                            <select name="event_type" className="pe-2" id="event_type">
                                <option value="1">Event Type</option>
                                <option value="1">Event Type</option>
                                <option value="1">Event Type</option>
                            </select>
                        </div> */}
                        {/* <div className="form_input mx-2 box_shadow">
                            <select
                                name="facility"
                                onChange={handleData}
                                className="pe-2"
                                id="facilities"
                            >
                                <option value="">Select facility</option>
                                {venueFacility.length > 0 &&
                                    venueFacility.map((val, key) => {
                                        return <option key={key} value={val.name}>{val.name}</option>
                                    })
                                }
                            </select>
                        </div> */}
                        <button type="submit" className=" form_input mx-2 form_btn border-0 text-white" style={{ background: '#ff7e67', }}>Search</button>
                    </div>
                </form>
            </div>
            <div className="search_body">
                <div className="container-fluid">
                    <div className="row flex-lg-wrap flex-wrap-reverse gx-1">
                        <div className="col-xxl-5 col-lg-6 pt-2 map_side">
                            {data.length > 0 ?
                                data.map((val, key) => {
                                    return <>
                                        <div className="card mb-3 box_shadow border-0 rounded-4">
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    {
                                                        val?.images.length > 0 && val?.images?.split(",") &&
                                                        <img src={SITE_URL + 'images/' + val?.images?.split(",")[0]} className="img-fluid w-100 h-100 rounded-start-4 object-fit-cover"
                                                             alt="DoubleSpot.com" />
                                                    }
                                                    {val?.process_payment ?
                                                        <div className="price-box">From ${
                                                            val?.min_hours && val?.max_hours && val?.min_hours === val?.max_hours ?
                                                                val?.min_hours * val?.hourly_price :
                                                                `${val?.hourly_price}/hr`
                                                        }</div> :
                                                        null
                                                    }

                                                </div>
                                                <div className="col-md-8 h300">
                                                    <div className="card-body">
                                                        <h5 className="card-title mb-0 title-trunc">{val.name}</h5>
                                                        <small className="text_icon gap-1 fw-normal mb-2">{getCity(val.location)}</small>
                                                        {/* <div className="button_group">
                                                            {
                                                                val?.selected_services.length > 0 && val?.selected_services?.split(",").map((val, key) => {
                                                                    return <button type="button" key={key} className="btn">{val}</button>
                                                                })
                                                            }
                                                        </div> */}
                                                        <p id={`description_${val.id}`} className="card-text fw-normal my-2 text-trunc pad-tb-5"/>
                                                        <a onClick={() => getSingleVenueDetail(val.id)} className="more_btn mx-0 mt-2">Learn More</a>
                                                        <div className="button_group align-items-baseline gap-2 mt-2 ">

                                                            {/* <p className="fs-6 fw-medium mb-0">Min Hours :⏱️ {val.min_hours}</p> - */}
                                                            {/* <p className="fs-6 fw-medium mb-0">${val.hourly_price}/hr</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })

                                :
                                <h4 className="text-center text-danger" >No data found!</h4>
                            }
                        </div>
                        <div className="col-xxl-7 col-lg-6 pt-lg-0 pt-3">
                            <div className="map">
                                {!isLoaded && markers.length == 0 ? (
                                    <h1>Loading...</h1>
                                ) : (
                                    <GoogleMap
                                        mapContainerStyle={{ width: "100%", height: "100%" }}
                                        zoom={12}
                                        onLoad={onLoad}
                                    >
                                        {markers.map(({ id, lat, lng }) => (
                                            <Marker key={id} position={{ lat, lng }} />
                                        ))}
                                    </GoogleMap>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default SearchPage
