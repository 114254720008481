module.exports = {
    ROLE_ADMIN: 0,
    ROLE_CUSTOMER: 1,
    ROLE_VENUE_OWNER: 2,
    ROLE_VENUE_MANAGER: 3,

    SOCIAL_TYPE_GOOGLE: 1,
    SOCIAL_TYPE_MICROSOFT: 2,

    DEVICE_TYPE_ANDROID: 1,
    DEVICE_TYPE_IOS: 2,
};
