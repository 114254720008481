import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserFetch } from "./redux/action/action";
import Routing from './Routes/Routing';
import { BrowserRouter } from "react-router-dom";
import Alerts from "./User/Layout/Alerts/Alerts";
import '@coreui/coreui/dist/css/coreui.min.css';
// import './Provider/css/style.css';
import './Business/styles/business.css';

function App() {
    const dispatch = useDispatch()
    // The current location.
    const data = useSelector(state => state.Login.users);
    const [provider, setProvider] = useState("")

    useEffect(() => {
        dispatch(getUserFetch())
    }, [alert])

    return (
        <div className="App">
            <BrowserRouter>
                <Alerts/>
                <Routing />
            </BrowserRouter>
        </div>
    );
}

export default App;
