import {
    CButton,
    CForm,
    CFormInput,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import {importMemberships, setAlert} from "../../../../redux/action/action";
import csv from "csvtojson";
import {useEffect, useState} from "react";
import {dateToIsoString} from "../../../../Utils/DateTimeUtils";

const UploadCsvModal = ({visible, onClose, membershipTypes, dispatch}) => {
    const [entries, setEntries] = useState([]);
    const [validEntries, setValidEntries] = useState([]);

    const onCsvUpload = async (event) => {
        const csvFile = event.target.files && event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
            try {
                const csvText = reader.result;
                const jsonArrayObj = await csv().fromString(csvText);
                setEntries(jsonArrayObj);
            } catch (error) {
                dispatch(setAlert('The CSV file is not well formatted.','error'));
            } finally {
                event.target.value = '';
            }
        };

        reader.onerror = () => {
            dispatch(setAlert('An error occurred attempting to read the CSV file.','error'));
            event.target.value = '';
        };

        reader.readAsText(csvFile, 'UTF-8');
    };

    const onSubmit = (event) => {
        event.preventDefault();
        dispatch(importMemberships(validEntries));
    }

    const entryToValidEntry = (entry) => {
        const email = entry["Email"];
        if (!email || !email.includes('@')) {
            return null;
        }

        const membershipTypeName = entry["Membership Type"];
        if (!membershipTypeName) return null;
        const matchingMembershipType = membershipTypes.find(membershipType =>
            membershipType.name.trim().toLowerCase() === membershipTypeName.trim().toLowerCase()
        );
        if (!matchingMembershipType) return null;

        const expirationDateString = entry["Expiration Date"];
        const expirationDate = new Date(expirationDateString);
        const expirationDateIsoString = isNaN(expirationDate.getTime()) ? null : dateToIsoString(expirationDate);
        console.log("expiration date:", expirationDateIsoString);

        return {
            email,
            membership_type_id: matchingMembershipType?.id,
            expire_on: expirationDateIsoString,
        };
    }

    useEffect(() => {
        setValidEntries(entries
            .map(entry => entryToValidEntry(entry))
            .filter(entry => entry !== null)
        );
    }, [entries]);

    useEffect(() => {
    }, [validEntries]);

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            setEntries([]);
            setValidEntries([]);
            onClose();
        }}>
            <CModalHeader>
                <div>
                    <CModalTitle>Import Membership Entries from CSV File</CModalTitle>
                    <p className="modal-subtitle mt-1 mb-0">Expiration date will be updated for existing email-membership-type combinations.</p>
                </div>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        Download the <a href="/membership-example.csv" target="_blank"><u>example CSV file</u></a> for complete
                        formatting specifications. CSV files can be viewed and edited in spreadsheet editors such as Microsoft Excel or Google Sheets.
                    </div>
                    <div class="pb-3">
                        <CFormInput type="file" accept=".csv, text/csv" onChange={onCsvUpload}/>
                    </div>
                    {entries.length ? <div class="pb-3">{`${validEntries.length}/${entries.length} valid entries detected.`}</div> : null}
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default UploadCsvModal;
