import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel, 
    CFormSelect,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {addMemberships, updateMembership} from "../../../../redux/action/action";

const EditMembershipEntryModal = ({visible, onClose, editFormData, setEditFormData, membershipTypes, dispatch}) => {
    const updateFormData = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const updateFormDataMultiselect = (selectedOptions, actionMeta) => {
        setEditFormData({...editFormData, [actionMeta.name]: selectedOptions});
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (editFormData?.id === null) {
            dispatch(addMemberships({
                ...editFormData,
                membership_type_ids:  editFormData.membership_types.map(type => type.value),
            }));
        } else {
            dispatch(updateMembership(editFormData));
        }
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setEditFormData({});
        }}>
            <CModalHeader>
                {
                    editFormData?.id === null ? <div>
                        <CModalTitle>Add Membership Entries</CModalTitle>
                        <p className="modal-subtitle mt-1 mb-0">Expiration date will be updated for existing
                            email-membership-type combinations.</p>
                    </div> : <CModalTitle>Edit Membership Entry</CModalTitle>
                }
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        <CFormLabel htmlFor="email">Email</CFormLabel>
                        <CFormInput type="text" name="email" value={editFormData?.email}
                                    onChange={updateFormData}/>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="membership_types">Membership Types</CFormLabel>
                        {
                            editFormData.id === null ?
                                <MultiSelectCheckbox
                                    name="membership_types"
                                    onChange={updateFormDataMultiselect}
                                    options={membershipTypes.map(
                                        type => ({
                                            label: type.name,
                                            value: type.id,
                                        })
                                    )}
                                    value={editFormData.membership_types}
                                /> :
                                <CFormSelect name="membership_type_id" onChange={updateFormData}
                                             value={editFormData.membership_type_id}>
                                    {
                                        membershipTypes.map(type => <option value={type.id}>{type.name}</option>)
                                    }
                                </CFormSelect>
                        }
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="date">Expiration Date (optional)</CFormLabel>
                        <CFormInput
                            type="date"
                            name="expire_on"
                            onChange={updateFormData}
                            value={editFormData.expire_on}
                        />
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default EditMembershipEntryModal;
