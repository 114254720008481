import {CButton} from "@coreui/react";
import React from "react";

export const columns = (eventTypeColors, onEditButtonClick, onDeleteButtonClick) => [
    {
        name: 'Color',
        selector: row => row?.color,
        cell: row => <div className="event-type-color-circle" style={{backgroundColor: eventTypeColors[row?.color]}}/>,
        width: "100px",
        wrap: true,
        sortable: true,
    },
    {
        name: 'Name',
        selector: row => row?.name,
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onEditButtonClick(row)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    }
];
