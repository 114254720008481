import {CAvatar, CBadge, CButton} from "@coreui/react";
import {getProfilePictureUri} from "../../../../Utils/UriUtils";

export const editableAccesses = [
    { columnName: "editVenueAccess", displayName: "Edit Venue Profiles" },
    { columnName: "editReservationReportsAccess", displayName: "Edit Event Reports" },
    { columnName: "editCustomerAccess", displayName: "Edit Customer Data" },
    { columnName: "editEventAccess", displayName: "Edit Event Details" },
    { columnName: "acceptBookingAccess", displayName: "Accept Booking Requests" },
    { columnName: "editBusinessAccess", displayName: "Access Organization Profile" },
    { columnName: "aiAccess", displayName: "Access DoubleSpot AI" },
];

export const columns = (venues, handleEditButtonClick) => [
    {
        name: 'Team Member',
        selector: row => row.managerUser?.name,
        cell: row => (
            <div class="flex-row py-2 align-items-center">
                <CAvatar size="md" shape="rounded-circle" src={getProfilePictureUri(row.managerUser?.profile_image, "../../../../assets")}/>
                <span class="ps-4">
                    {row.managerUser?.name}
                    <br/>
                    {row.managerUser?.email || "N/A"}
                    <br/>
                    {row.managerUser?.phone || "N/A"}
                </span>
            </div>
        ),
        width: "300px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Venues',
        selector: row => row.venue_ids.length,
        cell: row => {
            return <span class="py-2 display-line-break">{
                row.venue_ids.map(venueId => {
                    return venues.find((venue) => venue.id === venueId)?.name;
                }).filter(venueName => venueName).join('\n')
            }</span>;
        },
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Permissions',
        selector: row => editableAccesses.filter(({columnName}) => row[columnName]).length,
        cell: row => (
            <div className="flex-row flex-wrap row-gap-1 py-3">
                {
                    editableAccesses.filter(({columnName}) => row[columnName]).map(
                        ({displayName}) => (
                            <div className="px-1">
                                <h6 class="mb-0"><CBadge className="black" color="light">{displayName}</CBadge></h6>
                            </div>
                        )
                    )
                }
            </div>
        ),
        width: "350px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => handleEditButtonClick(row.id)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "125px",
        right: true,
    },
];

export const filterCategories = [
    {
        name: 'Name',
        category: 'name',
        type: 'exact',
    },
    {
        name: 'Phone',
        category: 'phone',
        type: 'exact',
    },
    {
        name: 'Email',
        category: 'email',
        type: 'exact',
    },
    {
        name: 'Venues',
        category: 'venues',
        type: 'multiple',
    },
    {
        name: 'Edit Venues',
        category: 'editVenueAccess',
        type: 'multiple',
    },
    {
        name: 'Edit Events',
        category: 'editEventAccess',
        type: 'multiple',
    },
    {
        name: 'Accept Bookings',
        category: 'acceptBookingAccess',
        type: 'multiple',
    },
    {
        name: 'Access Business Profile',
        category: 'editBusinessAccess',
        type: 'multiple',
    },
    {
        name: 'Access DoubleSpot AI',
        category: 'aiAccess',
        type: 'multiple',
    },
];

export const applyFilters = (managers, filters) => {
    return managers.filter(manager => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                manager.managerUser.name.toLowerCase().includes(searchTerm) ||
                manager.managerUser.email.toLowerCase().includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.name) {
            const searchTerm = filters.name.toLowerCase();
            if (!manager.name.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.phone) {
            if (!manager.phone.includes(filters.phone)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email.toLowerCase();
            if (!manager.email.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.venues?.length) {
            let includesVenue = false;
            filters.venues.map(venue => venue.value).map((venueId) => {
                if (manager.venue_ids.includes(venueId)) {
                    includesVenue = true;
                }
            })
            if (!includesVenue) return false;
        }

        if (filters.editVenueAccess?.length) {
            if (!filters.editVenueAccess.map(accessLevels => accessLevels.value).includes(manager.editVenueAccess)) return false;
        }
        if (filters.editReservationReportsAccess?.length) {
            if (!filters.editReservationReportsAccess.map(accessLevels => accessLevels.value).includes(manager.editReservationReportsAccess)) return false;
        }
        if (filters.editCustomerAccess?.length) {
            if (!filters.editCustomerAccess.map(accessLevels => accessLevels.value).includes(manager.editCustomerAccess)) return false;
        }
        if (filters.editEventAccess?.length) {
            if (!filters.editEventAccess.map(accessLevels => accessLevels.value).includes(manager.editEventAccess)) return false;
        }
        if (filters.acceptBookingAccess?.length) {
            if (!filters.acceptBookingAccess.map(accessLevels => accessLevels.value).includes(manager.acceptBookingAccess)) return false;
        }
        if (filters.editBusinessAccess?.length) {
            if (!filters.editBusinessAccess.map(accessLevels => accessLevels.value).includes(manager.editBusinessAccess)) return false;
        }
        if (filters.aiAccess?.length) {
            if (!filters.aiAccess.map(accessLevels => accessLevels.value).includes(manager.aiAccess)) return false;
        }

        return true;
    });
};
