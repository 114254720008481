import BusinessSidebar from "../components/Sidebar";
import React from "react";

const DashboardLayout = ({ children }) => {
    return (
        <div>
            <BusinessSidebar/>
            <div class="flex-row min-vh-100" style={{
                backgroundColor: "#eeeeee",
                paddingLeft: "16rem"
            }}>
                {children}
            </div>
        </div>
    )
};

export default DashboardLayout;
