import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import {addMembershipType, updateMembershipType} from "../../../../redux/action/action";

const EditMembershipTypeModal = ({visible, onClose, editFormData, setEditFormData, dispatch}) => {
    const updateFormData = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (editFormData?.id === null) {
            dispatch(addMembershipType(editFormData));
        } else {
            dispatch(updateMembershipType(editFormData));
        }
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setEditFormData({});
        }}>
            <CModalHeader>
                <CModalTitle>{editFormData?.id === null ? "Add" : "Edit"} Membership Type</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        <CFormLabel htmlFor="name">Name</CFormLabel>
                        <CFormInput type="text" name="name" value={editFormData?.name}
                                    onChange={updateFormData}/>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default EditMembershipTypeModal;
