import React from "react";

const JoinUs = () => {
    return (
        <>
            <seciton className="join_us">
                <div className="container  pb-17">
                    <div className="card text-bg-dark">
                        <img src="../../../../assets/images/venue_join_us.png" className="card-img" alt="doublespot.com" />
                        <div className="card-img-overlay">
                            <h3 className="card-title">Venues Join Us</h3>
                            <p className="card-text d-block">Join DoubleSpot to make your venue thrive!</p>
                            <a href = "https://docs.google.com/forms/d/e/1FAIpQLSdHuY_l6by0R6unWNWH2m3NwD9h9KrmLka1kVpuEX0sLnwGbQ/viewform?usp=sf_link" className="more_btn">Learn More</a>
                        </div>
                    </div>

                    <p className="my-4 text-center">Favourite restaurant not yet on Doublespot? <a href="#"> Suggest a restaurant.
                    </a>
                    </p>

                </div>
            </seciton>
        </>
    )

}


export default JoinUs;