import { 
    CRow, 
    CCol, 
    CWidgetStatsA, 
    CWidgetStatsD, 
    CDropdown, 
    CDropdownToggle, 
    CDropdownMenu, 
    CDropdownItem, 
    CCard, 
    CCardBody 
} from "@coreui/react"
import CIcon from "@coreui/icons-react";
import { cilArrowTop, cilGlobeAlt, cilOptions } from "@coreui/icons";
import {setAlert} from "../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {CChartBar} from "@coreui/react-chartjs";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const AnalyticReports = () => {
    document.title = "Analytic Reports | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Take a deep dive into the analytic reports of your event venues at DoubleSpot."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userDetail = useSelector(state => state.Apis.userDetail);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.analyticsAccess) {
                dispatch(setAlert('You do not have the permissions to analytic reports.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    return (
        <div className="p-4 vw-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Analytic Reports</h2>
                    <p className="modal-subtitle mb-0">This page is for demo purposes only. A working analytics page will be coming soon!</p>
                    <hr/>
                    <CRow>
                        <CCol sm={6}>
                            <CWidgetStatsA
                            className="h-100"
                            color="success"
                            value={
                                <>
                                $413,095{' '}
                                <span className="fs-6 fw-normal">
                                    (8.0% <CIcon icon={cilArrowTop} />)
                                </span>
                                </>
                            }
                            title="Monthly Revenue"
                            action={
                                <CDropdown alignment="end">
                                <CDropdownToggle color="transparent" caret={false} className="p-0">
                                    <CIcon icon={cilOptions} className="text-white" />
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem>View Details</CDropdownItem>
                                    <CDropdownItem>Share Report</CDropdownItem>
                                    <CDropdownItem>Save As...</CDropdownItem>
                                </CDropdownMenu>
                                </CDropdown>
                            }
                            chart={
                                <CChartBar
                                className="mt-3 mx-3"
                                style={{ height: '70px' }}
                                data={{
                                    labels: [
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    'May',
                                    'June',
                                    'July',
                                    'August',
                                    'September',
                                    'October',
                                    'November',
                                    'December',
                                    'January',
                                    'February',
                                    'March',
                                    'April',
                                    ],
                                    datasets: [
                                    {
                                        label: 'Revenue',
                                        backgroundColor: 'rgba(255,255,255,.2)',
                                        borderColor: 'rgba(255,255,255,.55)',
                                        data: [282944, 349520, 373979, 298598, 355837, 296931, 220041, 202909, 204832, 266494, 221440, 340426, 237834, 298943, 382493, 413095],
                                        barPercentage: 0.6,
                                    },
                                    ],
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    },
                                    scales: {
                                    x: {
                                        grid: {
                                        display: false,
                                        drawTicks: false,
                                        },
                                        ticks: {
                                        display: false,
                                        },
                                    },
                                    y: {
                                        border: {
                                        display: false,
                                        },
                                        grid: {
                                        display: false,
                                        drawBorder: false,
                                        drawTicks: false,
                                        },
                                        ticks: {
                                        display: false,
                                        },
                                    },
                                    },
                                }}
                                />
                            }
                            />
                        </CCol>
                        <CCol sm={6}>
                            <CWidgetStatsD
                            className="h-100"
                            icon={<CIcon className="my-4 text-white" icon={cilGlobeAlt} height={52} />}
                            style={{ '--cui-card-cap-bg': '#003e7d' }}
                            values={[
                                { title: 'domain rank', value: '205K' },
                                { title: 'weekly site traffic', value: '7,323' },
                            ]}
                            />
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </div>
    )
};

export default AnalyticReports;
