const initialState = {
    openSignInModal: false,
    openSignUpModal: false,
    openForgotModal: false,
    navbarLogo: "../../../assets/images/logo.png",
    navbarColor: "ffffff",
    navbarButtonColor: "ff7e67",
    navbarButtonWhiteText: true,
}

const HandleModals = (state = initialState , action) => {
    const { type, data } = action;
    switch (type) {
        case "OPEN_SIGNUP_MODAL":
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: true,
                openForgotModal: false
            } 
        case "OPEN_SIGNIN_MODAL" : {
            return {
                ...state,
                openSignInModal: true,
                openSignUpModal: false,
                openForgotModal: false
            } 
        }
        case "OPEN_FORGOT_MODAL" : {
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: false,
                openForgotModal: true
            } 
        }
        
        case "CLOSE_MODAL" : {
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: false,
                openForgotModal: false
            } 
        }

        case "UPDATE_NAVBAR_BRAND": {
            const {
                navbarLogo,
                navbarColor,
                navbarButtonColor,
                navbarButtonWhiteText,
            } = data;
            const updatedStyles = {};
            if (navbarLogo) updatedStyles.navbarLogo = navbarLogo;
            if (navbarColor) updatedStyles.navbarColor = navbarColor;
            if (navbarButtonColor) updatedStyles.navbarButtonColor = navbarButtonColor;
            if (navbarButtonWhiteText != null) updatedStyles.navbarButtonWhiteText = navbarButtonWhiteText;

            return {
                ...state,
                ...updatedStyles,
            }
        }

        case "RESET_NAVBAR_BRAND": {
            return {
                ...state,
                navbarLogo: "../../../assets/images/logo.png",
                navbarColor: "ffffff",
                navbarButtonColor: "ff7e67",
                navbarButtonWhiteText: true,
            }
        }
       
        default:
            return state;
    }
}

export default HandleModals;