import {timeRange, dateToTimeString, getTimeString, unavailableTime} from "../../Utils/DateTimeUtils";
import "react-calendar/dist/Calendar.css";
import ReactSelect, {createFilter} from "react-select";
import React, {useEffect, useState} from "react";

/**
 *
 * @param name {string}
 * @param startTime {string} In format of hh:mm:ss with default value 00:00:00
 * @param endTime {string} In format hh:mm:ss with default value 23:30:00
 * @param interval {int} Time option interval
 * @param placeholder {string} Placeholder text when no time is selected
 * @param isClearable {boolean} Whether the time picker's value can be cleared
 * @param value {string} In format hh:mm:ss
 * @param onChange Function for onChange
 * @returns
 */

const TimePicker = ({
    name,
    startTime = "00:00:00",
    endTime= unavailableTime,
    interval = 5,
    placeholder = "Select Time",
    isClearable = false,
    value,
    onChange,
}) => {
    const dateTimes = timeRange(new Date(`2000-11-30T${startTime}`), new Date(`2000-11-30T${endTime}`), interval);
    const options = dateTimes.map(time => ({
        label: getTimeString(time, true),
        value: dateToTimeString(time),
    }));
    const filterConfig = {
        stringify: option => `${option.label} ${option.label.split(':').join('')}`
    }
    const [label, setLabel] = useState(null);

    useEffect(() => {
        if (!value || value === unavailableTime) {
            setLabel("");
        } else {
            setLabel(getTimeString(value, true));
        }
    }, [value]);

    return <ReactSelect
        menuShouldScrollIntoView={false}
        hideSelectedOptions={false}
        value={label ? { label, value } : null}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        filterOption={createFilter(filterConfig)}
        isClearable={isClearable}
    />;
};

export default TimePicker;
