import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {useSelector} from "react-redux";
import ErrorPage from "../ErrorPage";
import Checkout from "../User/Pages/Checkout";
import Home from "../User/Pages/Home"
import SearchPage from "../User/Pages/SearchPage";
import Venue from "../User/Pages/Venue";
import Profile from "../User/Pages/Profile";
import Chat from "../User/Pages/Chat";
import Notification from "../User/Pages/Notification";
import Wishlist from "../User/Pages/Wishlist";
import Bookings from "../User/Pages/Bookings";
import BizForgotPassword from "../Business/pages/ForgotPassword";
import ResetPassword from "../User/Pages/ResetPassword";
import VerifyEmail from "../User/Pages/VerifyEmail";
import Privacy_policy from "../User/Pages/Privacy_policy";
import Terms_of_use from "../User/Pages/Terms_of_use";
import ContactUs from "../User/Pages/ContactUs";
import ContactUsProvide from '../Provider/Pageslayout/ContactUs'
import ProvideFaq from "../Provider/Pageslayout/Faq";
import Faq from "../User/Pages/Faq";
import PPRPage from "../User/Pages/PostPurchaseRoutingPage";
import Navbar from "../User/components/Navbar";
import Footer from "../User/components/Footer";
import BusinessLogin from "../Business/pages/Login";
import Venues from "../Business/views/Organization/Venues/Venues";
import DashboardLayout from "../Business/layouts/DashboardLayout";
import EditVenue from "../Business/views/Organization/Venues/EditVenue";
import PersonalInfo from "../Business/views/AccountSettings/PersonalInfo";
import UserPreference from "../Business/views/AccountSettings/UserPreference";
import ReservationCalendar from "../Business/views/Reservations/Calendar/ReservationCalendar";
import AnalyticReports from "../Business/views/AnalyticReports";
import Calendar from "../User/Pages/Calendar";
import Team from "../Business/views/Organization/Team/Team";
import EventTypes from "../Business/views/Manage/EventTypes/EventTypes";
import ReservationTable from "../Business/views/Reservations/Table/ReservationTable";
import ProgramList from "../Business/views/Programs/ProgramList/ProgramTable";
import CreateProgram from "../Business/views/Programs/CreateNewProgram/CreateProgram";
import Program from "../Business/views/Programs/Program/Program";
import Session from "../Business/views/Programs/Session/Session";
import ProviderProfile from "../User/Pages/ProviderProfile";
import Members from "../Business/views/Customers/Members/Members";
import BookingRestrictions from "../Business/views/Customers/BookingRestrictions/BookingRestrictions";
import BusinessProfile from "../Business/views/Organization/Profile";
import SubscriptionPlan from "../Business/views/Organization/SubscriptionPlan";
import Conversations from "../Business/views/DoubleSpotAi/Conversations/Conversations";
import Conversation from "../Business/views/DoubleSpotAi/Conversations/Conversation";
import ChatbotPreview from "../Business/views/DoubleSpotAi/ChatbotPreview";
import EventTags from "../Business/views/Manage/EventTags/EventTags";
import ScheduledReports from "../Business/views/Reservations/ScheduledReports/ScheduledReports";
import Event from "../Business/views/Reservations/Event/Event";
import Programs from "../User/Pages/Programs";
import ProgramDetail from "../User/Pages/Program";
import {users} from "constants";

function Routing() {
  function UserRoute({ children }) {
    const userRole = localStorage.getItem("userRole");
    const isAuthenticated = userRole;
    if (isAuthenticated != 1) {
      // user is not authenticated
      return <Navigate to="/" />;
    }
    return children;
  }

  function UserLayout({ children }) {
    return (
        <div id="page-container">
          <div id="content-wrap">
            <div className="content">
              <div>
                <Navbar/>
                {children}
              </div>
              <Footer/>
            </div>
          </div>
        </div>
    );
  }

  function BusinessView({ children }) {
    const userRole = localStorage.getItem("userRole");
    const isAuthenticated = userRole;
    if (isAuthenticated != 2 && isAuthenticated != 3) {
      // user is not authenticated
      return <Navigate to="/business/login" />;
    }
    return (
        <DashboardLayout>{children}</DashboardLayout>
    );
  }

  function BusinessDefault() {
    const userDetail = useSelector(state => state.Apis.userDetail);

    if (userDetail.role === users.ROLE_VENUE_OWNER || userDetail.manager?.calendarAccess) {
      return <Navigate to="/business/reservations/calendar" replace/>;
    } else if (userDetail.manager?.aiAccess) {
      return <Navigate to="/business/ai/conversations" replace/>;
    } else {
      return <Navigate to="/business/account/personal-info" replace/>;
    }
  }

  return (<>
    <Routes>
      <Route exact path="/" element={<UserLayout><Home/></UserLayout>}/>
      <Route exact path="/venue/:id" element={<UserLayout><Venue/></UserLayout>}/>
      <Route exact path="/search-page" element={<UserLayout><SearchPage /></UserLayout>} />
      <Route exact path="/PostPurchaseRouting" element={<UserLayout><PPRPage /></UserLayout>} />
      <Route exact path="/checkout/:id" element={<UserLayout><UserRoute><Checkout /></UserRoute></UserLayout>} />
      <Route exact path="/privacy" element={<UserLayout><Privacy_policy /></UserLayout>} />
      <Route exact path="/terms" element={<UserLayout><Terms_of_use /></UserLayout>} />
      <Route exact path="/contact-us" element={<UserLayout><ContactUs /></UserLayout>} />
      <Route exact path="/faq" element={<UserLayout><Faq /></UserLayout>} />
      <Route exact path="/business/faq" element={<UserLayout><ProvideFaq /></UserLayout>} />
      <Route exact path="/business/contact-us" element={<UserLayout><ContactUsProvide /></UserLayout>} />
      <Route exact path="/reset-password/:id/:link" element={<UserLayout><ResetPassword /></UserLayout>} />
      <Route exact path="/verify-email/:id/:link" element={<UserLayout><VerifyEmail /></UserLayout>} />
      <Route exact path="/provider/:id" element={<UserLayout><ProviderProfile/></UserLayout>}/>
      <Route exact path="/calendar/:id" element={<UserLayout><Calendar/></UserLayout>}/>
      <Route exact path="/programs" element={<UserLayout><Programs/></UserLayout>}/>
      <Route exact path="/programs/:id" element={<UserLayout><ProgramDetail/></UserLayout>}/>

      {/*protected routes for business website*/}
      <Route path="/business" element={<BusinessDefault/>} />
      <Route exact path="/business/login" element={<BusinessLogin/>} />
      <Route exact path="/business/forgot-password" element={<BizForgotPassword/>} />
      <Route path="/business/venues" element={<BusinessView><Venues/></BusinessView>}/>
      <Route path="/business/venues/:id" element={<BusinessView><EditVenue/></BusinessView>}/>
      <Route path="/business/team" element={<BusinessView><Team/></BusinessView>}/>
      <Route path="/business/event-tags" element={<BusinessView><EventTags/></BusinessView>}/>
      <Route path="/business/event-types" element={<BusinessView><EventTypes/></BusinessView>}/>
      <Route path="/business/account/personal-info" element={<BusinessView><PersonalInfo/></BusinessView>}/>
      <Route path="/business/account/user-preference" element={<BusinessView><UserPreference/></BusinessView>}/>
      <Route path="/business/reservations" element={<Navigate to="/business/reservations/calendar" replace />}/>
      <Route path="/business/reservations/calendar" element={<BusinessView><ReservationCalendar/></BusinessView>}/>
      <Route path="/business/reservations/table" element={<BusinessView><ReservationTable/></BusinessView>}/>
      <Route path="/business/programs/list" element={<BusinessView><ProgramList/></BusinessView>}/>
      <Route path="/business/programs/create-program" element={<BusinessView><CreateProgram/></BusinessView>}/>
      <Route path="/business/programs/create-program/:id" element={<BusinessView><CreateProgram/></BusinessView>}/>
      <Route path="/business/programs/program/:id" element={<BusinessView><Program/></BusinessView>}/>
      <Route path="/business/programs/session/:id" element={<BusinessView><Session/></BusinessView>}/>
      <Route path="/business/reservations/scheduled-reports" element={<BusinessView><ScheduledReports/></BusinessView>}/>
      <Route path="/business/events/:id" element={<BusinessView><Event/></BusinessView>}/>
      <Route path="/business/analytic-reports" element={<BusinessView><AnalyticReports/></BusinessView>}/>
      <Route path="/business/customers/members" element={<BusinessView><Members/></BusinessView>}/>
      <Route path="/business/customers/booking-restrictions" element={<BusinessView><BookingRestrictions/></BusinessView>}/>
      <Route path="/business/organization-profile" element={<BusinessView><BusinessProfile/></BusinessView>}/>
      <Route path="/business/subscription" element={<BusinessView><SubscriptionPlan/></BusinessView>}/>
      <Route path="/business/ai/conversations" element={<BusinessView><Conversations/></BusinessView>}/>
      <Route path="/business/ai/conversation" element={<BusinessView><Conversation/></BusinessView>}/>
      <Route path="/business/ai/chatbot-preview" element={<BusinessView><ChatbotPreview/></BusinessView>}/>

      {/* protected routes for website*/}
      <Route path="/profile" element={<UserLayout><UserRoute><Profile /></UserRoute></UserLayout>}/>
      <Route path="/chat" element={<UserLayout><UserRoute><Chat /></UserRoute></UserLayout>} />
      <Route path="/booking" element={<UserLayout><UserRoute><Bookings /></UserRoute></UserLayout>} />
      <Route path="/notification" element={<UserLayout><UserRoute><Notification /></UserRoute></UserLayout>} />
      <Route path="/wishlist" element={<UserLayout><UserRoute><Wishlist /></UserRoute></UserLayout>} />
      <Route path="*" exact={true} element={<UserLayout><ErrorPage /></UserLayout>} />
    </Routes>
  </>)
}

export default Routing
