import React from "react";

function Loading() {
    return (<>
        <section class="page_404">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="text-center">   
                        <h3 className="h2 ">
                            Loading...
                        </h3>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default Loading