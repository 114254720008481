import DataTable from "react-data-table-component";
import React from "react";
import {columns} from "../../../../Utils/TaskUtils";

const EventChecklist = ({ data, onTaskStatusChange }) => {
    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#f5f5f5',
                minHeight: "36px",
            },
        },
        rows: {
            style: {
                backgroundColor: '#fafafa',
            },
        },
    };

    return <DataTable
        columns={[
            {
                name: "",
                selector: () => "",
                width: "96px",
            },
            ...columns(onTaskStatusChange)
        ]}
        data={data?.tasks || []}
        customStyles={customStyles}
    />
}

export default EventChecklist;
