import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DEFAULT_URL, setAlert } from "../../redux/action/action";

const ContactUs = () => {
  document.title = "Contact Us | DoubleSpot";
  document.querySelector('meta[name="description"]').setAttribute(
      "content",
      "Connect with DoubleSpot by sending us a message!"
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(`${DEFAULT_URL}addContactus`, formData)
      .then(function (response) {
        dispatch(setAlert(response.data.message, "success"));
      })
      .catch(function (error) {
        dispatch(setAlert(error.data.message, "success"));
      });

    navigate("/");
  };

  return (
    <>
      <section className="contact_us pt-5">
        <div className="container">
          <div className=" text-center">
            <h3 className="mb-0">Contact Us</h3>
          </div>
          <div className="row justify-content-center align-itmes-end h-100 flex-wrap-reverse mt-md-3 mt-3">
            <div className="col-md-4">
              <form onSubmit={(e) => submitForm(e)}>
                <div className="form-group">
                  <label for="text">Full name</label>
                  <input
                    type="text"
                    name="full_name"
                    className="form-control box_shadow"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label for="text">Contact Number</label>
                  <input
                    type="text"
                    name="contact_no"
                    className="form-control box_shadow"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Contact Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label for="text">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control box_shadow"
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <label for="text">Message</label>
                  <textarea
                    name="msg"
                    id="tarea"
                    onChange={(e) => handleChange(e)}
                    className="form-control box_shadow"
                    placeholder="Write here.."
                    required
                  ></textarea>
                </div>
                <div className="form-group mb-2 text-center">
                  <button type="submit" className="more_btn ms-auto">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
