import {call , put , takeEvery} from 'redux-saga/effects';
import {  
  DEFAULT_URL,
  GET_USERS_SUCCESS, 
  setAlert,  
  SEND_VERIFICATION_EMAIL,
  USER_SIGNUP_API_SUCCESS, 
  USER_SIGNUP_FAILURE
} from '../action/action';


function* userSignUp(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}signUp`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: USER_SIGNUP_API_SUCCESS , data });
    yield put({ type: SEND_VERIFICATION_EMAIL, payload: data.body });
    yield put(setAlert('User signed up successfully', 'success'));
    
  } catch (error) {
    yield put({ type: USER_SIGNUP_FAILURE, error });
    yield put(setAlert('User sign up failed', 'error'));
  }
}


function* saga() {
    yield takeEvery(GET_USERS_SUCCESS , userSignUp);
}

export default saga;