import React, { useEffect, useState } from "react";
import { DEFAULT_URL } from "../../redux/action/action";
import axios from "axios";

function Terms_of_use() {
  document.title = "Terms of Use | DoubleSpot";
  document.querySelector('meta[name="description"]').setAttribute(
      "content",
      "Review DoubleSpot's terms of use to understand your rights and responsibilities with ease."
  );

  const [data, setData] = useState("");

  useEffect(() => {
    getPage();
  }, []);

  async function getPage() {
    try {
      const response = await axios.get(`${DEFAULT_URL}getPage/terms_conditions`);
      setData(response.data.body.content);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <section class="inner_banner">
        <div class="container">
          <h2>Terms of Use</h2>
          <p>
            {" "}
            <a>Legal</a> / <a>Terms of Use</a>{" "}
          </p>
        </div>
      </section>

      <section class="pera py-5 px-5 ">
        <div class="container">
          <p><i>Last Updated: Aug 16, 2023</i></p>
          <p>&nbsp;</p>
          <p>Welcome to DoubleSpot, a venue management and discovery platform that connects venue operators and event organizers. These Terms of Service ("Terms") govern your access to and use of our website, products, and services (collectively, the "Platform"). Please read these Terms carefully before using the Platform. By accessing or using the Platform, you agree to be bound by these Terms and by our Privacy Policy. If you do not agree to these Terms and our Privacy Policy, you may not access or use the Platform.</p>
          <p>&nbsp;</p>
          <h3><strong>Eligibility</strong></h3>
          <p>You must be at least 18 years old to access or use the Platform. By accessing or using the Platform, you represent and warrant that you are 18 years or older and that you have the right, authority, and capacity to enter into these Terms.</p>
          <p>&nbsp;</p>
          <h3><strong>Accounts</strong></h3>
          <p>To access or use some features of the Platform, you may need to create an account. You are responsible for maintaining the security of your account and password, and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or password, or any other breach of security. You may not use another user's account without their permission. You may not create more than one account per email address. You may not create an account if we have previously terminated your account or banned you from the Platform.</p>
          <p>&nbsp;</p>
          <h3><strong>Content</strong></h3>
          <p>The Platform allows you to create, upload, post, send, receive, and store content, such as text, photos, videos, reviews, ratings, messages, and other materials ("Content"). You retain all rights in and to your Content, subject to the licenses granted by you in these Terms.</p>
          <p>By creating, uploading, posting, sending, receiving, or storing Content through the Platform, you grant us a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform and display your Content in connection with the Platform and our business.</p>
          <p>You also grant us a worldwide, non-exclusive, royalty-free license to use your name, likeness, voice, and identity in connection with your Content. You waive any rights of publicity or privacy that you may have in your Content.</p>
          <p>You are solely responsible for your Content and for the consequences of creating, uploading, posting, sending, receiving or storing it. You represent and warrant that:</p>
          <p>- You own or have the necessary rights and permissions to use and share your Content on the Platform;</p>
          <p>- Your Content does not infringe or violate any third-party rights, including intellectual property rights, privacy rights, publicity rights or contractual rights;</p>
          <p>- Your Content does not contain any unlawful, harmful, abusive, defamatory, obscene, harassing, threatening, racist, sexist, hateful, or otherwise objectionable material;</p>
          <p>- Your Content does not contain any viruses, malware, or other harmful code or content that may interfere with the operation of the Platform or harm any user;</p>
          <p>- Your Content complies with all applicable laws and regulations.</p>
          <p>We do not endorse or guarantee the accuracy, quality, or integrity of any Content posted by you or other users on the Platform. You understand that by using the Platform, you may be exposed to Content that is offensive, inappropriate, or inaccurate. You agree that we are not liable for any Content posted by you or other users on the Platform.</p>
          <p>We reserve the right to review, remove, edit, block, or disable access to any Content at any time and for any reason in our sole discretion. We may also terminate or suspend your account if you violate these Terms or create liability for us or others.</p>
          <p>&nbsp;</p>
          <h3><strong>Booking Services</strong></h3>
          <p>The Platform enables venue operators to list their venues for rent and event organizers to book venues for their events. We are not a party to any agreements entered into between venue operators and event organizers. We are not a real estate broker, agent, or insurer. We have no control over the conduct of venue operators, event organizers, or any other users of the Platform. We disclaim all liability arising from or related to any such agreements.</p>
          <p>If you are a venue operator,</p>
          <p>- You are solely responsible for creating, updating, and maintaining your venue listings on the Platform. You must provide accurate, complete, and up-to-date information about your venue, including its availability, capacity, layout, equipment, pricing, and any other relevant details. You must comply with all applicable laws and regulations regarding your venue and its operation.</p>
          <p>- You are solely responsible for setting the terms and conditions of your venue rental agreement with event organizers. You must clearly communicate your cancellation policy, deposit policy, damage policy, and any other rules or requirements that apply to your venue rental. You must honor all bookings made through the Platform unless there is a valid reason for cancellation.</p>
          <p>- You are solely responsible for collecting and remitting any taxes that apply to your venue rental income. We are not responsible for determining your tax obligations or collecting or remitting any taxes on your behalf.</p>
          <p>- You are solely responsible for ensuring the safety and security of your venue and its guests. You must maintain adequate insurance coverage for your venue and its operation. You must comply with all health and safety standards and regulations that apply to your venue. You must promptly address any issues or complaints raised by event organizers or their guests.</p>
          <p>If you are an event organizer,</p>
          <p>- You are solely responsible for choosing a venue that meets your needs and expectations. You must review the venue listing carefully and communicate with the venue operator before booking a venue. You must comply with the terms and conditions of the venue rental agreement set by the venue operator, including their cancellation policy, deposit policy, damage policy, and any other rules or requirements that apply to the venue rental.</p>
          <p>- You are solely responsible for paying the fees and charges associated with your booking, including the venue rental fee, any service fees, taxes, or other costs that may apply. You must provide valid payment information and authorize us to charge your payment method for the total amount due at the time of booking or as otherwise agreed with the venue operator. You must pay any additional fees or charges that may arise from changes, cancellations, damages, or disputes related to your booking.</p>
          <p>- You are solely responsible for ensuring the safety and security of yourself and your guests at the venue. You must respect the property and privacy of the venue operator and their neighbors. You must comply with all applicable laws and regulations regarding your event and its conduct. You must promptly address any issues or complaints raised by the venue operator or their neighbors.</p>
          <p>&nbsp;</p>
          <h3><strong>Fees and Payments</strong></h3>
          <p>We may charge fees to venue operators (“Venue Fees”) and/or event organizers (“Service Fees”) for the use of our Platform. Venue Fees are deducted from the venue rental fee before remitting the balance to the venue operator. Service Fees are added to the venue rental fee and paid by the event organizer. The amount of Venue Fees and Service Fees will be displayed to both venue operators and event organizers before confirming a booking.</p>
          <p>We may also offer subscription plans to venue operators that provide access to additional features and benefits on our Platform. Subscription fees are charged to venues on a recurring basis, such as monthly or annually, depending on the plan selected. The amount and frequency of subscription fees will be displayed to venue operators before subscribing to a plan.</p>
          <p>We use third-party payment processors to facilitate payments on our Platform. By providing your payment information, you agree to be bound by the terms and conditions and privacy policies of the payment processors. We are not responsible for any errors, delays, or failures in processing payments.</p>
          <p>We reserve the right to change our fees and subscription plans at any time without notice. A venue's continued use of our Platform after such changes constitutes acceptance of the new fees and subscription plans. If a venue does not agree to the new fees or subscription plans, it must stop accessing or using the Platform or cancel its subscription.</p>
          <p>&nbsp;</p>
          <h3><strong>Cancellations and Refunds</strong></h3>
          <p>Cancellations and refunds are subject to the terms and conditions of the venue rental agreement between the venue operator and the event organizer. We do not have any control over or liability for such terms and conditions. We will process any refunds in accordance with our payment processor's policies and procedures.</p>
          <p>&nbsp;</p>
          <h3><strong>Disputes</strong></h3>
          <p>If you have a dispute with another user of our Platform, you agree to contact them directly and try to resolve it amicably. If you are unable to do so, you may contact us at support@doublespot.com and we will try to help you as a courtesy, but we are not obligated to do so. We are not responsible for resolving any disputes between users of our Platform.</p>
          <p>&nbsp;</p>
          <h3><strong>Modification of Services</strong></h3>
          <p>We retain the right to alter, limit access to, or terminate the provision of the Services (or any part thereof), either temporarily or permanently, with or without prior notification to you. We are under no obligation to provide support or updates to the Services. Unless explicitly stated otherwise, any fresh attributes that enhance or enrich the existing Services will be governed by these Terms.</p><p>Any modifications to these Terms will take effect immediately upon being posted. It is important to review the Terms each time you utilize the Services, as your continued use of the Services subsequent to such revisions will indicate your acceptance of and compliance with these alterations. Furthermore, you relinquish any entitlement you may possess to receive specific notifications regarding such changes.</p>
          <p>&nbsp;</p>
          <h3><strong>Termination</strong></h3>
          <p>We reserve the right, with or without notice, and in our sole discretion, to terminate any license to the Services, in whole or in part, and to block or prevent future access to and use of the Services.</p>
          <p>&nbsp;</p>
          <h3><strong>Prohibited Activities</strong></h3>
          <p>You agree not to engage in any of the following prohibited activities on our Platform:</p>
          <p>- Violating these Terms or any applicable laws or regulations;</p>
          <p>- Infringing or violating the rights of others, including intellectual property rights, privacy rights, publicity rights, or contractual rights;</p>
          <p>- Creating, uploading, posting, sending, receiving, or storing any Content that is unlawful, harmful, abusive, defamatory, obscene, harassing, threatening, racist, sexist, hateful, or otherwise objectionable;</p>
          <p>- Creating, uploading, posting, sending, receiving, or storing any Content that contains viruses, malware, or other harmful code or content;</p>
          <p>- Impersonating any person or entity, or misrepresenting your affiliation with any person or entity;</p>
          <p>- Creating multiple accounts or using another user's account without their permission;</p>
          <p>- Harvesting or collecting information about other users without their consent;</p>
          <p>- Sending unsolicited messages, spam, or other forms of commercial solicitation;</p>
          <p>- Interfering with or disrupting the operation of the Platform or its servers or networks;</p>
          <p>- Attempting to gain unauthorized access to the Platform or its servers or networks;</p>
          <p>- Modifying, adapting, translating, reverse engineering, decompiling, disassembling, or otherwise creating derivative works of the Platform or its content;</p>
          <p>- Using any automated means, such as bots, scripts, or spiders, to access or use the Platform or its content;</p>
          <p>- Framing, mirroring, or linking to any part of the Platform or its content without our prior written consent;</p>
          <p>- Violating the rights or interests of us or any other user of the Platform.</p>
          <p>We reserve the right to investigate and prosecute any violations of these Terms to the fullest extent of the law. We may also terminate or suspend your access to or use of the Platform at any time and for any reason in our sole discretion.</p>
          <p>&nbsp;</p>
          <h3><strong>RELEASE</strong></h3>
          <p>YOU AGREE THAT YOU BEAR ALL RISK AND YOU AGREE TO AND DO HEREBY RELEASE DOUBLESPOT, ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SHAREHOLDERS, LICENSORS, LICENSEES AND SUPPLIERS FROM ALL CLAIMS, DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, NOW AND IN THE FUTURE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICES, YOUR USE OF, OR TRANSACTIONS WITH, DOUBLESPOT OR OTHER THIRD PARTIES. YOU FURTHER WAIVE ANY AND ALL RIGHTS AND BENEFITS OTHERWISE CONFERRED BY ANY STATUTORY OR NON-STATUTORY LAW OF ANY JURISDICTION THAT WOULD PURPORT TO LIMIT THE SCOPE OF A RELEASE OR WAIVER. YOU WAIVE AND RELINQUISH ALL RIGHTS AND BENEFITS WHICH YOU HAVE OR MAY HAVE UNDER SECTION 1542 OF THE CIVIL CODE OF THE STATE OF CALIFORNIA OR ANY SIMILAR PROVISION OF THE STATUTORY OR NON-STATUTORY LAW OF ANY OTHER JURISDICTION TO THE FULL EXTENT THAT YOU MAY LAWFULLY WAIVE ALL SUCH RIGHTS AND BENEFITS.</p>
          <p>&nbsp;</p>
          <h3><strong>DISCLAIMER OF WARRANTIES</strong></h3>
          <p>THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, DOUBLESPOT DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED, STATUTORY, AND OTHERWISE IN CONNECTION WITH THE SERVICES AND YOUR USE OF OR TRANSACTIONS WITH DOUBLESPOT OR OTHER THIRD PARTIES, INCLUDING BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, SECURITY, ACCURACY, AVAILABILITY, USE OF REASONABLE CARE AND SKILL, AND NON- INFRINGEMENT, AS WELL AS WARRANTIES ARISING BY USAGE OF TRADE, COURSE OF DEALING, AND COURSE OF PERFORMANCE. DOUBLESPOT DOES NOT WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR FREE OF ERRORS, VIRUSES OR OTHER HARMFUL CODE, OR THAT ANY ERRORS OR DEFECTS WILL BE CORRECTED.</p>
          <p>&nbsp;</p>
          <h3><strong>LIMITATION OF LIABILITY</strong></h3>
          <p>IN NO EVENT SHALL DOUBLESPOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER ARISING OUT OF, BASED ON, OR RESULTING FROM YOUR USE OF THE SERVICES AND YOUR USE OF, OR TRANSACTIONS WITH DOUBLESPOT OR OTHER THIRD PARTIES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, NEGLIGENCE OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT DOUBLESPOT IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>
          <p>IN NO EVENT WILL THE COLLECTIVE LIABLITY OF DOUBLESPOT, ARISING OUT OF OR IN CONNECTION WITH THESE TERMS, THE SERVICES, AND YOUR USE OF, OR TRANSACTIONS WITH, DOUBLESPOT, OR OTHER THIRD PARTIES EXCEED THE AMOUNT YOU HAVE PAID TO DOUBLESPOT FOR USE OF THE APPLICABLE SERVICES IN THE PRECEDING TWELVE MONTH PERIOD OR TEN DOLLARS, IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO DOUBLESPOT, AS APPLICABLE.</p>
          <p>&nbsp;</p>
          <h3><strong>CLASS ACTION WAIVER</strong></h3>
          <p>ANY DISPUTE RESOLUTION PROCEEDINGS WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION OR AS A NAMED OR UNNAMED MEMBER IN A CLASS, CONSOLIDATED, REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL LEGAL ACTION. YOUR ACCESS AND CONTINUED USE OF THE SERVICES SIGNIFIES YOUR EXPLICIT CONSENT TO THIS WAIVER.</p>
          <p>&nbsp;</p>
          <h3><strong>BINDING ARBITRATION</strong></h3>
          <p>ANY CLAIM OR DISPUTE BETWEEN YOU AND US SHALL BE DECIDED BY BINDING ARBITRATION RATHER THAN IN COURT. THE ARBITRATION WILL BE ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION UNDER ITS CONSUMER ARBITRATION RULES. YOU AGREE TO THE EXCLUSIVE JURISDICTION OF COURTS IN ILLINOIS FOR ENFORCEMENT OF ANY ARBITRATION DECISION.</p>
          <p>&nbsp;</p>
          <h3><strong>Indemnification</strong></h3>
          <p>You agree to indemnify, defend, and hold harmless DoubleSpot, its affiliates, and their respective officers, directors, employees, agents, shareholders, licensors, licensees, and suppliers from and against any claims, disputes, losses, obligations, demands, liabilities, expenses, damages, costs, and attorneys' fees due to or arising out of (1) your access to or use of the Services, (2) your use of, or transactions with, DoubleSpot, venue operators, event organizers, or other third parties, or (3) your violation of any of these Terms.</p>
          <p>DoubleSpot reserves the right, at its own cost and sole discretion, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with DoubleSpot in asserting any available defenses.</p>
          <h3><strong>Governing Law</strong>&nbsp;&nbsp;&nbsp;&nbsp;</h3>
          <p>These Terms are governed by the laws of the State of Illinois, U.S.A., without giving effect to any conflict of laws principles.</p>
          <p>Any legal action or proceeding arising under these Terms will be brought exclusively in the federal or state courts located in Cook County, Illinois and you consent to personal jurisdiction and venue in those courts. Neither party will be liable for any delays arising from circumstances beyond its reasonable control.</p>
          <p>&nbsp;</p>
          <h3><strong>Intellectual Property</strong></h3>
          <p>The Platform and all content on the Platform, including but not limited to text, graphics, logos, images, source code, software, and the selection and arrangement thereof ("Platform Content") is the property of DoubleSpot and its licensors, and is protected by copyright, trademark, patent, trade secret, international treaties, and other proprietary rights and laws.</p>
          <p>All Platform Content belongs to DoubleSpot. By creating, uploading, posting, sending, receiving, or storing Content on the Platform, you grant DoubleSpot a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, publicly perform and display such Content.</p>
          <p>&nbsp;</p>
          <h3><strong>Trademarks</strong></h3>
          <p>The DoubleSpot name, logo, and all related names, logos, product and service names, designs, and slogans are trademarks and/or trade dress of DoubleSpot. You must not use any DoubleSpot trademarks or trade dress without the prior written permission of DoubleSpot. All other names, logos, product and service names, designs, and slogans on the Platform are the trademarks of their respective owners.</p>
          <p>&nbsp;</p>
          <h3><strong>Copyright Infringement Claims</strong></h3>
          <p>DoubleSpot respects the intellectual property rights of others. If you believe your copyrighted work has been reproduced or distributed in a way that constitutes copyright infringement, please notify our Copyright Agent providing the written information specified below.</p>
          <p>To be effective, your notice must include the following:</p>
          <p>(i) electronic or physical signature of the copyright owner or someone authorized to act on behalf of the owner;</p>
          <p>(ii) identification of the copyrighted work claimed to be infringed;</p>
          <p>(iii) identification and URL for the material that is claimed to be infringing or to be the subject of infringing activity;</p>
          <p>(iv) your name, address, telephone number, and email address;</p>
          <p>(v) a statement that you have a good faith belief that use of the disputed material is not authorized by the copyright owner, its agent, or the law; and</p>
          <p>(vi) a statement made under penalty of perjury that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.</p>
          <p>DoubleSpot's Copyright Agent for notice of claims of copyright infringement can be reached as follows:</p>
          <p>support@doublespot.com</p>
          <p>&nbsp;</p>
          <h3><strong>FTC Disclosures</strong></h3>
          <p>DoubleSpot is not compensated for reviews or endorsements. References and links to products or services do not constitute an endorsement or recommendation of those products or services by DoubleSpot. Any references to third party products, services, companies, or individuals is not an indication that DoubleSpot has relationships with, sponsors, or endorses any such third parties or their products, services, or business practices.</p>
          <p>DoubleSpot does not recommend or endorse any specific providers of products or services that may be mentioned on the Platform. The views and opinions expressed by other users are solely those of such users and do not reflect the opinions or policies of DoubleSpot.</p>
          <p>&nbsp;</p>
          <h3><strong>Attorney Fees</strong></h3>
          <p>If DoubleSpot takes legal action against you as a result of your violation of these Terms, DoubleSpot will be entitled to recover from you, and you agree to pay all reasonable attorneys' fees and costs of such action, in addition to any other relief granted to DoubleSpot.</p>
          <p>&nbsp;</p>
          <h3><strong>Reservation of Rights</strong></h3>
          <p>We specifically reserve all rights that we do not expressly grant in these Terms. Nothing on the Site or in these Terms grants any right or license to use any property of any third party.</p>
          <p>&nbsp;</p>
          <h3><strong>Miscellaneous</strong></h3>
          <p>These Terms constitute the entire agreement between you and us regarding your access to and use of the Platform. These Terms do not create any partnership, joint venture, employment, or agency relationship between you and us. These Terms do not confer any rights or remedies on any third parties. If any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, such provision will be modified to the extent necessary to make it enforceable and the remaining provisions will remain in full force and effect. Our failure to enforce any right or provision of these Terms will not be considered a waiver of such right or provision. Our rights and remedies under these Terms are cumulative and not exclusive. We may assign or transfer our rights and obligations under these Terms without your consent. You may not assign or transfer your rights or obligations under these Terms without our prior written consent.</p>
          <p>&nbsp;</p>
          <h3><strong>Contact Us</strong></h3>
          <p>If you have any questions, please contact us at support@doublespot.com.</p>
        </div>
      </section>
    </>
  );
}

export default Terms_of_use;
