import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";

const MembershipFilterModal = ({ visible, onClose, updateFilters, updateFiltersMultiselect, filters, membershipTypes }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <div class="pb-3">
                    <CFormLabel htmlFor="email">Email</CFormLabel>
                    <CFormInput type="text" name="email" value={filters.email}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="membership_types">Membership Types</CFormLabel>
                    <MultiSelectCheckbox
                        name="membership_types"
                        onChange={updateFiltersMultiselect}
                        options={membershipTypes.map(role => ({
                            label: role.name,
                            value: role.id,
                        }))}
                        value={filters.membership_types ? filters.membership_types : []}
                    />
                </div>
                <CFormLabel htmlFor="venues">Expiration Date</CFormLabel>
                <div class="flex-row pb-3">
                    <div class="col-6 pe-2">
                        <CFormLabel htmlFor="min_expiration_date">Earliest</CFormLabel>
                        <CFormInput type="date" name="min_expiration_date" value={filters.min_expiration_date}
                                    onChange={updateFilters}/>
                    </div>
                    <div class="col-6 ps-2">
                        <CFormLabel htmlFor="max_expiration_date">Latest</CFormLabel>
                        <CFormInput type="date" name="max_expiration_date" value={filters.max_expiration_date}
                                    onChange={updateFilters}/>
                    </div>
                </div>
            </CModalBody>
        </CModal>
    )
};

export default MembershipFilterModal;
