import React, {useEffect, useState} from "react";
import {
    CButton,
    CCard,
    CCardBody,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {getProviderVenueListing, updateTask} from "../../../../redux/action/action";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import FilterModal from "./FilterModal";
import AddSessionModal from "./AddSessionModal";
import SessionList from "./SessionList"
import {columns, filterCategories} from "./FilterUtils";
import Filters from "../../../components/Filters";

const ProgramTable = () => {
    document.title = "Programs | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Organize your programs with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();

    const venueListings = useSelector(state => state.Apis.providerVenuesListing);

    const [venues, setVenues] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [addSessionModalVisible, setAddSessionModalVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [sessionFormData, setSessionFormData] = useState({});

    const updateFilters = (event) => {
        let newValue = event.target.value;
        if (event.target.name === "any_tags") {
            newValue = event.target.checked;
        }
        setFilters({...filters, [event.target.name]: newValue});
    }

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    }

    const onTaskStatusChange = (taskId, newStatus) => {
        dispatch(updateTask({
            id: taskId,
            status: newStatus,
        }));
    }

    const closeAddSessionModal = () => {
        setSessionFormData({});
        setAddSessionModalVisible(false);
    }

    useEffect(() => {
        setPrograms([
            {
                title: "Beginner Pickleball",
                description: `Join our beginner boot camp! You will learn the basic stroke mechanics, \ 
                    rules/scoring, and basic shots needed to get you playing matches on your own! No equipment needed!`,
                sessions: [
                        {
                            start_date: "Nov 4, 2024",
                            end_date: "Mar 7, 2025",
                            day: "M W F",
                            time: "5:00pm - 6:30pm",
                            instructor: "Jerry Tudor",
                            venue: "Highland Park Pickleball Court",
                            availability: "12/30",
                            price: "$1500.00"
                        },
                        {
                            start_date: "Nov 4, 2024",
                            end_date: "Mar 7, 2025",
                            day: "T W R",
                            time: "7:30pm - 9:00pm",
                            instructor: "Adam Scott",
                            venue: "Highland Park Pickleball Court",
                            availability: "5/30",
                            price: "$1500.00"
                        }
                ]
            },
            {
                title: "Advanced Basketball",
                description: "Our Advanced Basketball Program focuses on advanced skills and game strategies \
                    with high-intensity training. Athletes receive personalized coaching to prepare for competitive or collegiate-level play."
            },
            {
                title: "Youth Beginner Swimming",
                description: "Our Youth Beginner Swimming Program teaches water safety, floating, and basic strokes \
                    in a supportive environment. Ideal for kids with little to no swimming experience, guided by certified instructors."
            },
            {
                title: "Kids Art and Crafts",
                description: "Our program encourages creativity through hands-on projects like painting, drawing, and sculpture. \
                    Children will explore different materials and techniques in a fun. Perfect for young artists of all skill levels!"
            }
        ])
    }, [])

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        setVenues(venueListings);
    }, [venueListings]);


    useEffect(() => {
        let count = 0;
        filterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (filters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (filters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (filters[minCategory] || filters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setFilterCount(count);
    }, [filters]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Programs</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            filterCount={filterCount}
                            updateFilters={updateFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setFilterModalVisible}
                        />
                        <div>
                            <CButton color="light" href={`/business/programs/create-program`} target="_blank">
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Program
                            </CButton>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(() => setAddSessionModalVisible(true))}
                        data={programs}
                        expandableRows
                        expandableRowsComponent={row => <SessionList
                            data={row.data}
                            onTaskStatusChange={onTaskStatusChange}
                            dummyProp="dummy"
                        />}
                        expandableRowDisabled={row => (row?.sessions || []).length === 0}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <FilterModal
                visible={filterModalVisible}
                onClose={() => setFilterModalVisible(false)}
                updateFilters={updateFilters}
                updateFiltersMultiselect={updateFiltersMultiselect}
                filters={filters}
                venues={venues}
            />
            <AddSessionModal
                visible={addSessionModalVisible}
                onClose={closeAddSessionModal}
                sessionFormData={sessionFormData}
                setSessionFormData={setSessionFormData}
                venues={venues}
            />
        </div>
    );
}

export default ProgramTable;
