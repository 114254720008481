import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPwd } from "../../redux/action/action";


const BizForgotPassword = () => {
    const dispatch = useDispatch() 
    
    const [formData, setFormData] = useState({})
    const onChangeData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

    const submitPwdRecover = (e) => {
        e.preventDefault()
        dispatch(forgotPwd(formData))
    }

    useEffect(() => {
        setFormData({ ...formData, ["role"]: 2})
    }, [])
   
    return <>
        <section className="login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2 className="animate__backOutRight">Password Recovery</h2>
                            <form onSubmit={(e) => submitPwdRecover(e)}>
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input 
                                        type="email"
                                        name="email"
                                        required="true"
                                        onChange={onChangeData}
                                        className="form-control" 
                                        id="email" 
                                        placeholder="email"
                                    />
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="submit_modal pr_submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default BizForgotPassword