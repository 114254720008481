const {jsPDF} = require("jspdf");
const {autoTable} = require("jspdf-autotable4");
const {formatDate} = require("./DateTimeUtils");

const columns = [
    {
        name: 'Title',
        selector: row => row.title,
        pdfFormat: row => row.title,
        pdfWidth: 100,
    },
    {
        name: 'Status',
        selector: row => row.status,
        pdfFormat: row => {
            switch (row.status) {
                case 1:
                    return "Confirmed";
                case 2:
                    return "Pending";
                case 3:
                    return "Internal";
            }
        },
        pdfWidth: 75,
    },
    {
        name: 'Venue',
        selector: row => row.venue?.name,
        pdfFormat: row => row.venue?.name,
        pdfWidth: 100,
    },
    {
        name: 'Start Time',
        selector: row => new Date(`${row.date} ${row.start_time}`),
        pdfFormat: row => formatDate(row.date, row.start_time, false),
        pdfWidth: 100,
    },
    {
        name: 'End Time',
        selector: row => new Date(`${row.end_date} ${row.end_time}`),
        pdfFormat: row => formatDate(row.end_date, row.end_time, false),
        pdfWidth: 100,
    },
    {
        name: 'Event Type',
        selector: row => row.eventtype?.name,
        pdfFormat: row => row.eventtype?.name || "Other",
        pdfWidth: 75,
    },
    {
        name: 'Tags',
        selector: row => row.tags,
        pdfFormat: row => row.tags?.map(tag => tag.name)?.join(", "),
        pdfWidth: 150,
    },
    {
        name: 'Attendees',
        selector: row => row.attendees,
        pdfFormat: row => row.attendees,
        pdfWidth: 50,
    },
    {
        name: 'Revenue',
        selector: row => row.booked_price,
        pdfFormat: row => `$ ${row.booked_price}`,
        pdfWidth: 50,
    },
    {
        name: 'Confirmation No.',
        selector: row => row.confirmation_no ? row.confirmation_no : "N/A",
        pdfFormat: row => row.confirmation_no ? row.confirmation_no : "N/A",
        pdfWidth: 75,
    },
    {
        name: 'Customer',
        selector: row => {
            const name = `${row.customer_first_name} ${row.customer_last_name}`;
            return !name || name === " " ? "N/A" : name;
        },
        pdfFormat: row => {
            let name = `${row.customer_first_name} ${row.customer_last_name}`;
            name = !name || name === " " ? "N/A" : name;
            const number = row.customer_number || "N/A";
            const email = row.customer_email || "N/A";
            return `${name}\n${number}\n${email}`;
        },
        pdfWidth: 100,
    },
    {
        name: 'Description',
        selector: row => row.customer_message || "N/A",
        pdfFormat: row => row.customer_message || "N/A",
        pdfWidth: 125,
    },
];

const REVENUE_ROW = 8;

const downloadColumnOptions = columns.map((column, index) => ({
    label: column.name,
    value: index,
}));

/**
 *
 * @param columnIds {number[]} The column indices to be included in the event report.
 * @param selectedRows {Object[]} The rows of events to be included in the event report.
 * @param sortBy {number} The column index to sort the event report by.
 * @param sortDesc {Boolean} Whether the PDF should be sorted from larger values to smaller ones.
 * @param displayBookedPrice {Boolean} Use column name "booked price" in place of "revenue" for customer-facing reports.
 * @param displayTotalPrice {Boolean} Add an extra row at the end of the table to display total price.
 * @returns {Object} The jspdf instance with the complete event report.
 */
const generatePdf = (columnIds, selectedRows, sortBy, sortDesc, displayTotalPrice, displayBookedPrice) => {
    let tableWidth = 0;
    const padding = 40;
    const columnStyles = Object.fromEntries(columnIds.map((columnId, index) => {
        const cellWidth = columns[columnId].pdfWidth;
        tableWidth += cellWidth;
        return [index, {cellWidth}];
    }));
    const columnDisplay = [columnIds.map(columnId => {
        if (columnId === REVENUE_ROW) {
            return displayBookedPrice ? "Booked Price" : columns[columnId].name;
        } else {
            return columns[columnId].name;
        }
    })];
    let body = selectedRows;
    if (sortBy !== -1) {
        body.sort((row1, row2) => {
            const value1 = columns[sortBy].selector(row1);
            const value2 = columns[sortBy].selector(row2);

            if (value1 < value2) return sortDesc ? 1 : -1;
            if (value1 > value2) return sortDesc ? -1 : 1;
            return 0;
        });

    }
    body = body.map(row => columnIds.map(columnId => columns[columnId].pdfFormat(row)));
    if (displayTotalPrice) {
        if (columnIds.includes(REVENUE_ROW)) {
            let totalAmount = 0;
            selectedRows.map(row => {
                totalAmount += row.booked_price;
            })
            const totalRow = columnIds.map((columnId, index) => {
                if (columnId === REVENUE_ROW) {
                    return `$ ${totalAmount}`;
                }
                // Show total price as row title on the leftmost column unless the column is already total price itself
                if (index === 0 || (columnIds[0] === REVENUE_ROW && index === 1)) {
                    return "Total Price"
                }
                return "";
            });
            body.push(totalRow);
        }
    }
    const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px',
        format: [600, tableWidth + padding * 2],
    });
    autoTable(pdf, {
        head: columnDisplay,
        foot: columnDisplay,
        body,
        theme: "plain",
        columnStyles,
        tableWidth,
    });

    return pdf;
}

module.exports = {
    downloadColumnOptions,
    generatePdf,
};
