import React,{useCallback, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'reactstrap';
import { logInUser } from "../../redux/action/action";
import {
    openSignUpModal,
    openSignInModal,
    openForgotModal,
    closeModal
} from "../../redux/action/accountModals";
import { jwtDecode } from "jwt-decode";
import {microsoftSignIn} from "./MicrosoftAuth";
import {users} from "constants";

function SignIn(props) {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        "role" : 1
    })

    const openSignIn = useSelector(state => state.HandleModals.openSignInModal);

    // For SignIn modal
    const toggleSignIn = () => dispatch(openSignInModal());
    const [provider, setProvider] = useState('');
    const [profile, setProfile] = useState();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
    const submitlogInUser = (e) => {
        e.preventDefault()
        //    console.log("working......." ,formData)
        // setSignIn(false)
        dispatch(logInUser(formData))
    }
  
    const onLoginStart = useCallback(() => {
      alert('login start');
    }, []);
  
    const onLogoutSuccess = useCallback(() => {
      setProfile(null);
      setProvider('');
      alert('logout success');
    }, []);

    console.log(provider ,profile );

    function handleGoogleResponse(googleUser, error) {
        const token = googleUser.credential;
        const creds = jwtDecode(token);
        creds.role = users.ROLE_CUSTOMER;
        creds.social_type = users.SOCIAL_TYPE_GOOGLE;
        dispatch(logInUser(creds));
    }

    const googleButtonRef = useCallback(node => {
        if (node) {
            window.google.accounts.id.initialize({
                client_id: `${process.env.REACT_APP_GOOGLE_AUTHENTICATION_CLIENT_ID}.apps.googleusercontent.com`,
                callback: handleGoogleResponse
            });
            window.google.accounts.id.renderButton(node, {
                type: 'icon',
                size: 'large',
                text: 'signin_with'
            });
        }
    })

    return <>
        {props.btn == "navbar" && 
            <li className="nav-item">
                <a className="nav-link bg-white border" onClick={toggleSignIn} data-bs-target="#login"
                    data-bs-toggle="modal">Login</a>
            </li>
        }
        {props.btn == "Request" && 
            <button type="button" className="btn form-control rounded-pill" onClick={toggleSignIn}>Reserve</button>
        }
        <Modal className="singInModal s_modal" isOpen={openSignIn} toggle={() => {dispatch(closeModal())}} style={{maxWidth: "768px"}}>
            <div className="modal-header border-0 pb-0">
                <button type="button" className="btn-close" onClick={() => {dispatch(closeModal())}} aria-label="Close"/>
            </div>
            <div className="modal-body px-lg-5 px-md-3 pb-4">
                <div className="row">
                    <div className="col-lg-6 col-md-5  d-lg-block d-none">
                        <div className="modal_side_img w-100 h-100">
                            <img src="../../../assets/images/login_img.png" alt="DoubleSpot.com" />
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2>Login</h2>
                            <div className="social_modal_icons">
                                <div ref={googleButtonRef}/>
                                <div style={{width: "10px"}}/>
                                <a href="#" onClick={() => microsoftSignIn(dispatch)}><img
                                    src="../../../assets/images/microsoft_icon.svg" alt="DoubleSpot.com"/></a>
                                {/*<LoginSocialFacebook*/}
                                {/*    appId={process.env.REACT_APP_FB_APP_ID || ''}*/}
                                {/*    fieldsProfile={*/}
                                {/*        'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'*/}
                                {/*    }*/}
                                {/*    onLoginStart={onLoginStart}*/}
                                {/*    onLogoutSuccess={onLogoutSuccess}*/}
                                {/*    redirect_uri={REDIRECT_URI}*/}
                                {/*    onResolve={({ provider, data }) => {*/}
                                {/*        console.log(provider, data ,"ppppp");*/}
                                {/*        setProvider(provider);*/}
                                {/*        setProfile(data);*/}
                                {/*    }}*/}
                                {/*    onReject={err => {*/}
                                {/*        console.log(err);*/}
                                {/*    }}*/}
                                {/*    >*/}
                                {/*    <a href="#"><img src="../../../assets/images/facebook_icon.png" alt="DoubleSpot.com" /></a>*/}
                                {/*    </LoginSocialFacebook>*/}
                            </div>
                            <div className="via_line">
                            <strong>or do it via E-mail</strong>
                            </div>

                            <form onSubmit={(e) => submitlogInUser(e)}>
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        onChange={onChange}
                                        required="true"
                                        name="email"
                                        placeholder="john@gmail.com" />
                                </div>
                                <div className="form-group">
                                    <label for="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        required="true"
                                        placeholder="password"
                                        onChange={onChange}
                                        name="password"
                                    />
                                    {/* <div id="passwordHelp" className="form-text">Must be 8 characters at least</div> */}
                                </div>
                                <div className="form-group mt-3 d-flex justify-content-start gap-2 align-items-center">
                                    {/* <label className="switch">
                                        <input type="checkbox" id="swich01" />
                                        <span className="slider round"></span>
                                    </label>
                                    <label for="swich01">Remember Me</label> */}

                                    <label for="forgot" className="ms-auto " style={{ cursor: "pointer" }}
                                        data-bs-target="#forgot" data-bs-toggle="modal"
                                        onClick={() => {
                                            dispatch(openForgotModal())
                                        }} >
                                        <u>Forgot Password?</u>
                                    </label>
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="submit_modal">Login</button>
                                </div>

                                <span className="d-block text-center">Don't have an account? <a className="text-black"
                                    onClick={() => {
                                        dispatch(openSignUpModal())
                                    }} data-bs-toggle="modal"> <u>Register</u>
                                </a></span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}
export default SignIn
