import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from 'reactstrap';
import '../../App.css';


function ResetPwdSuccess(props) {
    const [RS, setRS] = useState(false);

    useEffect(() => {
        setRS(props.openModalStatus)
    }, [props.openModalStatus])

    const toggleRS = () => setRS(!RS);

    function closeModal() {
        props.onData("close")
    }

    return <>
        <Modal className="singInModal s_modal" isOpen={RS} toggle={() => {toggleRS(); closeModal()}}>
            <div className="modal-header border-0">
                <button type="button" className="btn-close" onClick={() => {toggleRS(); closeModal()}} data-bs-dismiss="modal" aria-label="Close"><i
                    className="fa-solid fa-xmark"></i></button>
            </div>
            <div className="modal-body px-lg-4 px-md-3 ">
                <div className="row">
                    <div className="col-lg-6 col-md-5 d-lg-block d-none">
                        <div className="modal_side_img w-100 h-100">
                            <img src="../../../assets/images/login_img.png" alt="DoubleSpot.com" />
                        </div>
                    </div>


                    <div className="col-lg-6 d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2>Reset Password Success!</h2>
                            <Link to={'/'}> <a  class="link_404">Go to Home</a></Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </Modal>
        
    </>
}

export default ResetPwdSuccess