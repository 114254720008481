import {
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CImage
} from "@coreui/react";
import {formatDate} from "../../../../Utils/DateTimeUtils";
import {bookvenues, users} from "constants";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {cilPencil, cilPlus, cilTrash} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    updateTask,
    SITE_URL
} from "../../../../redux/action/action";
import {useNavigate} from "react-router-dom";
import {columns} from "../../../../Utils/ProgramUtils";
import AddSessionModal from "../ProgramList/AddSessionModal";


const Program = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    
    const [sessions, setSessions] = useState({});
    const [addSessionModalVisible, setAddSessionModalVisible] = useState(false);
    const [sessionFormData, setSessionFormData] = useState({});
    const [venues, setVenues] = useState([]);

    const customStyles = {
        rows: {
            style: {
                cursor: "pointer"
            }
        }
    }
    
    const onTaskStatusChange = (taskId, newStatus) => {
        dispatch(updateTask({
            id: taskId,
            status: newStatus,
        }));
    }

    const closeAddSessionModal = () => {
        setSessionFormData({});
        setAddSessionModalVisible(false);
    }

    const onRowClicked = (row, event) => {
        navigate("/business/programs/session/1");
    }

    useEffect(() => {
        setVenues([
            {
                id: 0,
                name: "No Change",
            },
            ...venueListings
        ]);
    }, [venueListings]);

    useEffect(() => {
        setSessions([
            {
                start_date: "Nov 4, 2024",
                end_date: "Mar 7, 2025",
                day: "M W F",
                time: "5:00pm - 6:30pm",
                instructor: "Jerry Tudor",
                venue: "Highland Park Pickleball Court",
                availability: "12/30",
                price: "$1500.00"
            },
            {
                start_date: "Nov 4, 2024",
                end_date: "Mar 7, 2025",
                day: "T W R",
                time: "7:30pm - 9:00pm",
                instructor: "Adam Scott",
                venue: "Highland Park Pickleball Court",
                availability: "5/30",
                price: "$1500.00"
            },
        ])
    }, [])

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <h2>Beginner Pickleball</h2>
                        <div className="flex-row">
                            <CButton color="light" onClick={() => navigate("/business/programs/create-program/1")}>
                                <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit
                            </CButton>
                            <CButton color="danger" className="white ms-2">
                                <CIcon icon={cilTrash} className="white"/> &nbsp; Delete
                            </CButton>
                        </div>       
                    </div>
                    <hr/>
                    <div className="flex-row">
                        <div>
                            <h4 className="mb-3 pe-2">Description</h4>
                            <p>Join our beginner boot camp! You will learn the basic stroke mechanics, rules/scoring, 
                                and basic shots needed to get you playing matches on your own! No equipment needed!</p>
                        </div>
                        <div>
                            <CImage src={"https://i.postimg.cc/cJ8ZG5ms/365368536.jpg"} thumbnail width={250}/>
                        </div>
                    </div>
                    
                    <div className="flex-row justify-content-between align-items-end mt-4">
                        <div>
                            <h4>Sessions</h4>
                        </div>
                        <div>
                            <CButton color="light" onClick={() => setAddSessionModalVisible(true)}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Session
                            </CButton>
                        </div>
                    </div>
                    <DataTable
                        columns={[
                            ...columns(onTaskStatusChange),
                            ...[{
                                name: '',
                                cell: row => (
                                    <div class="py-2">
                                        <CButton color="dark" variant="outline">
                                            Edit
                                        </CButton>
                                    </div>
                                ),
                                minWidth: "100px",
                                right: true,
                            },
                            {
                                name: '',
                                cell: row => (
                                    <div class="py-2">
                                        <CButton color="danger" className="white">
                                            Delete
                                        </CButton>
                                    </div>
                                ),
                                width: "105px",
                                right: true,
                            }]
                        ]}
                        data={sessions}
                        highlightOnHover={true}
                        onRowClicked={onRowClicked}
                        customStyles={customStyles}
                    />
                    <AddSessionModal
                        visible={addSessionModalVisible}
                        onClose={closeAddSessionModal}
                        sessionFormData={sessionFormData}
                        setSessionFormData={setSessionFormData}
                        venues={venues}
                    />
                </CCardBody>
            </CCard>
        </div>
    );
};

export default Program;
