import {
    CButton,
    CCard,
    CCardBody,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilCloudUpload, cilKeyboard, cilPlus} from "@coreui/icons";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {users} from "constants";
import {
    deleteMembership,
    deleteMembershipType,
    getMemberships,
    getMembershipTypes,
    setAlert
} from "../../../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {applyMembershipFilters, membershipColumns, membershipFilterCategories, roleColumns} from "./FilterUtils";
import EditMembershipTypeModal from "./EditMembershipTypeModal";
import Filters from "../../../components/Filters";
import MembershipFilterModal from "./MembershipFilterModal";
import EditMembershipEntryModal from "./EditMembershipEntryModal";
import UploadCsvModal from "./UploadCsvModal";

const Members = () => {
    document.title = "Members | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Manage booking customers' memberships with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);
    const membershipTypes = useSelector(state => state.Apis.membershipTypes);
    const updatedMembershipType = useSelector(state => state.Apis.updatedMembershipType);
    const memberships = useSelector(state => state.Apis.memberships);
    const updatedMembership = useSelector(state => state.Apis.updatedMembership);

    const [editTypeModalVisible, setEditTypeModalVisible] = useState(false);
    const [editTypeFormData, setEditTypeFormData] = useState({});
    const [allMembershipTypes, setAllMembershipTypes] = useState([]);
    const [displayedMembershipTypes, setDisplayedMembershipTypes] = useState([]);
    const [membershipTypesSearch, setMembershipTypesSearch] = useState("");
    const [membershipFilters, setMembershipFilters] = useState({});
    const [membershipFilterCount, setMembershipFilterCount] = useState(0);
    const [membershipFilterModalVisible, setMembershipFilterModalVisible] = useState(false);
    const [displayedMemberships, setDisplayedMemberships] = useState([]);
    const [editEntryModalVisible, setEditEntryModalVisible] = useState(false);
    const [editEntryFormData, setEditEntryFormData] = useState({});
    const [uploadCsvModalVisible, setUploadCsvModalVisible] = useState(false);

    const onAddTypeButtonClick = () => {
        setEditTypeModalVisible(true);
        setEditTypeFormData({
            id: null,
            name: "",
        });
    }

    const onEditTypeButtonClick = (type) => {
        setEditTypeModalVisible(true);
        setEditTypeFormData({
            id: type.id,
            name: type.name,
        });
    }

    const onDeleteTypeButtonClick = (id) => {
        if (window.confirm("Are you sure you want delete this membership type? Entries of customers with the membership type will be deleted as well.")) {
            dispatch(deleteMembershipType({id}));
        }
    }

    const updateMembershipFilters = (event) => {
        setMembershipFilters({...membershipFilters, [event.target.name]: event.target.value});
    }

    const updateMembershipFiltersMultiselect = (selectedOptions, actionMeta) => {
        setMembershipFilters({...membershipFilters, [actionMeta.name]: selectedOptions});
    }

    const onAddEntryButtonClick = () => {
        setEditEntryModalVisible(true);
        setEditEntryFormData({
            id: null,
            email: "",
            membership_types: [],
            expire_on: "",
        });
    }

    const onEditEntryButtonClick = (entry) => {
        setEditEntryModalVisible(true);
        setEditEntryFormData({
            id: entry.id,
            email: entry.email,
            membership_type_id: entry.membership_type?.id,
            expire_on: entry.expire_on,
        });
    }

    const onDeleteEntryButtonClick = (id) => {
        if (window.confirm("Are you sure you want delete this membership entry?")) {
            dispatch(deleteMembership({id}));
        }
    }

    useEffect(() => {
        setEditTypeModalVisible(false);
        dispatch(getMembershipTypes());
        dispatch(getMemberships());
    }, [updatedMembershipType]);

    useEffect(() => {
        setEditEntryModalVisible(false);
        setUploadCsvModalVisible(false);
        dispatch(getMemberships());
    }, [updatedMembership]);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role === users.ROLE_VENUE_OWNER) {
            } else if (userDetail?.manager?.editCustomerAccess) {
            } else {
                dispatch(setAlert('You do not have the permissions to view the customers.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        setAllMembershipTypes(membershipTypes);
        let newDisplayedMembershipTypes = membershipTypes;
        if (membershipTypesSearch) {
            const searchTerm = membershipTypesSearch.toLowerCase();
            newDisplayedMembershipTypes = newDisplayedMembershipTypes.filter(entry => entry.name.toLowerCase().includes(searchTerm));
        }
        setDisplayedMembershipTypes(newDisplayedMembershipTypes);
    }, [membershipTypes, membershipTypesSearch]);

    useEffect(() => {
        let count = 0;
        membershipFilterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (membershipFilters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (membershipFilters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (membershipFilters[minCategory] || membershipFilters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setMembershipFilterCount(count);
    }, [membershipFilters]);

    useEffect(() => {
        setDisplayedMemberships(applyMembershipFilters(memberships, membershipFilters));
    }, [memberships, membershipFilters]);

    return (
        <div class="p-4 w-100">
            <CCard className="mb-4">
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Membership Types</h2>
                        </div>
                        <div>
                            <CButton color="light" onClick={onAddTypeButtonClick}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Membership Type
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row flex-wrap align-items-center">
                        <div class="pe-2 pb-3">
                            <CFormInput placeholder="Search..." onChange={
                                (event) => setMembershipTypesSearch(event.target.value)
                            }/>
                        </div>
                    </div>
                    <DataTable
                        columns={roleColumns(onEditTypeButtonClick, onDeleteTypeButtonClick)}
                        data={displayedMembershipTypes}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Membership Entries</h2>
                            <p className="modal-subtitle mb-0">One customer email can have multiple membership types.</p>
                        </div>
                        <div>
                            <CDropdown>
                                <CDropdownToggle color="light">
                                    <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Membership Entries
                                </CDropdownToggle>
                                <CDropdownMenu>
                                    <CDropdownItem role="button" onClick={onAddEntryButtonClick}>
                                        <CIcon icon={cilKeyboard} className="primary-color"/> &nbsp; Manual Input
                                    </CDropdownItem>
                                    <CDropdownItem role="button" onClick={() => setUploadCsvModalVisible(true)}>
                                        <CIcon icon={cilCloudUpload} className="primary-color"/> &nbsp; Upload CSV File
                                    </CDropdownItem>
                                </CDropdownMenu>
                            </CDropdown>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={membershipFilters}
                            setFilters={setMembershipFilters}
                            filterCount={membershipFilterCount}
                            updateFilters={updateMembershipFilters}
                            filterCategories={membershipFilterCategories}
                            setFilterModalVisible={setMembershipFilterModalVisible}
                        />
                    </div>
                    <DataTable
                        columns={membershipColumns(onEditEntryButtonClick, onDeleteEntryButtonClick)}
                        data={displayedMemberships}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <EditMembershipTypeModal
                visible={editTypeModalVisible}
                onClose={() => setEditTypeModalVisible(false)}
                editFormData={editTypeFormData}
                setEditFormData={setEditTypeFormData}
                dispatch={dispatch}
            />
            <MembershipFilterModal
                visible={membershipFilterModalVisible}
                onClose={() => setMembershipFilterModalVisible(false)}
                updateFilters={updateMembershipFilters}
                updateFiltersMultiselect={updateMembershipFiltersMultiselect}
                filters={membershipFilters}
                membershipTypes={allMembershipTypes}
            />
            <EditMembershipEntryModal
                visible={editEntryModalVisible}
                onClose={() => setEditEntryModalVisible(false)}
                editFormData={editEntryFormData}
                setEditFormData={setEditEntryFormData}
                membershipTypes={allMembershipTypes}
                dispatch={dispatch}
            />
            <UploadCsvModal
                visible={uploadCsvModalVisible}
                onClose={() => setUploadCsvModalVisible(false)}
                membershipTypes={allMembershipTypes}
                dispatch={dispatch}
            />
        </div>
    )
}

export default Members;
