import { useEffect, useState} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import { bookVenue, setAlert } from "../../redux/action/action";
import { useNavigate ,useSearchParams } from "react-router-dom";

function PPRouting() {
    const stripe = useStripe();
    //const [message, setMessage] = useState(null);
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const id = useState(localStorage.getItem('venueId'));
    const bookingSuccess = useSelector(state => state.Apis.bookingSuccess);
    // const [] = useState(null);

    useEffect(() => {
        if (bookingSuccess) {
            localStorage.removeItem("checkoutData");
            localStorage.removeItem("bookingData");
            navigate('/booking');
        } else if (bookingSuccess === false) {
            navigate(`/checkout/${id}`);
        }
    }, [bookingSuccess]);

    useEffect(() => {
        console.log("reached")
        console.log(searchParams.get("payment_intent_client_secret"))

        const route = async () => {
            const clientSecret = searchParams.get("payment_intent_client_secret")
            const paymentIntent = searchParams.get("payment_intent")
            const reservation_data = JSON.parse(localStorage.getItem("checkoutData"))
            reservation_data.payment_intent_id = paymentIntent

            console.log(reservation_data)
              
            stripe
            .retrievePaymentIntent(clientSecret)
            .then(({paymentIntent}) => {
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (paymentIntent.status) {
                case 'requires_capture':
                    //setMessage('Success! Payment received.');
                    reservation_data.status = 2;
                    console.log(paymentIntent.status)
                    dispatch(bookVenue(reservation_data))
                    break;
                case 'succeeded':
                    //setMessage('Success! Payment received.');
                    reservation_data.status = 2;
                    console.log(paymentIntent.status)
                    dispatch(bookVenue(reservation_data))
                    break;
        
                case 'processing':
                    //setMessage("Payment processing. We'll update you when payment is received.");
                    reservation_data.status = 2;
                    console.log(paymentIntent.status)
                    dispatch(bookVenue(reservation_data))
                    break;
        
                case 'requires_payment_method':
                    // Redirect your user back to your payment page to attempt collecting
                    // payment again
                    //setMessage('Payment failed. Please try another payment method.');
                    console.log('processing')
                    navigate(`/checkout/${id}`);
                    break;
        
                default:
                    //setMessage('Something went wrong.');
                    console.log(paymentIntent.status)
                    navigate(`/checkout/${id}`);
                    //navigate('/booking');
                    break;
                }
            })
        }

        if (!stripe) {
            return;
        }
        if(localStorage.getItem("checkoutData")){
            route();
        }
        // else {
        //     navigate('/');
        // }
    }, [stripe]);   
}

export default PPRouting





