import React, { useEffect, useState } from "react";
import axios from "axios"
import { DEFAULT_URL } from "../../redux/action/action";

const Privacy_policy = () => {
  document.title = "Privacy Policy | DoubleSpot";
  document.querySelector('meta[name="description"]').setAttribute(
      "content",
      "Review DoubleSpot's privacy policies for transparent data handling and protection measures."
  );

  const [data , setData]= useState('');

  useEffect(() => {
    getPage()
  }, [data])
  

  async function getPage() {
    try {
      const response = await axios.get(`${DEFAULT_URL}/getPage/privacy_policy`);
      setData(response.data.body.content);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <section class="inner_banner">
        <div class="container">
          <h2>Privacy Policy</h2>
          <p>
            {" "}
            <a>Legal</a> / <a>Privacy Policy</a>{" "}
          </p>
        </div>
      </section>
      <section class="pera py-5 mh85 px-5">
        <div class="container">
          <p><i>Last Updated: Aug 16, 2023</i></p>
          <p>&nbsp;</p>
          <h3><strong>Overview</strong></h3>
          <p>DoubleSpot Inc., a Delaware incorporated company, and our affiliates (collectively, “DoubleSpot,” “we,” “us”), are providing this Privacy Policy (“Privacy Policy”) with respect to all of our Services, all of which are hosted in the United States. DoubleSpot is a venue discovery and management platform that connects venue owners and event organizers. This Privacy Policy describes our collection, use and disclosure of information that we receive when you use our platform. All terms used in this Privacy Policy that are not defined herein have the meanings set forth in our Terms of Use.</p>
          <p>&nbsp;</p>
          <h3><strong>Privacy Policy Updates</strong></h3>
          <p>DoubleSpot reserves the right to amend this Privacy Policy at any time in order to address future developments of our platform or changes in industry regulations. When we amend this Privacy Policy, we will revise the "last updated" date at the top. Your continued use of DoubleSpot after any such amendments become effective shall constitute your acceptance of, and agreement to be bound by, those amendments.</p>
          <p>&nbsp;</p>
          <h3><strong>Information We Collect</strong></h3>
          <h4>Personal Information</h4>
          <p>When you use our Services, we may collect personal information, which is any information that identifies or can be used to identify you. This may include:</p>
          <p>- Name</p>
          <p>- Email address</p>
          <p>- Phone number</p>
          <p>- Payment information</p>
          <p>- Event details</p>
          <p>- Communications between venue operators and event organizers</p>
          <p>- Any other information you choose to provide</p>
          <h4>Usage Information</h4>
          <p>We collect information about your use of our Services, such as:</p>
          <p>- Device information, including device type, operating system, and browser</p>
          <p>- IP address</p>
          <p>- Usage data, such as pages viewed, time spent on our platform, and other interactions</p>
          <p>- Location data</p>
          <h4>Cookies and Similar Technologies</h4>
          <p>We use cookies and similar technologies, such as web beacons and pixels, to collect information about your use of our Services.</p>
          <h4>Third-Party Data</h4>
          <p>We may receive information about you from third-party sources, such as payment processors and marketing partners.</p>
          <p>&nbsp;</p>
          <h3><strong>Use of Information</strong></h3>
          <p>We use the information we collect from and about you for the following purposes:</p>
          <p>- Provide our Services, such as facilitating connections between venues and event organizers.</p>
          <p>- Respond to your requests, such as processing bookings, payments and inquiries.</p>
          <p>- improve our Services by performing analytics, conducting research, and debugging to identify and fix errors.</p>
          <p>- Communicate with you by sending emails, push notifications, and other messages about your account or activity on DoubleSpot.</p>
          <p>- Market our Services by sending promotional communications, subject to your choices.</p>
          <p>- Prevent potentially illegal activities, and enforce our Terms of Use.</p>
          <p>- Comply with legal obligations such as responding to subpoenas or legal process.</p>
          <p>We may also aggregate and/or anonymize personal information we collect so that it will no longer identify any individual user. We may use aggregate or anonymized information for various business purposes, where permitted by applicable laws.</p>
          <p>&nbsp;</p>
          <h3><strong>Disclosure of Information</strong></h3>
          <p>We disclose personal information in the ways discussed below.</p>
          <h4>Service Providers</h4>
          <p>We may share personal information with third-party service providers who complete transactions or perform services on our behalf, such as payment processing, data storage, and analytics.</p>
          <h4>Affiliates</h4>
          <p>We may share personal information within our corporate family of companies. This includes our subsidiaries and joint ventures we control.</p>
          <h4>Business Transfers</h4>
          <p>In the event of a merger, asset sale or other corporate transaction where third-party organizations become successors-in-interest to our business, personal information would be part of the transferred assets. We will provide notice before transferring any personal information.</p>
          <h4>Legal Disclosures</h4>
          <p>We may disclose personal information if required to do so by law or in the good-faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement agencies, third-party rights owners, or others in the good faith belief that such disclosure is reasonably necessary.</p>
          <h4>With Your Consent</h4>
          <p>We may share personal information for any other purposes disclosed to you with your consent.</p>
          <p>&nbsp;</p>
          <h3><strong>Your California Privacy Rights</strong></h3>
          <p>If you are a California resident, California law may provide you with additional rights regarding our use of your personal information.</p>
          <p>California Civil Code Section 1798.83 permits our users who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact us at support@doublespot.com.</p>
          <p>Under California Civil Code Section 1798.83, California residents with an established business relationship with us can opt-out of our disclosing personal information to third parties for the third parties' direct marketing purposes by contacting us via email or U.S. mail using the contact information provided at the end of this Privacy Policy.</p>
          <p>&nbsp;</p>
          <h3><strong>General Matters</strong></h3>
          <h4>Children's Privacy</h4>
          <p>Our Services are not directed at individuals under 18. We do not knowingly collect personal information from children under 18. If you become aware that a child under 18 has provided us with personal information, please contact us.</p>
          <h4>Changing Information; Closing Account</h4>
          <p>You may update or delete your account information at any time by accessing your account settings online. You may also close your account by contacting us as indicated in the “Contact Us” section.</p>
          <h4>Security</h4>
          <p>We implement reasonable administrative, technical and physical security measures to help protect your personal information from unauthorized access, use or disclosure. However, no security measure is 100% effective, and we cannot guarantee that your personal information will never be disclosed in a manner inconsistent with this Privacy Policy (for example, as the result of unauthorized acts by third parties that violate applicable law or DoubleSpot’s policies).</p>
          <h4>Contact Us</h4>
          <p>If you have any questions or suggestions regarding our Privacy Policy, you may email us at support@doublespot.com</p>
        </div>
      </section>
    </>
  );
};

export default Privacy_policy;
