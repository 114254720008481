import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPwd } from "../../redux/action/action";
import { Modal } from 'reactstrap';
import { closeModal } from "../../redux/action/accountModals";


function ForgotPassword() {

    const dispatch = useDispatch()

    const [formData, setFormData] = useState({})
    const onChangeData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

    const openForgot = useSelector(state => state.HandleModals.openForgotModal);

    useEffect(() => {
        setFormData({ ...formData, ["role"]: 1})
    }, [])


    const submitPwdRecover = (e) => {
        e.preventDefault()
        dispatch(forgotPwd(formData))
        dispatch(closeModal())
    }

    return <>
        <Modal className="singInModal s_modal" isOpen={openForgot} toggle={() => {dispatch(closeModal())}}>
            <div className="modal-header border-0">
                <button type="button" className="btn-close" onClick={() => {dispatch(closeModal())}} data-bs-dismiss="modal" aria-label="Close"><i
                    className="fa-solid fa-xmark"></i></button>
            </div>
            <div className="modal-body px-lg-4 px-md-3 ">
                <div className="row">
                    <div className="col-lg-6 col-md-5 d-lg-block d-none">
                        <div className="modal_side_img w-100 h-100">
                            <img src="../../../assets/images/login_img.png" alt="DoubleSpot.com" />
                        </div>
                    </div>


                    <div className="col-lg-6 d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2>Password Recovery</h2>
                            
                            <form onSubmit={(e) => submitPwdRecover(e)}>
                                <div className="form-group">
                                    <label for="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            onChange={onChangeData}
                                            required="true"
                                            name="email"
                                            placeholder="john@gmail.com"
                                        />
                                    
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="submit_modal pr_submit">Submit</button>
                                </div>
                            </form>
                            <h6>We will send a link to your email to reset your password if an account exists under your email.</h6>
                        </div>
                    </div>
                    
                </div>
            </div>
        </Modal>
        
    </>
}

export default ForgotPassword
