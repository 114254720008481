import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CInputGroup,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {addEmailRestrictionEntry} from "../../../../redux/action/action";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {email_restrictions} from "constants";

const AddModal = ({ visible, onClose, venues, editCustomerAccess, type }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});

    const updateForm = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const updateFormMultiselect = (selectedOptions, actionMeta) => {
        setFormData({...formData, [actionMeta.name]: selectedOptions});
    };

    const handleAddEmailRestrictionEntry = (event) => {
        event.preventDefault();
        dispatch(addEmailRestrictionEntry({
            venueIds: formData ? formData.venues.map(venue => venue.value) : [],
            email: formData.email,
            editCustomerAccess: editCustomerAccess, 
            type: type
        }));
    };

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Add {type === email_restrictions.TYPE_EMAIL_WHITELIST ? "Whitelist" : "Domain"} Entry</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body" style={{ minHeight: "300px" }}>
                <CForm onSubmit={handleAddEmailRestrictionEntry}>
                    <div className="pb-3">
                        <CFormLabel htmlFor="venueId">Venue</CFormLabel>
                        <MultiSelectCheckbox
                            name="venues"
                            onChange={updateFormMultiselect}
                            options={venues.map(listing => ({
                                label: listing.name,
                                value: listing.id,
                            }))}
                            value={formData.venues ? formData.venues : []}
                        />
                    </div>
                        {type === email_restrictions.TYPE_EMAIL_WHITELIST ?
                            <div className="pb-3">
                                <CFormLabel htmlFor="customer_email">Customer Email</CFormLabel>
                                <CFormInput type="email" name="email" onChange={updateForm}/>
                            </div>
                            : <div className="pb-3">
                                <CFormLabel htmlFor="email_domain">Organization Email Domain</CFormLabel>
                                <CInputGroup className="mb-3">
                                    <CInputGroupText id="basic-addon1">@</CInputGroupText>
                                    <CFormInput name="email" placeholder="company.com" onChange={updateForm}/>
                                </CInputGroup>
                            </div>
                        }
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default AddModal;
