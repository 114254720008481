import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";

const FilterModal = ({ displayLeads, visible, onClose, updateFilters, filters }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                {
                    displayLeads ? <>
                        <div class="pb-3">
                            <CFormLabel htmlFor="name">Name</CFormLabel>
                            <CFormInput type="text" name="name" value={filters.name}
                                        onChange={updateFilters}/>
                        </div>
                        <div class="pb-3">
                            <CFormLabel htmlFor="email">Email</CFormLabel>
                            <CFormInput type="text" name="email" value={filters.email}
                                        onChange={updateFilters}/>
                        </div>
                        <div class="pb-3">
                            <CFormLabel htmlFor="phone">Phone</CFormLabel>
                            <CFormInput type="text" name="phone" value={filters.phone}
                                        onChange={updateFilters}/>
                        </div>
                    </> : null
                }
                <div class="flex-row pb-3">
                    <div class="col-6 pe-2">
                        <CFormLabel htmlFor="start_date">Earliest Date</CFormLabel>
                        <CFormInput type="date" name="start_date" value={filters.start_date}
                                    onChange={updateFilters}/>
                    </div>
                    <div class="col-6 ps-2">
                        <CFormLabel htmlFor="end_date">Latest Date</CFormLabel>
                        <CFormInput type="date" name="end_date" value={filters.end_date}
                                    onChange={updateFilters}/>
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel>Number of Messages</CFormLabel>
                    <div class="flex-row align-items-center">
                        <CFormInput type="number" name="min_length" min="0" value={filters.min_length}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                        &nbsp;to&nbsp;
                        <CFormInput type="number" name="max_length" min="0" value={filters.max_length}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                    </div>
                </div>
            </CModalBody>
        </CModal>
    )
};

export default FilterModal;
