import {CButton} from "@coreui/react";

export const columns = (setAddSessionModalVisible) => [
    {
        name: 'Title',
        selector: row => row.title,
        cell: row => <a className="flex-row align-items-center external-link" href={`/business/programs/program/1`} target="_blank">{row.title}</a>,
        width: "350px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Description',
        selector: row => row.description || "N/A",
        cell: row => <span class="py-2 display-line-break">{row.description || "N/A"}</span>,
        width: "300px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div className="flex-row">
                <div class="py-2 pe-3">
                    <CButton color="dark" variant="outline" onClick={setAddSessionModalVisible}>Add Session</CButton>
                </div>
                <div class="py-2">
                    <CButton color="dark" variant="outline" href={`/business/programs/create-program/1`}>Edit</CButton>
                </div>
            </div>
            
        ),
        minWidth: "200px",
        right: true,
    }
];

export const filterCategories = [
    {
        name: 'Program type',
        category: 'event_types',
        type: 'multiple',
    },
];

export const applyFilters = (programs, filters) => {
    return programs.filter(program => {
        const lowerCustomerName = `${program.customer_first_name} ${program.customer_last_name}`.toLowerCase();

        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                program.title.toLowerCase().includes(searchTerm) ||
                program.description?.toLowerCase().includes(searchTerm) ||
                lowerCustomerName.includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.venues?.length) {
            if (!filters.venues.map(venue => venue.value).includes(program.venueId)) return false;
        }

        if (filters.start_date) {
            if (program.end_date < filters.start_date) return false;
        }

        if (filters.end_date) {
            if (program.date > filters.end_date) return false;
        }

        if (filters.start_time) {
            if (program.end_time < filters.start_time) return false;
        }

        if (filters.end_time) {
            if (program.time > filters.end_time) return false;
        }

        if (filters.min_availability) {
            if (program.availability < filters.min_availability) return false;
        }

        if (filters.max_availability) {
            if (program.availability > filters.max_attendees) return false;
        }

        if (filters.min_revenue) {
            if (program.booked_price < filters.min_revenue) return false;
        }

        if (filters.max_revenue) {
            if (program.booked_price > filters.max_revenue) return false;
        }

        if (filters.customer_name) {
            const searchTerm = filters.customer_name.toLowerCase();
            if (!lowerCustomerName.includes(searchTerm)) return false;
        }

        if (filters.customer_phone) {
            const searchTerm = filters.customer_phone.toLowerCase();
            if (!program.customer_number.includes(searchTerm)) return false;
        }

        if (filters.customer_email) {
            const searchTerm = filters.customer_email.toLowerCase();
            if (!program.customer_email.toLowerCase().includes(searchTerm)) return false;
        }

        return true;
    });
};
