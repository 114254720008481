import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setAlert } from "../../../redux/action/action";

function Alerts() {
    const dispatch = useDispatch()  
    const alert = useSelector(state => state.Alert.alert);
    const notify = () => { 
        var dataObj = {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        }
        alert?.alertType == "success" ? toast.success(alert?.msg, dataObj) : toast.error(alert?.msg,dataObj)
    }
    
    useEffect(() => {
        notify()
    },[alert])


    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

  
  export default Alerts