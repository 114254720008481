import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Logout from "../../Provider/Pageslayout/modals/Logout";


const Footer = () => {
    const location = useLocation()
    const [pathName, setPathName] = useState('')

    useEffect(() => {
        setPathName(location.pathname)
    })

    if (pathName === "search-page" || pathName === "profile"){
        return <></>
    }

    return (
        <>
            {
                pathName !== "/"
                    ?
                        <>
                            <div className="footer-small py-1">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 d-flex align-items-center justify-content-md-start justify-content-center">
                                            <p className="mb-0">
                                                © 2024 <a href="https://www.doublespot.ai/" target="_blank">DoubleSpot</a>. All Rights Reserved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Logout />

                        </>
                    :
                        <footer>
                            <div className="container">
                                <div className="row justify-content-lg-between justify-content-start pb-2 gy-2">
                                    <div className="col-md-5">
                                        <a className="footer_logo">
                                            <img src="../../../assets/images/logo.png" alt="Doublespot.com" />
                                        </a>
                                        <p className="my-3">
                                            Together, we make venue booking easy!
                                        </p>

                                        <p className=" mb-0">© 2024 <a href="https://www.doublespot.ai/" target="_blank">DoubleSpot</a>. All
                                            Rights Reserved.</p>
                                    </div>
                                    <div className="col-lg-7 col-md-7 justify-content-end align-itmes-end pt-0 mt-0">
                                        <div className="row gy-2 justify-content-end w-100 pt-2">
                                            <div className="col-xxl-2 col-md-4 col-6 pt-25">
                                                <h4 className="foot_heading">Support</h4>
                                                <ul className="list-unstyled">
                                                    <li><a href="https://forms.gle/Pm4TYWnSxt94VfP66" target="_blank">Contact Us</a></li>
                                                </ul>
                                            </div>

                                            <div className="col-xxl-2 col-md-4 col-6 pt-25">
                                                <h4 className="foot_heading">Legal</h4>
                                                <ul className="list-unstyled">
                                                    <li><a href="/terms" target="_blank">Terms of Use</a></li>
                                                    <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
                                                </ul>
                                            </div>
                                            {/* <div className="col-xxl-2 col-md-3 col-6">
                                                <h4 className="foot_heading">Support</h4>
                                                <ul className="list-unstyled">
                                                    <li><a href="/faq">FAQ</a></li>
                                                    <li><a href="/contact-us">Contact Us</a></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </footer>
            }
        </>
    )
}


export default Footer;