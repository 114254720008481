import { all } from 'redux-saga/effects';
import alertSaga from './alertsaga';
import Apisaga from './apisaga';
import login from './login';
import saga from './saga';


export default function* rootSaga() {
  yield all([
    alertSaga(),
    saga(),
    login(),
    Apisaga(),
  ]);
}