import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authResetPwd, resetPwd, setResetPwdFalse } from "../../redux/action/action";
import { useNavigate } from "react-router-dom";
import ErrorPage from "../../ErrorPage";
import Loading from "./Loading";
import ResetPwdSuccess from "../Modals/ResetPwdSuccess"

const ResetPassword = () => {
    document.title = "Reset Password | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Reset your password on DoubleSpot and regain access to your DoubleSpot account with ease."
    );

    const navigate = useNavigate();
    const dispatch = useDispatch() 
    
    const authResetData = useSelector(state => state.Apis.authResetData);
    const resetSuccess = useSelector(state => state.Apis.resetSuccess)
    const [formData, setFormData] = useState({})
    const [timeOut, setTimeOut] = useState(true)
    
    const onChangeData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

    const [openRSModal, setOpenRSModal] = useState(false)

    const submitResetPwd = (e) => {
        e.preventDefault()
        var pathArray = window.location.pathname.split('/')
        var id_ = pathArray[2]
        setFormData({ ...formData, ["id"]: id_})
        dispatch(resetPwd(formData))
    }

    function handleRSModal(modal) {
        setOpenRSModal(false)
        dispatch(setResetPwdFalse())
        navigate("/")
    }

    useEffect(() => {
        if (resetSuccess == true){
            setOpenRSModal(true)
        }
    }, [resetSuccess])

    useEffect(() => {
        var pathArray = window.location.pathname.split('/')
        var data = {
            id: pathArray[2],
            link: pathArray[3]
        }
        dispatch(authResetPwd(data))
        
    }, [])

    useEffect(() => {
        console.log(authResetData)

        var timeObj = new Date(authResetData.time)
        var newTimeObj = new Date(timeObj.getTime() + 60*60000);
        var timeout = newTimeObj < new Date()
        setTimeOut(timeout)        
        
    }, [authResetData])

    if (authResetData.auth == undefined){
        return (
            <><Loading /></>
        )
    } else if (timeOut == true || authResetData.auth == false){
        return (
            <><ErrorPage /></>
        )
    }

   
    return <>
        <section className="login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2 className="animate__backOutRight">Password Recovery</h2>
                            <form onSubmit={(e) => submitResetPwd(e)}>
                                <div className="form-group">
                                    <label for="password">New Password</label>
                                    <input 
                                        type="password"
                                        name="password"
                                        required="true"
                                        onChange={onChangeData}
                                        className="form-control" 
                                        id="password" 
                                        placeholder="password"
                                    />
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="submit_modal pr_submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ResetPwdSuccess onData={handleRSModal} openModalStatus={openRSModal}/>
        </section>
    </>
}

export default ResetPassword