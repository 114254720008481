import {CButton} from "@coreui/react";
import {email_restrictions} from "constants";

export const columns = (onDeleteButtonClick, type) => [
    {
        name: 'Venue',
        selector: row => row.venue_name,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: type === email_restrictions.TYPE_DOMAIN_RESTRICTION ? 'Domain' : 'Customer Email',
        selector: row => row.email || "N/A",
        width: "250px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        minWidth: "125px",
        right: true,
    }
];

export const filterCategories = [
    {
        name: 'Venues',
        category: 'venues',
        type: 'multiple',
    },
    {
        name: 'Customer Email',
        category: 'email',
        type: 'exact',
    },
];

export const applyFilters = (whitelist, filters) => {
    return whitelist.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                entry.email.toLowerCase().includes(searchTerm) ||
                entry.venue_name.toLowerCase().includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.venues?.length) {
            if (!filters.venues.map(venue => venue.value).includes(entry.venueId)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email.toLowerCase();
            if (!entry.email.toLowerCase().includes(searchTerm)) return false;
        }

        return true;
    });
};
