import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import TimePicker from "../../../components/TimePicker";
import {weekDays} from "../../../../Utils/DateTimeUtils";

const FilterModal = ({ visible, onClose, updateFilters, updateFiltersMultiselect, filters, venues }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <div class="flex-row pb-3">
                    <div class="col-6 pe-2">
                        <CFormLabel htmlFor="start_date">Start Date</CFormLabel>
                        <CFormInput type="date" name="start_date" value={filters.start_date}
                                    onChange={updateFilters}/>
                    </div>
                    <div class="col-6 ps-2">
                        <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                        <CFormInput type="date" name="end_date" value={filters.end_date}
                                    onChange={updateFilters}/>
                    </div>
                </div>
                <div class="flex-row pb-3">
                    <div class="col-6 pe-2">
                        <CFormLabel htmlFor="start_time">Start Time</CFormLabel>
                        <TimePicker name="start_time" 
                            value={filters.start_time}
                            onChange={updateFilters}/>
                    </div>
                    <div class="col-6 ps-2">
                        <CFormLabel htmlFor="start_time">End Time</CFormLabel>
                        <TimePicker name="end_time" 
                            value={filters.end_time}
                            onChange={updateFilters}/>
                    </div>
                </div>
                <div className="align-items-center pb-3">
                    <CFormLabel>Days</CFormLabel>
                    <div>
                        {
                            weekDays.map((weekDay) =>
                                <div className="day-of-the-week-circle-container">
                                    <div
                                        className={"d-flex day-of-the-week-circle inactive"}>
                                        {weekDay[0]}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="customer_name">Instructor</CFormLabel>
                    <CFormInput type="text" name="instructor" value={filters.instructor}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="venues">Venue</CFormLabel>
                    <MultiSelectCheckbox
                        name="venues"
                        onChange={updateFiltersMultiselect}
                        options={venues.map(listing => ({
                            label: listing.name,
                            value: listing.id,
                        }))}
                        value={filters.venues ? filters.venues : []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel>Availability</CFormLabel>
                    <div class="flex-row align-items-center">
                        <CFormInput type="number" name="min_availability" min="0" value={filters.min_availability}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                        &nbsp;to&nbsp;
                        <CFormInput type="number" name="max_availability" min="0" value={filters.max_availability}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel>Price</CFormLabel>
                    <div class="flex-row align-items-center">
                        <CFormInput type="number" name="min_price" min="0" value={filters.min_price}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                        &nbsp;to&nbsp;
                        <CFormInput type="number" name="max_price" min="0" value={filters.max_price}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                    </div>
                </div>
            </CModalBody>
        </CModal>
    )
};

export default FilterModal;
