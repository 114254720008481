import {CCard, CCardBody} from "@coreui/react";
import {applyFilters, columns, filterCategories} from "./FilterUtils";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import FilterModal from "./FilterModal";
import Filters from "../../../components/Filters";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../../../redux/action/action";
import {users} from "constants";

const Conversations = () => {
    document.title = "DoubleSpot AI Conversations | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Manage conversations of your DoubleSpot AI chatbot with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);

    const [chatbotId, setChatbotId] = useState('');
    const [displayLeads, setDisplayLeads] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [conversations, setConversations] = useState([]);
    const [displayedConversations, setDisplayedConversations] = useState([]);

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const fetchConversations = async () => {
        setLoading(true);
        // Using maximum size query is a temporary solution because Chatbase's APIs do not show total entries.
        // Once they do, querying for a page with the given number of rows at once makes more sense.
        const paramConfig = {
            chatbotId,
            size: 2147483647,
            // startDate: filters.start_date || '',
            // endDate: filters.end_date || '',
        };
        const params = new URLSearchParams(paramConfig);
        const response = await axios.get(
            `https://www.chatbase.co/api/v1/get-conversations?${params.toString()}`,
            {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_CHATBASE_API_KEY}`
                }
            }
        );
        setConversations(response.data.data);
        setLoading(false);
    };

    const onViewButtonClick = (conversation) => {
        localStorage.setItem("conversation", JSON.stringify({
            ...conversation,
            form_submission: displayLeads ? conversation.form_submission : null,
        }));
        navigate("/business/ai/conversation");
    };

    useEffect(() => {
        if (chatbotId) {
            fetchConversations();
        }
    }, [chatbotId]);

    useEffect(() => {
        if (userDetail.role) {
            const businessPlan = userDetail.role === users.ROLE_VENUE_OWNER ? userDetail.business_plan : userDetail.manager?.business?.business_plan;
            if (!businessPlan?.chatbotId) {
                dispatch(setAlert('Your DoubleSpot Business plan does not include AI chatbot.', 'error'));
                navigate("/business");
            } else if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.aiAccess) {
                dispatch(setAlert('You do not have access to DoubleSpot AI.', 'error'));
                navigate("/business");
            } else {
                setChatbotId(businessPlan.chatbotId);
                setDisplayLeads(businessPlan?.chatbot_leads);
            }
        }
    }, [userDetail]);

    useEffect(() => {
        setDisplayedConversations(applyFilters(conversations, filters));
    }, [conversations, filters]);

    useEffect(() => {
        let count = 0;
        filterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (filters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (filters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (filters[minCategory] || filters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setFilterCount(count);
    }, [filters]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>DoubleSpot AI Conversations</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            filterCount={filterCount}
                            updateFilters={updateFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setFilterModalVisible}
                        />
                    </div>
                    <DataTable
                        columns={columns(displayLeads, onViewButtonClick)}
                        data={displayedConversations}
                        progressPending={loading}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <FilterModal
                displayLeads={displayLeads}
                visible={filterModalVisible}
                onClose={() => setFilterModalVisible(false)}
                updateFilters={updateFilters}
                filters={filters}
            />
        </div>
    )
}

export default Conversations;
