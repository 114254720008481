import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";

const FilterModal = ({ visible, onClose, updateFilters, updateFiltersMultiselect, filters, venues }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <div class="pb-3">
                    <CFormLabel htmlFor="name">Name</CFormLabel>
                    <CFormInput type="text" name="name" value={filters.name}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="phone">Phone</CFormLabel>
                    <CFormInput type="text" name="phone" value={filters.phone}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="email">Email</CFormLabel>
                    <CFormInput type="text" name="email" value={filters.email}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="venues">Venue</CFormLabel>
                    <MultiSelectCheckbox
                        name="venues"
                        onChange={updateFiltersMultiselect}
                        options={venues.map(listing => ({
                            label: listing.name,
                            value: listing.id,
                        }))}
                        value={filters.venues ? filters.venues : []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="editVenueAccess">Edit Venues</CFormLabel>
                    <MultiSelectCheckbox
                        name="editVenueAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.editVenueAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="editReservationReportsAccess">Edit Event Reports</CFormLabel>
                    <MultiSelectCheckbox
                        name="editReservationReportsAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.editReservationReportsAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="editEventAccess">Edit Customer Restrictions</CFormLabel>
                    <MultiSelectCheckbox
                        name="editCustomerAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.editCustomerAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="editEventAccess">Edit Events</CFormLabel>
                    <MultiSelectCheckbox
                        name="editEventAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.editEventAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="acceptBookingAccess">Accept Bookings</CFormLabel>
                    <MultiSelectCheckbox
                        name="acceptBookingAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.acceptBookingAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="editBusinessAccess">Access Business Profile</CFormLabel>
                    <MultiSelectCheckbox
                        name="editBusinessAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.editBusinessAccess || []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="aiAccess">Access DoubleSpot AI</CFormLabel>
                    <MultiSelectCheckbox
                        name="aiAccess"
                        onChange={updateFiltersMultiselect}
                        options={['No', 'Yes'].map(
                            (label, index) => ({
                                label,
                                value: index
                            })
                        )}
                        value={filters.aiAccess || []}
                    />
                </div>
            </CModalBody>
        </CModal>
    )
};

export default FilterModal;
