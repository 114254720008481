import {CBadge, CButton} from "@coreui/react";
import {downloadColumnOptions} from "../../../../Utils/ReservationReportUtils"
import {recurrenceRuleToString} from "../../../../Utils/DateTimeUtils";
import {bookvenues} from "constants";

const statusBadges = {
    [bookvenues.STATUS_CONFIRMED]: <CBadge color="success">Confirmed</CBadge>,
    [bookvenues.STATUS_PENDING]: <CBadge color="danger">Pending</CBadge>,
    [bookvenues.STATUS_INTERNAL]: <CBadge textBgColor="light">Internal</CBadge>,
}

export const columns = (onEditButtonClick, onDeleteButtonClick) => [
    {
        name: 'Name',
        selector: row => row.name,
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Recurrence',
        selector: row => row.email_job?.job,
        format: row => recurrenceRuleToString(row.email_job?.job),
        width: "300px",
        reorder: true,
    },
    {
        name: 'Recipients',
        selector: row => row.email_job?.users,
        cell: row => {
            return <span class="py-2 display-line-break">{
                row.email_job?.users?.map(user => {
                    return user.name;
                }).join('\n')
            }</span>;
        },
        width: "200px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Selected Columns',
        selector: row => row.columns,
        format: row => row.columns?.split(',')?.map(column => {
            return downloadColumnOptions[parseInt(column)]?.label;
        }).join(', '),
        width: "300px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Sort By',
        selector: row => row.sort_by,
        format: row => row.sort_by === -1 ? "Default" : downloadColumnOptions[row.sort_by]?.label,
        width: "200px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Sort Direction',
        selector: row => row.sort_desc,
        format: row => row.sort_desc ? "Descending" : "Ascending",
        width: "150px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Statuses',
        selector: row => row.statuses,
        cell: row => (
            <div className="flex-row flex-wrap row-gap-1 py-3">
                {
                    row.statuses.length ?
                        row.statuses?.split(',')?.map(status =>
                            <div className="px-1">{statusBadges[parseInt(status)]}</div>
                        ) : "All"
                }
            </div>
        ),
        width: "200px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Venues',
        selector: row => row.venues,
        cell: row => {
            return <span class="py-2 display-line-break">{
                row.venues?.length ?
                    row.venues?.map(venue => {
                        return venue.name;
                    }).join('\n') : "All"
            }</span>;
        },
        width: "200px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Event Types',
        selector: row => row.eventtypes,
        cell: row => (
            <div className="flex-row flex-wrap row-gap-1 py-3">
                {
                    row.eventtypes?.length ?
                        row.eventtypes?.map(
                            eventtype => (
                                <div className="px-1">
                                    <CBadge class={`badge calendar-event-color-${eventtype.color}`}>
                                        {eventtype.name}
                                    </CBadge>
                                </div>
                            )
                        ) : "All"
                }
            </div>
        ),
        width: "300px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Tags',
        selector: row => row.tags,
        cell: row => (
            <div className="flex-row flex-wrap row-gap-1 py-3">
                {
                    row.tags?.length ?
                        row.tags?.map(
                            tag => (
                                <div className="px-1">
                                    <CBadge className="black" color="light">{tag.name}</CBadge>
                                </div>
                            )
                        ) : "All"
                }
            </div>
        ),
        width: "300px",
        wrap: true,
        reorder: true,
    },
    {
        name: 'Tags Match',
        selector: row => row.match_any_tags,
        format: row => row.match_any_tags ? "Any" : "All",
        width: "115px",
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onEditButtonClick(row)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    },
];

export const applyFilters = (reservationReports, filters) => {
    return reservationReports.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(entry.name.toLowerCase().includes(searchTerm))) {
                return false;
            }
        }

        return true;
    });
};
