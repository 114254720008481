import React, {useEffect} from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    rectSortingStrategy,
    SortableContext,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';

import {SortableItem} from './SortableItem';
import UploadZone from "./UploadZone";

// This class is derived from discussion in https://github.com/clauderic/dnd-kit/issues/381
class LayeredPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown',
            handler: (event) => {
                return event.target.className === 'sortable';
            },
        },
    ];
}

export function SortableItems({ dispatch, images, setImages, imageOrder, setImageOrder }) {
    const sensors = useSensors(
        useSensor(LayeredPointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const removeItem = (id) => {
        const newImages = [...images];
        newImages.splice(id - 1, 1);
        setImages(newImages);

        const newImageOrder = [...imageOrder];
        const index = imageOrder.indexOf(id);
        newImageOrder.splice(index, 1);
        for (let i = 0; i < newImageOrder.length; ++i) {
            if (newImageOrder[i] > id) newImageOrder[i] -= 1;
        }
        setImageOrder(newImageOrder);
    }

    useEffect(() => {
        if (imageOrder.length === 0) {
            setImageOrder(images.map((_, index) => index + 1));
        }
    }, [images]);

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={imageOrder}
                strategy={rectSortingStrategy}
            >
                <div className="flex-row flex-wrap">
                    {imageOrder.map(id => (
                        <SortableItem
                            key={id}
                            id={id}
                            src={images[id - 1]?.src}
                            removeItem={() => removeItem(id)}
                            />
                    ))}
                    <div className="p-2">
                        <UploadZone
                            dispatch={dispatch}
                            images={images}
                            setImages={setImages}
                            imageOrder={imageOrder}
                            setImageOrder={setImageOrder}
                            />
                    </div>
                </div>
            </SortableContext>
        </DndContext>
    );

    function handleDragEnd(event) {
        const {active, over} = event;

        if (active.id !== over.id) {
            setImageOrder((items) => {
                const oldIndex = items.indexOf(active.id);
                const newIndex = items.indexOf(over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
        }
    }
}