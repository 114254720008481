import { Elements } from "@stripe/react-stripe-js";
import PPRouting from "./PostPurchaseRouting";
import { loadStripe } from "@stripe/stripe-js";
import {TEST_STRIPE} from "endpoint";

let stripePromise;
if (TEST_STRIPE) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE);
}

function PPRPage() {
    return (
        <Elements stripe={stripePromise}>
            <PPRouting />
        </Elements>
    )
}
export default PPRPage