import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";

const FilterModal = ({ visible, onClose, updateFilters, updateFiltersMultiselect, filters, venues }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body" style={{ minHeight: "300px" }}>
                <div class="pb-3">
                    <CFormLabel htmlFor="venues">Venue</CFormLabel>
                    <MultiSelectCheckbox
                        name="venues"
                        onChange={updateFiltersMultiselect}
                        options={venues.map(listing => ({
                            label: listing.name,
                            value: listing.id,
                        }))}
                        value={filters.venues ? filters.venues : []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="email">Customer Email</CFormLabel>
                    <CFormInput type="text" name="email" value={filters.email}
                                onChange={updateFilters}/>
                </div>
            </CModalBody>
        </CModal>
    )
};

export default FilterModal;
