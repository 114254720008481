import ReactSelect from "react-select";
import React from "react";
import {formatDate} from "./DateTimeUtils";
import {tasks} from "constants";

const statusValues = [
    tasks.STATUS_TO_DO,
    tasks.STATUS_IN_PROGRESS,
    tasks.STATUS_COMPLETED,
];
const statusNames = [
    "\u{23F0} To Do",
    "\u{1F6A7} In Progress",
    "\u{2705} Completed",
];
const statusOptions = statusValues.map((statusValue, index) => ({
    label: statusNames[index],
    value: statusValue,
}));

/**
 *
 * @param taskList {{status: number}[]} The tasks to determine the completion percentage on.
 * @returns {number|null} The percentage of tasks that are completed. null denotes that there are no tasks.
 */
export const getTaskCompletionPercentage = (taskList) => {
    if (!taskList.length) return null;
    return Math.round(100 * taskList.filter(task => task.status === tasks.STATUS_COMPLETED).length / taskList.length);
}

export const columns = (onTaskStatusChange) => [
    {
        name: "Name",
        selector: row => row.name,
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Time Due",
        selector: row => `${row.due_date}${row.due_time}`,
        format: row => formatDate(row.due_date, row.due_time) || "N/A",
        width: "300px",
        sortable: true,
    },
    {
        name: "Status",
        selector: row => row.status,
        cell: row => <ReactSelect
            menuShouldScrollIntoView={false}
            value={{
                label: statusNames[statusValues.indexOf(row.status)],
                value: row.status,
            }}
            onChange={({value}) => onTaskStatusChange(row.id, value)}
            options={statusOptions}
            styles={{
                control: (provided) => ({
                    ...provided,
                    width: "150px",
                })
            }}
            menuPortalTarget={document.body}
        />,
        width: "200px",
        sortable: true,
    },
];
