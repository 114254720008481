import React, { useState, useEffect, useRef } from "react";
import ChatOnline from './ChatOnline'
import Conversations from './Conversations'
import io from "socket.io-client";
import debounce from 'lodash.debounce';
import ENV from '../../env.json';
import { useLocation, useNavigate } from "react-router-dom";


const Chat = () => {
    document.title = "Chats | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Connect directly with venues on DoubleSpot's chat platform. Discuss details of your next event with ease."
    );

    const { state } = useLocation();
    const [conversations, setConversations] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [arrivalMessage, setArrivalMessage] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [loader, setLoader] = useState(false);
    const [userId, setUserId] = useState("");
    const [userRole, setUserRole] = useState("");
    const [isTrue, setIsTrue] = useState(false);
    const [mszType, setMszType] = useState(0);
    const [imageType, setImageType] = useState("");
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

    const navigate = useNavigate();
    const [search, setSearch] = useState({
        keyword : ""
    });
   
    const socket = useRef();
    const SOCKET_SERVER_URL = ENV.SERVER_URL;
    useEffect(() => { 
        if (localStorage.getItem("token")) {
            let userId = localStorage.getItem("userId")
            userId = JSON.parse(userId)
            // Creates a WebSocket connection
            socket.current = io(SOCKET_SERVER_URL);
            let data = {
                "user_id": userId
            };
            socket.current.emit('connect_user', data);

            //listner connect user
            socket.current.on("connect_listener", function (data) {
                console.log("socket connect===============================")
                //getChat();
                if (currentChat && newMessage && arrivalMessage) {
                    getListing();
                }
            });
            // return () => {
            // if (socket.current) {
            //         socket.current.disconnect();
            //         console.log('disconnect successfully===============================');
            //     }
            // };
        }
    }, []);
   
   
    const getVenueInfo = () => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        setUserId(userId)
        let userRole = localStorage.getItem("userRole")
        userRole = JSON.parse(userRole)
        setUserRole(userRole)
    }

    useEffect(() => {
        if (newMessage != '') {
            setIsTrue(true)
        } else {
            setIsTrue(false)
        }
        getVenueInfo();
        setTimeout(() => {
            getSideBarChat();
        }, 1000)
    }, [userId, newMessage, currentChat])

    const getSideBarChat = () => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId);
        let data = {
            sender_id : userId,
            venue_id : currentChat && currentChat==null ? currentChat.venue_id : 0,
         }
        socket.current.emit('get_chat_list', data);
        socket.current.on("get_list", (chat) => {
            if(chat.length>0){
                setOnlineUsers(chat);
            }
        });
    };
    
    const handleSubmit = () => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        if (newMessage == '') {
            setIsTrue(true)
        } else {
            setIsTrue(false)
            let obj = {
                sender_id: userId,
                receiver_id: userId == currentChat.receiver_id ? currentChat.sender_id : currentChat.receiver_id,
                venue_id: currentChat ? currentChat.venue_id : 0,
                message: newMessage,
                msg_type: mszType ? mszType : 0,
                extension:imageType
            }
            socket.current.emit('send_message', obj);
            setNewMessage("");
            setMszType(0)
        }
    }; 
    // console.log(currentChat, "currentChat")

    useEffect(() => {
        // console.log(conversations, "conversations")
        // console.log(state, "conversations")
        if (conversations.length == 0) {
            if (state && state != undefined && state.val) {
                let obj = {
                    sender_id: state?.val?.booked_by,
                    receiver_id: state?.val?.bussness_id,
                    venue_id: state?.val?.id,
                    message: '',
                    msg_type: '0',
                }
                    console.log(obj, "obj")
                // return000
                // socket.current.emit('send_message', obj);
                // setNewMessage("");
            }
        }
        if (state && state != undefined && state.venue_detail) {
            navigate('', { state: {  } });
        }
    }, []);

    useEffect(() => {
            if (state && state != undefined && state.val && conversations.length === 0) {
                console.log("here========")
                console.log("--")
                // socket.current.emit('send_message', obj);
                // setNewMessage("");
        // Cleanup function
        return () => {
            // if (shouldNavigate) {
              navigate('/chat', {state:{}});
            // }
        };
    }
    }, []);

    useEffect(() => {
        socket.current.on("send_message_listener", (message) => {
            console.log("--her=================")
            if (message) {
                if (!currentChat && currentChat == null) {
                    onlineUsers.length>0 && onlineUsers.map((item) => {
                        if (message.venue_id == item && item.venue_id) {
                            getChatById(item);
                        }
                    })
                } else {
                    // console.log("here")
                    // console.log("here", currentChat)
                    if (message.venue_id == currentChat.venue_id) {
                        getChatById(message);
                        // read_unread(message)
                        
                    }
                }
                getListing();
            }
        });
    }, [currentChat, arrivalMessage, conversations]);
    
    const read_unread = (message) => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        let obj = {
            sender_id : message.sender_id,
            venue_id : message.venue_id,
        }
        socket.current.emit('read_unread', obj);
        socket.current.on("read_data_status", (chat) => {
            if (chat) {
                getListings(obj)
            }
        });
        getListing(message);
    };

    const getListings = (obj) => {
        socket.current.emit('get_chat_list',  {
            sender_id: obj.sender_id,
            venue_id:0
        });
        socket.current.on("get_list", (listing) => {
            setArrivalMessage(listing);
        });
    }
    
    
    const getChatById = (info) => {
        let venueId = currentChat == null ? info.venue_id : currentChat.venue_id!=info.venue_id ? info.venue_id
            : info.venue_id;
        let obj = {
            sender_id : currentChat==null ? state?.user?.sender_id : info.sender_id,
            receiver_id :currentChat==null ? state?.user?.receiver_id : info.receiver_id,
            venue_id : venueId
        }
        socket.current.emit('get_chat',obj);
        socket.current.on("my_chat", (chat) => {
            if (chat) {
                setConversations(chat?.get_message);
            }
        });
    };

    useEffect(() => {
        if(state && state?.venue_detail) {
            let value = state?.venue_detail;
            const getChatByIds = () => {
                let obj = {
                    sender_id : value.booked_by,
                    receiver_id : value.business_id,
                    venue_id : value.id
                }
                socket.current.emit('get_chat',obj);
                socket.current.on("my_chat", (chat) => {
                    if (chat) {
                        setConversations(chat?.get_message);
                    }
                });
            };
            getChatByIds()

        }
    }, [onlineUsers, currentChat])
    
    const getListing = () => {
        socket.current.emit('get_chat_list',  {
            sender_id: userId,
            venue_id:0
        });
        socket.current.on("get_list", (listing) => {
            setArrivalMessage(listing);
        });
    }
    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };

    const uploadimagebase64 = async (event) => {
        const file = event.target.files[0];
        if (file && allowedTypes.includes(file.type)) {
            let type = file.type.split('/');
            type = type[1]
            setImageType(type)
            if(type=='pdf'){
                setMszType(2)  
            }else{
                setMszType(1)
            }
            const base64 = await convertBase64(file);
            setNewMessage(base64)
          } else {
            alert('Please select a valid image file (JPEG, PNG, JPG, PDF).');
          }
    
    };

    //search record
    const handleChangeSearch = (e) => {
		const { name, value } = e.target;
		setSearch({
			...search,
			[name]: value
		});
		const debouncedSave = debounce(() => searchRecord(value), 1000);
		debouncedSave(value);
 	};
     const searchRecord  = async (value) => {
        try{
            const results = onlineUsers.filter((item) =>
                item.venu_name.toLowerCase().includes(value.toLowerCase())
            );
            setOnlineUsers(results);
            if(value==''){
                getListing();
            }
        }catch(err) {
            console.log(err);
        }
    };


    
    const deleteChat = () => {
      
    }

  return (
    <>
        <section className="py-2 message">
        <div className="container">
            <div className="row justify-content-center align-items-start h-100 g-0">
                <div className="col-md-4 col-xl-3 chat mt-0">
                          <div className="card mb-sm-3 mb-md-0 contacts_card rounded-3 rounded-end-0 cl_side">
                      {/*   {currentChat &&   */}
                        <div className="card-header ">
                        
                            <div className="search">
                                <span className="fa fa-search"></span>
                                <input 
                                    type="text"
                                    placeholder="Search term"
                                    value={search.keyword}
                                    onChange={handleChangeSearch}
                                    autoComplete="off"
                                    name="keyword"
                                />
                            </div>
                              </div>
                        {/* } */}
                              
                        <ChatOnline
                            onlineUsers={onlineUsers}
                            setOnlineUsers={setOnlineUsers}
                            currentChat={currentChat}
                            setCurrentChat={setCurrentChat}
                            arrivalMessage={arrivalMessage}
                            setArrivalMessage={setArrivalMessage}
                            userId={userId}
                            userRole={userRole}
                            socket={socket}
                            conversations={conversations}
                            urlId={userId}
                            setConversations={setConversations}
                            setLoader={setLoader}
                            newMessage={newMessage}
                            setNewMessage={setNewMessage}
                            state={state?.val}
                                
                        />
                    </div>
                </div>
                <div className="col-md-8 col-xl-9 chat">
                    <div className="card rounded-start-0 rounded-3 border-start-0">

                        <div className="row card-body p-0 pt-0 h-100">
                            <div className="col-md-12 h-100">
                            {currentChat ? ( 
                                <>
                                <Conversations 
                                    currentChat={currentChat}
                                    conversations={conversations}
                                    loginUser={userId ? userId : ''}
                                    deleteChat={deleteChat}
                                    loader={loader}
                                                
                                />
                                <div className="card_footer border-top py-2 px-2">
                                    <div className="input-group d-flex align-items-center justify-content-around">
                                        <div className="form-check p-0">
                                            <input className="form-check-input d-none" type="file" name="image" id="flexCheckDefault" onChange={(e) => uploadimagebase64(e)} />
                                            <label className="form-check-label" for="flexCheckDefault">
                                                <i className="fa-solid fa-paperclip"></i>
                                            </label>
                                        </div>

                                        {mszType==1 &&
                                            <>Photo</>
                                        }
                                        {mszType==2 &&
                                         <>PDF</>
                                        }
                                        {mszType==0 &&
                                            <textarea name="" className="form-control d-flex align-items-end type_msg"
                                            placeholder="Type a message here ..." onChange={(e) => setNewMessage(e.target.value)} value={newMessage}>
                                            </textarea>
                                        }
                                        {isTrue &&
                                            <div className="input-group-append" onClick={handleSubmit}>
                                                <span className="input-group-text send_btn">
                                                    <i className="fa-solid fa-paper-plane"></i>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                </>
                                ) : (
                                    <span className="noCoversation">Open a conversation to chat</span>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Chat

