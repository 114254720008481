import React, { useEffect, useState } from "react";
import ENV from '../../env.json'
import { useLocation, useNavigate } from "react-router-dom";
 import { io } from 'socket.io-client';
import { SITE_URL } from "../../redux/action/action";
 
export default function ChatOnline({ onlineUsers, setOnlineUsers, currentChat, setCurrentChat, setArrivalMessage, userId, socket, urlId, setConversations, setLoader }) {
    const [currentUser, setCurrentUser] = useState({});
    const navigate = useNavigate();
    const { state } = useLocation();
    const SOCKET_SERVER_URL = ENV.SERVER_URL;

    console.log(onlineUsers)

    useEffect(() => {
        socket.current = io(SOCKET_SERVER_URL);
        // setSocket(newSocket);
    }, []);

    const handleClick = (user) => {
        try {
            if (user) {
                navigate('', { state: { user } });
                setCurrentUser(user);
                setCurrentChat(user);
                setLoader(true);
                if(user){
                    getChatById(user);
                    getAllList(user);
                    // read_unread(user);
                }
            }
		}
		catch (err) {
			console.log(err);
		}
    };

    useEffect(() => {
        if (state && state.venue_detail && onlineUsers.length > 0 && currentChat==null) {
            const matchingUser = onlineUsers.find(item => {
                return (
                  state.venue_detail.id == item.venue_id &&
                  state.venue_detail.business_id == item.receiver_id
                );
              });
            if (matchingUser) {
                setCurrentChat(matchingUser);
            }
          const getChatByIds = () => {
            const obj = {
              sender_id: state.venue_detail.booked_by,
              receiver_id: state.venue_detail.business_id,
              venue_id: state.venue_detail.id
            };
            socket.current.emit('get_chat', obj);
            socket.current.on('my_chat', chat => {
              if (chat) {
                setConversations(chat?.get_message);
              }
            });
          };
          getChatByIds();
        }
        if (state && state.val && onlineUsers.length > 0 && currentChat==null) {
            const matchingUser = onlineUsers.find(item => {
                return (
                  state.val.id == item.venue_id &&
                  state.val.business_id == item.receiver_id
                );
              });
            if (matchingUser) {
                setCurrentChat(matchingUser);
            }
          const getChatByIds = () => {
            const obj = {
              sender_id: state.val.booked_by,
              receiver_id: state.val.business_id,
              venue_id: state.val.id
            };
            socket.current.emit('get_chat', obj);
            socket.current.on('my_chat', chat => {
              if (chat) {
                setConversations(chat?.get_message);
              }
            });
          };
          getChatByIds();
        }
      }, [onlineUsers, state?.venue_detail, state?.val]);
    
    
   
    
    const getChatById = (currentUserVal) => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        let obj = {
            sender_id : currentUserVal.sender_id,
            receiver_id : currentUserVal.receiver_id,
            venue_id : currentUserVal ? currentUserVal.venue_id : currentUserVal.venue_id,
        }
        socket.current.emit('get_chat', obj);
        socket.current.on("my_chat", (chat) => {
            if (chat) {
                setConversations(chat?.get_message);
            }
        });
    };
 
   
    const getAllList = (currentUserVal) => {
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        let data = {
            sender_id:  userId ,
            venue_id : currentUserVal ? currentUserVal.venue_id : 0,
        }
        socket.current.emit('get_chat_list',  data);
        socket.current.on("get_list", (listing) => {
            setArrivalMessage(listing);
        });
    };
   
    useEffect(() => {
        if (userId && currentUser) {
            const getListing = () => {
                socket.current.emit('get_chat_list',  {
                    sender_id: userId,
                    venue_id : 0,
                });
                socket.current.on("get_list", (listing) => {
                    setArrivalMessage(listing);
                });
            }
            getListing();
        }
        // if(currentChat){
        //     read_unread();
        // }
    }, [userId, currentUser,currentChat]);

    const read_unread = () => {
        // return
        let userId = localStorage.getItem("userId")
        userId = JSON.parse(userId)
        let obj = {
            sender_id : currentChat!=null ? currentChat.sender_id : userId,
            venue_id : currentChat!=null ? currentChat.venue_id : 0,
        }
        socket.current.emit('read_unread', obj);
        socket.current.on("read_data_status", (chat) => {
            if (chat) {
                socket.current.emit('get_chat_list',  {
                    sender_id: currentChat!=null ? currentChat.sender_id :userId,
                    venue_id : 0,
                });
                socket.current.on("get_list", (listing) => {
                    setOnlineUsers(listing)
                });
            }
        });
    };
    
  return (
    <>
        <div className="card-body contacts_body">
              <ui className="contacts">
                  {onlineUsers.length > 0 && onlineUsers.map((online, index) => {
                        let userImg = online.receiver_image;
                        let UserId = currentUser ? currentUser.id  : parseInt(urlId);
                        let onlineUser = online ? online.id : '';
                        let venueId = state?.val?.id ?  state?.val?.id :  state?.venue_detail?.id
                        let active = '';
                        if((UserId == onlineUser) || (venueId == online.venue_id)){
                            active = 'active-user';
                        }
                        return (
                          <>
                          <li className={active} key={index} onClick={() => handleClick(online)}>
                              <div className="d-flex bd-highlight gap-3">
                                    <div className="img_cont">
                                      <img src={userImg ? SITE_URL+'images/'+userImg : '../assets/images/chat_user_img01.png'} className="rounded-circle user_img" />
                                    
                                        <span className={online.online_status==1 ? 'online_icon' : 'offline'}></span>
                                  </div>
                                  <div className="user_info">
                                      <span>{online.venu_name} ({online.receiver.name})</span>

                                      {/* <span>{userRole==1 ? online.venu_name : online.receiver_name} {online.unread_count}</span> */}
                                      {/* <span>{userRole==1 ? online.venu_name : online.receiver_name} </span> */}
                                      <p>{online.msg_type==1 ? 'Photo' : online.msg_type==2 ? 'PDF' : online.last_message.substring(0,15)}{online.last_message.length > 15 && "..."}</p>
                                  </div>
                              </div>
                          </li>
                          </>
                        )
                  })}
            </ui>
        </div>
    </>
  )
}
