import {
    CButton,
    CForm, CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {editableAccesses} from "./FilterUtils";
import {useState} from "react";
import {addManager} from "../../../../redux/action/action";

const AddModal = ({ visible, onClose, dispatch, venues }) => {
    const checkboxNames = editableAccesses.map(access => access.columnName);
    const checkboxTitles = editableAccesses.map(access => access.displayName);
    const defaultFormData = {
        name: "",
        email: "",
        phone: "",
        venues: [],
    };
    for (const {columnName} of editableAccesses) {
        defaultFormData[columnName] = false;
    }

    const [formData, setFormData] = useState(defaultFormData);

    const handleAddManager = (event) => {
        event.preventDefault();
        if (!window.confirm("Are you sure to add the member to the team? To remove a member, you would have to contact DoubleSpot support.")) {
            return;
        }
        const newManagerData = {
            ...formData,
            venues: undefined,
            venue_ids: formData.venues.map(venue => venue.value).join(',')
        };
        dispatch(addManager(newManagerData));
    }

    const updateFormData = (event) => {
        let newValue = event.target.value;
        if (checkboxNames.includes(event.target.name)) {
            newValue = event.target.checked;
        }
        setFormData({...formData, [event.target.name]: newValue});
    }

    const updateFormDataMultiselect = (selectedOptions, actionMeta) => {
        setFormData({...formData, [actionMeta.name]: selectedOptions});
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            setFormData(defaultFormData);
            onClose();
        }}>
            <CModalHeader>
                <CModalTitle>Add Team Member</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={handleAddManager}>
                    <CFormLabel htmlFor="name">Name</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="name" value={formData.name} onChange={updateFormData} required/>
                    </div>
                    <CFormLabel htmlFor="email">Email</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="email" value={formData.email} onChange={updateFormData} required/>
                    </div>
                    <CFormLabel htmlFor="phone">Phone</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="phone" value={formData.phone} onChange={updateFormData}/>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="venues">Venues</CFormLabel>
                        <MultiSelectCheckbox
                            name="venues"
                            onChange={updateFormDataMultiselect}
                            options={venues.map(listing => ({
                                label: listing.name,
                                value: listing.id,
                            }))}
                            value={formData.venues ? formData.venues : []}
                        />
                    </div>
                    {
                        checkboxNames.map((checkboxName, index) => (
                            <div className={`form-check pb-${index === checkboxNames.length - 1 ? 3 : 1}`} key={checkboxName}>
                                <input type="checkbox" checked={formData[checkboxName]}
                                       className="form-check-input primary-color"
                                       name={checkboxName}
                                       onChange={updateFormData}/>
                                <label className="check-form-label" htmlFor={checkboxName}>{checkboxTitles[index]}</label>
                            </div>
                        ))
                    }
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default AddModal;
