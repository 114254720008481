import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import TimePicker from "../../../components/TimePicker";
import {addTask, updateTask} from "../../../../redux/action/action";

const EditTaskModal = ({visible, onClose, editFormData, setEditFormData, dispatch}) => {
    const updateFormData = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const updateFormDataTimePicker = (selectedOption, actionMeta) => {
        setEditFormData({...editFormData, [actionMeta.name]: selectedOption?.value});
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (!editFormData.due_date && editFormData.due_time &&
            !window.confirm("Since a due date is not selected, the due time will not be applicable as well. Are you sure to continue?")) {
            return;
        }
        const submissionData = {
            name: editFormData.name,
            due_date: editFormData.due_date || null,
            due_time: (editFormData.due_date && editFormData.due_time) || null,
        }
        if (editFormData?.id === null) {
            submissionData.event_id = editFormData.event_id;
            dispatch(addTask(submissionData));
        } else {
            submissionData.id = editFormData.id;
            dispatch(updateTask(submissionData));
        }
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setEditFormData({});
        }}>
            <CModalHeader>
                <CModalTitle>{editFormData?.id === null ? "Add" : "Edit"} Task</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        <CFormLabel htmlFor="name">Name</CFormLabel>
                        <CFormInput type="text" name="name" value={editFormData?.name} onChange={updateFormData} required/>
                    </div>
                    <div class="pb-3">
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="due_date">Due Date (optional)</CFormLabel>
                            <CFormInput
                                type="date"
                                name="due_date"
                                onChange={updateFormData}
                                value={editFormData.due_date}
                            />
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="due_time">Due Time (optional)</CFormLabel>
                            <TimePicker name="due_time" onChange={updateFormDataTimePicker} value={editFormData.due_time} isClearable/>
                        </div>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default EditTaskModal;
