import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {getProfilePictureUri} from "../../../../../Utils/UriUtils";
import CIcon from "@coreui/icons-react";
import {cilTrash} from "@coreui/icons";

export function SortableItem({ id, src, removeItem }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div class="p-2">
            <div
                class="sortable"
                style={{
                    ...style,
                    backgroundImage: `url("${getProfilePictureUri(src, "../../../assets")}")`
                }}
                ref={setNodeRef}
                {...attributes}
                {...listeners}
            >
                <div class="sortable-close position-absolute d-flex align-items-center justify-content-center" onClick={removeItem}>
                    <CIcon icon={cilTrash} className="white"/>
                </div>
            </div>
        </div>
    );
}