import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { DEFAULT_URL, setAlert } from "../../redux/action/action";
import {useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import CheckoutFormBase from "./CheckoutFormBase";


const CheckoutForm = () => {
	const dispatch = useDispatch()
	const stripe = useStripe();
	const elements = useElements();
	const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
	const userDetail = useSelector(state => state.Apis.userDetail);

	const [checkoutData, setCheckoutData] = useState(JSON.parse(localStorage.getItem("bookingData")))

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		if (localStorage.getItem('token') == null) {
			return dispatch(setAlert('Please Login First', 'error'))
		}

		if (!userDetail.verify_account) {
			return dispatch(setAlert('Please Verify Your Account Email', 'error'))
		}

		if (checkoutData.messageForOwner.length >= 1000) {
			return dispatch(setAlert('Your message to host has more than 1000 characters',
				'error'));
		}

		checkoutData.bussness_id = singleVenueDetails.business_id
		checkoutData.selfBook = false
		localStorage.setItem("checkoutData", JSON.stringify(checkoutData))

		const paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardElement),
		});
		if (paymentMethod.error) {
			return dispatch(setAlert(paymentMethod.error.message, 'error'));
		}

		await fetch(DEFAULT_URL + 'confirmPayment',
			{
				method: 'GET',
				headers: {
					'payment_intent': localStorage.getItem("payment_intent"),
					'payment_method': paymentMethod.paymentMethod.id,
					'client_secret': localStorage.getItem("client_secret")
				}
			}
		).then((res) => {
			return(res.json())
		}).then((data) => {
			if (data.success) {
				window.location = data.url
			} else {
				dispatch(setAlert(data.msg, 'error'));
			}
		});
	};

	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	const paymentSection = <div className="payment_info p-5 border-top">
		<CardElement options={CARD_ELEMENT_OPTIONS}/>
	</div>;

	return <CheckoutFormBase
		checkoutData={checkoutData}
		setCheckoutData={setCheckoutData}
		paymentSection={paymentSection}
		handleSubmit={handleSubmit}
		submitDisabled={!stripe}
	/>
};

export default CheckoutForm;
