import axios from "axios";
import React, { useEffect, useState } from "react";
import { DEFAULT_URL } from "../../redux/action/action";

const Faq = () => {
  document.title = "FAQ | DoubleSpot";
  document.querySelector('meta[name="description"]').setAttribute(
      "content",
      "Discover solutions to common questions and problems on DoubleSpot's FAQ page."
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    getPage();
  }, []);

  async function getPage() {
    try {
      const response = await axios.get(`${DEFAULT_URL}faq/1`);
      setData(response.data.body);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <section class="faq p4-2">
        <div class="container">
          <div class="text-center">
            <h3>FAQ's</h3>
          </div>
          <div class="row gy-4">
            {data.length > 0 &&
              data.map((val, key) => {
                return (
                  <>
                    {" "}
                    <div class="col-lg-4 col-md-6">
                      <div class="card p-4 border-0 box_shadow rounded-4">
                        <h3>{val.question}</h3>
                        <p>{val.answer} </p>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
