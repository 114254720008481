import {CBadge, CButton, CCloseButton, CFormInput} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilFilter} from "@coreui/icons";
import React from "react";

const Filters = ({
                     filters,
                     setFilters,
                     filterCount,
                     updateFilters,
                     filterCategories,
                     setFilterModalVisible,
                 }) => {
    const removeFilter = (filter) => {
        if (filter.type === 'range') {
            const [minCategory, maxCategory] = filter.category;
            setFilters({
                ...filters,
                [minCategory]: undefined,
                [maxCategory]: undefined,
            })
        } else {
            setFilters({
                ...filters,
                [filter.category]: undefined,
            })
        }
    };

    return (
        <div class="flex-row flex-wrap align-items-center">
            <div class="pe-2 pb-3">
                <CFormInput name="search" placeholder="Search..." onChange={updateFilters}/>
            </div>
            {
                filterCategories.map(filter => {
                    let filterApplied = false;
                    let filterText = `${filter.name}: `;
                    if (filter.type === 'exact') {
                        const value = filters[filter.category];
                        if (value) {
                            filterApplied = true;
                            filterText += value;
                        }
                    } else if (filter.type === 'multiple') {
                        const values = filters[filter.category];
                        if (values?.length) {
                            filterApplied = true;
                            filterText += values[0].label;
                            if (values.length > 1) {
                                filterText += ` and ${values.length - 1} more`;
                            }
                        }
                    } else if (filter.type === 'range') {
                        const [minCategory, maxCategory] = filter.category;
                        const [minValue, maxValue] = [filters[minCategory], filters[maxCategory]];
                        if (minValue || maxValue) {
                            filterApplied = true;
                            filterText += `${minValue ? minValue : "min"} to ${maxValue ? maxValue : "max"}`;
                        }
                    }

                    return filterApplied ?
                        <div class="px-2 pb-3">
                            <div class="filter-block d-flex flex-row justify-content-between align-items-center">
                                {filterText}
                                &nbsp;&nbsp;
                                <CCloseButton style={{height: "5px", width: "5px"}} onClick={() => removeFilter(filter)}/>
                            </div>
                        </div> : null
                })
            }
            <div className="ps-2 pb-3">
                <CButton color="light" onClick={() => setFilterModalVisible(true)}>
                    <CIcon icon={cilFilter} className="primary-color"/>{" Filters "}
                    <CBadge color="secondary">{filterCount}</CBadge>
                </CButton>
            </div>
        </div>
    )
};

export default Filters;
