import React from 'react'
import { Link } from 'react-router-dom'

const Logout = () => {
  return (
    <>


  
    <div className="modal fade logout" id="logout" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
        <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
                <div className="modal-header border-0">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i
                            className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="modal-body p-lg-4 p-md-3 purchase_modal">
                    <div className="card_img text-center ">
                        <img src="../assets/images/logout_img.png" alt="DoubleSpot.com" />
                    </div>
                    <div className="card_body text-center">
                        <h3>Logout</h3>
                        <p className="fw-normal">Are you sure you want to
                            logout?</p>
                            <div className="d-flex align-itmes-center gap-3 justify-content-center">
                        <a href="" data-bs-dismiss="modal" aria-label="Close">No</a>
                      <Link to={'/'}  style={{backgroundColor: '#ee3131'}}>Yes</Link>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  )
}

export default Logout
