import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {useEffect} from "react";
import {downloadColumnOptions} from "../../../../Utils/ReservationReportUtils";
import {bookvenues} from "constants";
import {formDataToRecurrenceRule, weekDays} from "../../../../Utils/DateTimeUtils";
import {getOrdinalSuffix} from "../../../../Utils/StringUtils";
import TimePicker from "../../../components/TimePicker";
import {addReservationReport, setAlert, updateReservationReport} from "../../../../redux/action/action";

const EditModal = ({visible, onClose, editJobFormData, setEditJobFormData, dispatch, managers, venues, eventTypes, eventTags}) => {
    const statusOptions = [
        {label: "Confirmed", value: bookvenues.STATUS_CONFIRMED},
        {label: "Pending", value: bookvenues.STATUS_PENDING},
        {label: "Internal", value: bookvenues.STATUS_INTERNAL},
    ];

    const updateFormData = (event) => {
        setEditJobFormData({...editJobFormData, [event.target.name]: event.target.value});
    }

    const updateFormDataCheckbox = (event) => {
        setEditJobFormData({...editJobFormData, [event.target.name]: event.target.checked});
    }

    const updateFormDataMultiselect = (selectedOptions, actionMeta) => {
        setEditJobFormData({...editJobFormData, [actionMeta.name]: selectedOptions});
    }

    const updateFormDataTimePicker = (selectedOption, actionMeta) => {
        setEditJobFormData({...editJobFormData, [actionMeta.name]: selectedOption.value});
    }

    const updateFormDataFrequency = (event) => {
        let newFrequencyDetail = null;
        switch (event.target.value) {
            case 'W':
                newFrequencyDetail = 0; break;
            case 'M':
                newFrequencyDetail = 1; break;
        }
        setEditJobFormData({
            ...editJobFormData,
            frequency: event.target.value,
            frequency_detail: newFrequencyDetail,
        });
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (!editJobFormData?.time) {
            dispatch(setAlert("Please select a valid time for scheduled event report send.", 'error'));
            return;
        }
        const submissionData = {
            ...editJobFormData,
            recipientIds: editJobFormData?.recipients.map(recipient => recipient.value),
            columns: editJobFormData?.columns.map(column => column.value),
            statuses: editJobFormData?.statuses.map(status => status.value),
            venueIds: editJobFormData?.venues.map(venue => venue.value),
            eventtypeIds: editJobFormData?.event_types.map(eventType => eventType.value),
            tagIds: editJobFormData?.tags.map(tag => tag.value),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...formDataToRecurrenceRule(
                editJobFormData?.frequency,
                editJobFormData?.frequency_detail,
                editJobFormData?.time
            ),
        };
        if (editJobFormData?.id === null) {
            dispatch(addReservationReport(submissionData));
        } else {
            dispatch(updateReservationReport(submissionData));
        }
    }

    useEffect(() => {
        if (!editJobFormData?.columns?.map(column => column.value).includes(editJobFormData?.sort_by)) {
            setEditJobFormData({
                ...editJobFormData,
                sort_by: -1,
            });
        }
    }, [editJobFormData?.columns]);

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setEditJobFormData({
                ...editJobFormData,
                columns: downloadColumnOptions,
            });
        }}>
            <CModalHeader>
                <CModalTitle>{editJobFormData?.id === null ? "Add" : "Edit"} Scheduled Report</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body" style={{height: "500px"}}>
                <CForm onSubmit={onSubmit}>
                    <div class="pb-3">
                        <CFormLabel htmlFor="name">Name</CFormLabel>
                        <CFormInput type="text" name="name" value={editJobFormData?.name}
                                    onChange={updateFormData}/>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="recipients">Recipients</CFormLabel>
                        <MultiSelectCheckbox
                            name="recipients"
                            onChange={updateFormDataMultiselect}
                            options={managers.map(manager => ({
                                label: manager.managerUser?.name,
                                value: manager.managerId,
                            }))}
                            value={editJobFormData?.recipients || []}
                        />
                    </div>
                    <div className="pb-3">
                        <CFormLabel>Report frequency</CFormLabel>
                        <div className="flex-row align-items-center">
                            <span>Send every</span>
                            <div className="px-2">
                                <CFormSelect name="frequency" value={editJobFormData?.frequency}
                                             onChange={updateFormDataFrequency}>
                                    <option value='D'>day</option>
                                    <option value='W'>week</option>
                                    <option value='M'>month</option>
                                </CFormSelect>
                            </div>
                            {
                                editJobFormData?.frequency === 'W' ?
                                    <>
                                        <span>on</span>
                                        <div className="px-2">
                                            <CFormSelect name="frequency_detail"
                                                         value={editJobFormData?.frequency_detail}
                                                         onChange={updateFormData}>
                                                {weekDays.map((weekday, index) => (
                                                    <option value={index}>{weekday}</option>
                                                ))}
                                            </CFormSelect>
                                        </div>
                                    </> :
                                    editJobFormData?.frequency === 'M' ?
                                        <>
                                            <span>on</span>
                                            <div className="px-2">
                                                <CFormSelect name="frequency_detail"
                                                             value={editJobFormData?.frequency_detail}
                                                             onChange={updateFormData}>
                                                    {[...Array(28).keys()].map(index => {
                                                        const dateNumber = index + 1;
                                                        return <option value={dateNumber}>
                                                            {`${dateNumber}${getOrdinalSuffix(dateNumber)}`}
                                                        </option>
                                                    })}
                                                </CFormSelect>
                                            </div>
                                        </> : null
                            }
                        </div>
                        <div className="flex-row align-items-center pt-2">
                            <span>at</span>
                            <div className="px-2">
                                <TimePicker
                                    name="time"
                                    onChange={updateFormDataTimePicker}
                                    value={editJobFormData?.time}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="days_ahead">Time Range</CFormLabel>
                        <div className="flex-row align-items-center">
                            <span>Include events for the next</span>
                            <div className="px-2">
                                <CFormInput type="number" required min="1" name="days_ahead" value={editJobFormData?.days_ahead}
                                            onChange={updateFormData} style={{width: "75px"}}/>
                            </div>
                            <span>days</span>
                        </div>
                    </div>
                    <div class="pb-3">
                        <CFormLabel>Columns</CFormLabel>
                        <MultiSelectCheckbox
                            value={editJobFormData?.columns || []}
                            name="columns"
                            onChange={updateFormDataMultiselect}
                            options={downloadColumnOptions}
                        />
                    </div>
                    <div className="pb-3">
                        <CFormLabel>Sort by</CFormLabel>
                        <CFormSelect name="sort_by" value={editJobFormData?.sort_by}
                                     onChange={updateFormData}>
                            {
                                editJobFormData?.columns?.map(column =>
                                    <option value={column.value}>{column.label}</option>
                                )
                            }
                            <option value={-1}>Default</option>
                        </CFormSelect>
                    </div>
                    <div className="form-check pb-3">
                        <input type="checkbox" checked={editJobFormData?.sort_desc}
                               className="form-check-input primary-color"
                               name="sort_desc"
                               onChange={updateFormDataCheckbox}/>
                        <label className="check-form-label" htmlFor="sort_desc">Sort Descending</label>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="statuses">Statuses</CFormLabel>
                        <MultiSelectCheckbox
                            name="statuses"
                            onChange={updateFormDataMultiselect}
                            options={statusOptions}
                            value={editJobFormData?.statuses || []}
                        />
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="venues">Venues</CFormLabel>
                        <MultiSelectCheckbox
                            name="venues"
                            onChange={updateFormDataMultiselect}
                            options={venues.map(listing => ({
                                label: listing.name,
                                value: listing.id,
                            }))}
                            value={editJobFormData?.venues || []}
                        />
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="event_types">Event Types</CFormLabel>
                        <MultiSelectCheckbox
                            name="event_types"
                            onChange={updateFormDataMultiselect}
                            options={eventTypes.map(eventType => ({
                                label: eventType.name,
                                value: eventType.id,
                            }))}
                            value={editJobFormData?.event_types || []}
                        />
                    </div>
                    <div class="pb-2">
                        <CFormLabel htmlFor="tags">Tags</CFormLabel>
                        <MultiSelectCheckbox
                            name="tags"
                            onChange={updateFormDataMultiselect}
                            options={eventTags.map(eventTag => ({
                                label: eventTag.name,
                                value: eventTag.id,
                            }))}
                            value={editJobFormData?.tags || []}
                        />
                    </div>
                    <div className="form-check pb-3">
                        <input type="checkbox" checked={editJobFormData?.match_any_tags}
                               className="form-check-input primary-color"
                               name="match_any_tags"
                               onChange={updateFormDataCheckbox}/>
                        <label className="check-form-label"
                               htmlFor="match_any_tags">Match Any Tags</label>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default EditModal;
