import { SHOW_ALERT, SHOW_ALERT_SUCCESS } from "../action/action";
const initialState = {
  alert: null,
};
const Alert = (state =initialState , action) => {
    switch(action.type){
        case SHOW_ALERT_SUCCESS:
            return {
                ...state,
                alert: action.payload,
              };
        default:
            return state;
    }
}

export default Alert


