export const columns = (onTaskStatusChange) => [
    {
        name: "Start Date",
        selector: row => row.start_date,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: "End Date",
        selector: row => row.end_date,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Day",
        selector: row => row.day,
        width: "100px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Time",
        selector: row => row.time,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Instructor",
        selector: row => row.instructor,
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Venue",
        selector: row => row.venue,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Availability",
        selector: row => row.availability,
        width: "150px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Price",
        selector: row => row.price,
        width: "100px",
        wrap: true,
        sortable: true,
    },
    
];

export const attendeesColumns = (onTaskStatusChange) => [
    {
        name: "Name",
        selector: row => row.name,
        width: "250px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Email",
        selector: row => row.email,
        width: "300px",
        wrap: true,
        sortable: true,
    },
    {
        name: "Phone",
        selector: row => row.phone,
        width: "150px",
        wrap: true,
        sortable: true,
    },
];
