import React, { useEffect, useState } from "react";
import { DEFAULT_URL } from "../../../redux/action/action";
import axios from 'axios';

const LocationMap = ({ locationId, size }) => {
    console.log("CHECK THE USER DATA", locationId, size)
    const [latLog, setLatLong] = useState({})
    const [sizeMap, setSize] = useState("200px")

    async function GetLocationMap(sendVal) {
        try {
            const queryParams = new URLSearchParams(sendVal);
            const url = `${DEFAULT_URL}getLocation?${queryParams}`;

            const response = await axios.get(url);
            setLatLong(response?.data?.body?.geometry?.location)
            return true;

        } catch (error) {
            console.log("Check the error", error);
        }
    }

    useEffect(() => {
        const checkData = GetLocationMap({ place_id: locationId })
        setSize(size)
    }, [locationId])

    return <>
        <iframe
            className="border-line w-100"
            src={`https://maps.google.com/maps?q=${latLog?.lat},${latLog?.lng}&t=&z=8&ie=UTF8&iwloc=&output=embed&z=13`}
            style={{ height: sizeMap }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            frameborder="0"
        ></iframe>
    </>
}


export default LocationMap