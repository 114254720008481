import React, {useEffect, useRef, useState} from "react";
import {
    getEventTypes,
    getPublicProviderVenueListing,
    publicCalendarData
} from "../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {
    CButton,
    CButtonGroup,
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilCaretLeft, cilCaretRight} from "@coreui/icons";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {formatDate, weekDays} from "../../Utils/DateTimeUtils";
import {bookvenues} from "constants";

const Calendar = () => {
    document.title = "Calendar | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View the venue owner's schedule on DoubleSpot's calendar page."
    );

    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const currentBookings = useSelector(state => state.Apis.currentBooking);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);

    const [listings, setListings] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [modalBooking, setModalBooking] = useState({});
    const [bookingModalVisible, setBookingModalVisible] = useState(false);
    const [venueFilter, setVenueFilter] = useState({});
    const [eventTypeFilter, setEventTypeFilter] = useState({});
    const [calendarTitle, setCalendarTitle] = useState('');

    const handleEventClick = (clickInfo) => {
        setModalBooking(bookings.find(booking => booking.id === clickInfo.event.extendedProps.modalId));
        setBookingModalVisible(true);
    };

    const handleEventTypeCheckboxChange = (event, value) => {
        setEventTypeFilter({...eventTypeFilter, [value]: event.target.checked});
    };

    const handleVenueCheckboxChange = (event, value) => {
        setVenueFilter({...venueFilter, [value]: event.target.checked});
    };

    useEffect(() => {
        dispatch(getEventTypes({
            businessId: parseInt(document.location.pathname.split("/")[2])
        }));
    }, []);

    useEffect(() => {
        setEventTypes([
            ...businessEventTypes,
            {
                id: 0,
                name: "Other",
                color: 0,
            }
        ]);
        const defaultEventTypeFilter = {};
        businessEventTypes.map(eventType => {
            defaultEventTypeFilter[eventType.id] = true;
        });
        // 0 is reserved for other
        defaultEventTypeFilter[0] = true;
        setEventTypeFilter(defaultEventTypeFilter);
    }, [businessEventTypes]);

    useEffect(() => {
        if (calendarRef.current) {
            setCalendarTitle(calendarRef.current.getApi().getCurrentData().viewTitle);
        }
    }, [calendarRef.current]);

    const prevButtonClick = () => {
        calendarRef.current.getApi().prev();
        setCalendarTitle(calendarRef.current.getApi().getCurrentData().viewTitle)
    }

    const nextButtonClick = () => {
        calendarRef.current.getApi().next();
        setCalendarTitle(calendarRef.current.getApi().getCurrentData().viewTitle)
    }

    useEffect(() => {
        const businessId = document.location.pathname.split("/")[2];
        dispatch(publicCalendarData({
            businessId: businessId
        }));
        dispatch(getPublicProviderVenueListing({
            businessId: businessId
        }));
    }, []);

    useEffect(() => {
        setListings(venueListings);
        const defaultFilter = {};
        venueListings.map(listing => {
            defaultFilter[listing.id] = true;
        })
        setVenueFilter(defaultFilter);
    }, [venueListings]);

    useEffect(() => {
        if (currentBookings) {
            setBookings(currentBookings);
            setCalendarEvents(currentBookings
                .filter(
                    booking => venueFilter[booking.venueId]
                )
                .filter(
                    booking => {
                        if (!booking.eventtype_id) {
                            return eventTypeFilter[0];
                        } else {
                            return eventTypeFilter[booking.eventtype_id]
                        }
                    }
                )
                .map(
                    booking => {
                        let colorIndex = booking.eventtype_color;
                        return {
                            title: booking.title,
                            start: `${booking.date}T${booking.start_time}`,
                            end: `${booking.end_date}T${booking.end_time}`,
                            modalId: booking.id,
                            description: `Venue booked by ${booking.booked_by_name} with payment ${booking.booked_price}$`,
                            classNames: [
                                booking.status === bookvenues.STATUS_PENDING ? "pending" : "confirmed",
                                `calendar-event-color-${colorIndex ? colorIndex : "gray"}`
                            ],
                        };
                    }
                )
            );
        }
    }, [currentBookings, eventTypeFilter, venueFilter]);

    return (
        <div className="p-4 w-100 h-100 d-flex flex-column">
            <div className="flex-row align-items-center pt-1 pb-3">
                <div className="col-1">
                    <CButtonGroup role="group" aria-label="Basic outlined example">
                        <CButton color="dark" variant="outline" onClick={prevButtonClick}><CIcon
                            icon={cilCaretLeft}/></CButton>
                        <CButton color="dark" variant="outline" onClick={nextButtonClick}><CIcon icon={cilCaretRight}/></CButton>
                    </CButtonGroup>
                </div>
                <div className="col-3 px-3"><h3 className="mb-0">{calendarTitle}</h3></div>
                <div className="col-8 flex-row justify-content-end">
                    <CDropdown autoClose="outside">
                        <CDropdownToggle color="light">Filters</CDropdownToggle>
                        <CDropdownMenu>
                            <div className="px-3 py-2" style={{width: "500px"}}>
                                <h6>Event Types</h6>
                                {
                                    eventTypes.map((eventType) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={eventTypeFilter[eventType.id]}
                                                       className={`form-check-input calendar-event-color-${eventType.color ? eventType.color : "gray"}`}
                                                       id={eventType.id}
                                                       onChange={(event) => handleEventTypeCheckboxChange(event, eventType.id)}/>
                                                <label className="check-form-label"
                                                       htmlFor={eventType.id}>{eventType.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                <h6 className="pt-2">Venues</h6>
                                {
                                    listings.map((listing) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={venueFilter[listing.id]}
                                                       className="form-check-input primary-color"
                                                       id={listing.id}
                                                       onChange={(event) => handleVenueCheckboxChange(event, listing.id)}/>
                                                <label className="check-form-label"
                                                       htmlFor={listing.id}>{listing.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </CDropdownMenu>
                    </CDropdown>
                </div>
            </div>
            <div className="flex-row py-1">
                {
                    weekDays.map(weekday => <div class="col-sm text-center" style={{fontWeight: 500}}>{weekday}</div>)
                }
            </div>
            <div className="flex-grow-1 w-100" style={{height: "768px"}}>
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={false}
                    initialView='dayGridMonth'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    fixedWeekCount={false}
                    dayHeaders={false}
                    events={calendarEvents}
                    eventClick={handleEventClick}
                    eventsSet={() => {
                    }}
                />
            </div>
            <CModal scrollable visible={bookingModalVisible} onClose={() => setBookingModalVisible(false)}>
                <CModalHeader>
                    <CModalTitle>{modalBooking.title}</CModalTitle>
                </CModalHeader>
                <CModalBody className="p-4 business-modal-body">
                    <h5>Logistics Information</h5>
                    <div>
                        <p><b className="event-modal-information-title">Venue: </b><br/>{modalBooking?.venue_name}</p>
                        <p><b className="event-modal-information-title">Start
                            Time: </b><br/>{formatDate(modalBooking?.date, modalBooking?.start_time)}</p>
                        <p><b className="event-modal-information-title">End
                            Time: </b><br/>{formatDate(modalBooking?.end_date, modalBooking?.end_time)}</p>
                    </div>
                    <h5>Event Information</h5>
                    <div>
                        <p><b className="event-modal-information-title">Type: </b><br/>{
                            modalBooking?.eventtype_name ? modalBooking?.eventtype_name : "Other"
                        }</p>
                        <p><b className="event-modal-information-title">Attendees: </b><br/>
                            {modalBooking?.attendees}{modalBooking?.attendees === 1 ? " person" : " people"}
                        </p>
                        {
                            modalBooking?.confirmation_no &&
                            <p><b className="event-modal-information-title">Confirmation
                                Number: </b><br/>{modalBooking?.confirmation_no}</p>
                        }
                    </div>
                    <h5>Customer Information</h5>
                    <p className="mb-0">Name: {
                        modalBooking?.customer_first_name || modalBooking?.customer_last_name ?
                            `${modalBooking?.customer_first_name} ${modalBooking?.customer_last_name}` :
                            "N/A"
                    }</p>
                    <p className="mb-0">Phone: {modalBooking?.customer_number ? modalBooking?.customer_number : "N/A"}</p>
                    <p className="mb-1">Email: {modalBooking?.customer_email ? modalBooking?.customer_email : "N/A"}</p>
                    {modalBooking?.status === bookvenues.STATUS_INTERNAL ?
                        <>
                            <h5 className="mt-3 mb-2">Description</h5>
                            <p className="mb-0 fw-normal">{modalBooking?.customer_message ? modalBooking?.customer_message : "No description provided."}</p>
                        </> :
                        modalBooking?.customer_message &&
                        <>
                            <p className="mb-0"><b className="event-modal-information-title">Message For Owner:</b>
                                <br/>{modalBooking?.customer_message}</p>
                        </>
                    }
                </CModalBody>
            </CModal>
        </div>
    );
};

export default Calendar;
