import ReactSelect from "react-select";
import React from "react";

const RoundedMultiSelect = ({ name, onChange, options }) => {
    return <ReactSelect
        isMulti
        closeMenuOnSelect={false}
        menuShouldScrollIntoView={false}
        hideSelectedOptions={false}
        name={name}
        onChange={onChange}
        options={options}
        styles={{
            control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "20px",
            }),
            menu: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "20px",
                zIndex: "10000",
            }),
            option: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "20px",
            }),
            multiValue: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "20px",
            }),
            multiValueRemove: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "20px",
            }),
            valueContainer: (baseStyles) => ({
                ...baseStyles,
                maxHeight: "36px",
                overflow: "auto",
            }),
        }}
    />
}

export default RoundedMultiSelect;
