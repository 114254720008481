import {
    CButton,
    CForm,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {updateManagerAccess} from "../../../../redux/action/action";
import {editableAccesses} from "./FilterUtils";

const EditModal = ({ visible, onClose, editFormData, setEditFormData, dispatch, venues, managerName }) => {
    const checkboxNames = editableAccesses.map(access => access.columnName);
    const checkboxTitles = editableAccesses.map(access => access.displayName);

    const handleUpdateManagerAccess = (event) => {
        event.preventDefault();
        dispatch(updateManagerAccess({
            ...editFormData,
            name: undefined,
            venues: undefined,
            venue_ids: editFormData.venues.map(venue => venue.value).join(',')
        }));
    }

    const updateEditForm = (event) => {
        let newValue = event.target.value;
        if (checkboxNames.includes(event.target.name)) {
            newValue = event.target.checked;
        }
        setEditFormData({...editFormData, [event.target.name]: newValue});
    }

    const updateEditFormMultiselect = (selectedOptions, actionMeta) => {
        setEditFormData({...editFormData, [actionMeta.name]: selectedOptions});
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Team Member Access: {managerName}</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={handleUpdateManagerAccess}>
                    <div class="pb-3">
                        <CFormLabel htmlFor="venues">Venues</CFormLabel>
                        <MultiSelectCheckbox
                            name="venues"
                            onChange={updateEditFormMultiselect}
                            options={venues.map(listing => ({
                                label: listing.name,
                                value: listing.id,
                            }))}
                            value={editFormData.venues ? editFormData.venues : []}
                        />
                    </div>
                    {
                        checkboxNames.map((checkboxName, index) => (
                            <div className={`form-check pb-${index === checkboxNames.length - 1 ? 3 : 1}`} key={checkboxName}>
                                <input type="checkbox" checked={editFormData[checkboxName]}
                                       className="form-check-input primary-color"
                                       name={checkboxName}
                                       onChange={updateEditForm}/>
                                <label className="check-form-label" htmlFor={checkboxName}>{checkboxTitles[index]}</label>
                            </div>
                        ))
                    }
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default EditModal;
