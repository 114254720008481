import React, { useEffect, useState, useRef } from "react";
import ENV from '../../env.json'
import moment from 'moment';
import { SITE_URL } from "../../redux/action/action";
export default function Conversations({ currentChat, conversations, loginUser, deleteChat, loader}) {

    const scrollChatRef = useRef(null);
    useEffect(() => {
        if (scrollChatRef) {
            scrollChatRef.current.addEventListener('DOMNodeInserted', event => {
            const { currentTarget: target } = event;
            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
        });
        }
    }, []);
 
  return (
      <>
          <div className="card-header msg_head">
            {currentChat &&
            <div className="d-flex bd-highlight  align-items-center gap-1">
                <div className="img_cont w-auto img_cont_msg">
                    <img src={currentChat.receiver_image ? SITE_URL+'images/'+currentChat.receiver_image : '../assets/images/chat_user_img01.png'} className="rounded-circle user_img_msg" />
                    <span className="online_icon " style={{top: "11px; left: 0px"}}></span>
                </div>
                <div className="user_info ps-3">
                    <span>{currentChat.venu_name} ({`${loginUser!= currentChat.receiver_id ? currentChat.receiver.name : currentChat.sender.name}`})</span> <br/>
                    <small>{currentChat.online_status==1 ? 'Online'  : 'Offline'} </small>
                </div>
              </div>
            }
        </div>
          <div className="msg_card_body pt-2 px-2" ref={scrollChatRef}>
              {conversations.length > 0 && conversations.map((convo, key) => {
                  let content = 'msg_cotainer';
                  let background = 'd-flex justify-content-start mb-4'
                  let timeContent = 'msg_time'
                  let timeDay = moment.unix(convo.created).format("hh:mm A");
                    if(loginUser==convo.sender_id){
                        content = 'msg_cotainer_send ms-auto';
                        background = 'd-flex justify-content-end mb-4';
                        timeContent = 'msg_time_send'
                    }
                  
                  return (
                    <>
                        <div className={background} key={key}>
                        

                        {loginUser == convo.receiver_id &&
                            <div className="img_cont_msg">
                                <img src={convo.sender_image ?  SITE_URL+'images/'+convo.sender_image : '../assets/images/chat_user_img01.png'} className="rounded-circle user_img_msg" />
                            </div>
                        }       
                        {loginUser != convo.receiver_id &&   
                            <span className={timeContent}>{timeDay}</span>
                        }
                        <div style={{maxWidth: "70%"}}>
                            <div className={content}>
                            {convo.msg_type==1 ? <a href={`${ENV.SERVER_URL+convo.message}`} target="_blank"><img src={ENV.SERVER_URL+convo.message} width='80px' height='80px'/></a> : convo.msg_type==2 ? <a href={ENV.SERVER_URL+convo.message} target="_blank"><i class="fa-solid fa-file-pdf"></i> open pdf</a> : convo.message}
                              
                            </div>
                        </div>
                        {loginUser == convo.receiver_id &&
                            <span className={timeContent}>{timeDay}</span>
                        }
                        {loginUser != convo.receiver_id &&   
                            <div className="img_cont_msg">
                                <img src={convo.sender_image ?   SITE_URL+'images/'+convo.sender_image : '../assets/images/chat_user_img01.png'} className="rounded-circle user_img_msg" />
                            </div>
                        } 
                    </div>
                    </>
                )
            })}
        </div>
    </>
  )
}
