import {
    CBadge,
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CProgress,
    CProgressBar
} from "@coreui/react";
import {formatDate} from "../../../../Utils/DateTimeUtils";
import {bizDeleteEvent, confirmEvent, declineEvent} from "../../../../redux/action/action";
import {bookvenues} from "constants";
import CIcon from "@coreui/icons-react";
import {cilExternalLink} from "@coreui/icons";
import {getTaskCompletionPercentage} from "../../../../Utils/TaskUtils";
import {useEffect} from "react";
import ProgressBar from "../../../components/ProgressBar";

const BookingModal = ({ visible, onClose, modalBooking, dispatch, setBookingModalVisible, setEditEventModalVisible, editEventAccess, acceptBookingAccess }) => {
    const handleEditButtonClick = () => {
        setBookingModalVisible(false);
        setEditEventModalVisible(true);
    }

    const handleConfirmEvent = () => {
        if (window.confirm("Are you sure you want to accept this booking request? If you proceed, the customer will receive an email with the confirmation details.")) {
            dispatch(confirmEvent({
                id: modalBooking?.id,
                payment_intent_id: modalBooking?.payment_intent_id,
            }));
            setBookingModalVisible(false);
        }
    }

    const handleDeclineEvent = () => {
        if (window.confirm("Are you sure you want to decline this booking request? Once declined, this reservation will be canceled and the customer will be notified.")) {
            const additionalInformation = prompt("Add additional information about the decline to encourage customers to rebook.");
            dispatch(declineEvent({
                id: modalBooking?.id,
                payment_intent_id: modalBooking?.payment_intent_id,
                additionalInformation: additionalInformation
            }));
            setBookingModalVisible(false);
        }
    }

    const handleDeleteEvent = () => {
        if (window.confirm("You are about to delete this event. Are you sure you want to continue?")) {
            const additionalInformation = modalBooking.status === bookvenues.STATUS_INTERNAL ?
                null : prompt("Add additional information about the reason of cancellation for customers.");
            dispatch(bizDeleteEvent({
                id: modalBooking?.id,
                payment_intent_id: modalBooking?.payment_intent_id,
                additionalInformation: additionalInformation
            }));
            setBookingModalVisible(false);
        }
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <a className="flex-row align-items-center external-link" href={`/business/events/${modalBooking?.id}`} target="_blank">
                    <CModalTitle>{modalBooking.title}</CModalTitle>
                    <div className="hover-grey px-2 py-1 ms-2">
                        <CIcon  icon={cilExternalLink}/>
                    </div>
                </a>
            </CModalHeader>
            <CModalBody className="p-4 business-modal-body">
                <h5>Logistics Information</h5>
                <div>
                    <p><b className="event-modal-information-title">Venue: </b><br/>{modalBooking?.venue_name}</p>
                    <p><b className="event-modal-information-title">Start
                        Time: </b><br/>{formatDate(modalBooking?.date, modalBooking?.start_time)}</p>
                    <p><b className="event-modal-information-title">End
                        Time: </b><br/>{formatDate(modalBooking?.end_date, modalBooking?.end_time)}</p>
                </div>
                <h5>Event Information</h5>
                <div>
                    <p>
                        <b className="event-modal-information-title">Type and Tags</b>
                        <br/>
                        <div className="flex-row flex-wrap pt-2 row-gap-1">
                            <div>
                                <CBadge class={`badge calendar-event-color-${modalBooking?.eventtype_color ? modalBooking?.eventtype_color : "gray"}`}>
                                    {modalBooking.eventtype_name || "Other"}
                                </CBadge>
                            </div>
                            {
                                modalBooking?.tags?.map(tag => (
                                    <div className="ps-2">
                                        <CBadge className="black" color="light">{tag.name}</CBadge>
                                    </div>
                                ))
                            }
                        </div>
                    </p>
                    <p><b className="event-modal-information-title">Attendees: </b><br/>
                        {modalBooking?.attendees}{modalBooking?.attendees === 1 ? " person" : " people"}
                    </p>
                    <p><b className="event-modal-information-title">{
                        modalBooking?.status === bookvenues.STATUS_INTERNAL || !(modalBooking?.payment_intent_id) ? "Revenue" : "Booked Price"
                    }:</b>
                        <br/>${modalBooking?.booked_price}</p>
                    {
                        modalBooking?.confirmation_no &&
                        <p><b className="event-modal-information-title">Confirmation
                            Number: </b><br/>{modalBooking?.confirmation_no}</p>
                    }
                </div>
                <h5>Customer Information</h5>
                <p className="mb-0">Name: {
                    modalBooking?.customer_first_name || modalBooking?.customer_last_name ?
                        `${modalBooking?.customer_first_name} ${modalBooking?.customer_last_name}` :
                        "N/A"
                }</p>
                <p className="mb-0">Phone: {modalBooking?.customer_number ? modalBooking?.customer_number : "N/A"}</p>
                <p className="mb-1">Email: {modalBooking?.customer_email ? modalBooking?.customer_email : "N/A"}</p>
                {modalBooking?.status === bookvenues.STATUS_INTERNAL ?
                    null :
                    modalBooking?.customer_message &&
                    <>
                        <p className="mb-0 display-line-break"><b className="event-modal-information-title">Message For Owner:</b>
                            <br/>{modalBooking?.customer_message}</p>
                    </>
                }
                {
                    <>
                        <h5 className="mt-3 mb-2">Description</h5>
                        <p className="mb-0 fw-normal display-line-break">{modalBooking?.description ? modalBooking?.description : "No description provided."}</p>
                    </> 
                }
                {
                    modalBooking?.tasks?.length ? <>
                        <h5 className="mt-3">Task Completion</h5>
                        <div className="flex-row align-items-center">
                            <ProgressBar percentage={getTaskCompletionPercentage(modalBooking?.tasks)} className="w-75"/>
                            <div className="flex-row w-25 justify-content-end">
                                <a className="external-link dodgers-blue" href={`/business/events/${modalBooking?.id}`}
                                   target="_blank">view detail</a>
                            </div>
                        </div>
                    </> : null
                }
            </CModalBody>
            {
                modalBooking?.status === bookvenues.STATUS_PENDING ?
                    (acceptBookingAccess ?
                        <CModalFooter className="justify-content-start">
                        <CButton className="white" color="success" onClick={handleConfirmEvent}>Accept</CButton>
                        <CButton className="white" color="danger" onClick={handleDeclineEvent}>Decline</CButton>
                    </CModalFooter> : null)
                    :
                modalBooking?.status === bookvenues.STATUS_INTERNAL || (modalBooking?.status === bookvenues.STATUS_CONFIRMED && !(modalBooking?.payment_intent_id))?
                    (editEventAccess ?
                        <CModalFooter className="justify-content-start">
                            <CButton color="dark" variant="outline" onClick={handleEditButtonClick}>Edit</CButton>
                            <CButton className="white" color="danger" onClick={handleDeleteEvent}>Delete</CButton>
                        </CModalFooter> : null)
                    : 
                modalBooking?.status === bookvenues.STATUS_CONFIRMED ?
                    (editEventAccess ?
                        <CModalFooter className="justify-content-start">
                            <CButton color="dark" variant="outline" onClick={handleEditButtonClick}>Edit</CButton>
                        </CModalFooter> : null)
                    :null
            }
        </CModal>
    );
};

export default BookingModal;
