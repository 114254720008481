import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWishList } from '../../redux/action/action'
import RecommendedVenues from "../Layout/Home/RecommendedVenues";

function Wishlist() {
    document.title = "Wishlist | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Keep track of your favorite event venues on DoubleSpot for future reference."
    );

    const dispatch = useDispatch()
    const [venues, setVenues] = useState([])

    const listing = useSelector(state => state.Apis.wishList)

    useEffect(() => {
        setVenues(listing.map((venue) => ({
            ...venue.venue,
            review_count: venue.review_count,
            average_rating: venue.average_rating,
        })))
    }, [listing])

    useEffect(() => {
        dispatch(getWishList())
    }, [])

    return (
        <>
            <section className="venues pb-3">
                <div className="container">
                    <h3 className="text-center mb-3">
                        Wishlists
                    </h3>
                    <div className="row gy-3">
                        {venues && venues.length == 0 &&
                            <div className="text-center">
                                <h6>There seems to be nothing here. If you save a venue it will appear here!</h6>
                            </div>
                        }
                        {   
                            <RecommendedVenues
                                dispatch={dispatch}
                                venues={venues}
                                setVenues={setVenues}
                                removeVenue={true}
                            />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wishlist
