import {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {setAlert, SITE_URL} from "../../../../../redux/action/action";

const UploadZone = ({ dispatch, images, setImages, imageOrder, setImageOrder }) => {
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, { type: file.type });
                    resolve(resizedFile);
                }, file.type, 1);
            };

            img.onerror = (error) => reject(error);
        });
    };

    const uploadImage = async (acceptedFiles) => {
        if (images.length >= 7) {
            dispatch(setAlert('A venue can have at most 7 photos.', 'error'));
            return;
        }
        if (acceptedFiles) {
            const newImages = [];
            const maxWidth = 1920;
            const maxHeight = 1080;

            for (const i in acceptedFiles) {
                const file = acceptedFiles[i];
                if (!(file instanceof File)) {
                    continue;
                }

                try {
                    const resizedFile = await resizeImage(file, maxWidth, maxHeight);
                    newImages.push({ src: URL.createObjectURL(resizedFile), file: resizedFile });
                } catch (error) {
                    console.error("Error resizing image:", error);
                }
            }

            const newOrder = [...imageOrder, ...([...Array(newImages.length).keys()].map(i => i + images.length + 1))];
            setImageOrder(newOrder);
            setImages([...images, ...newImages]);
        }
    }

    const onDrop = useCallback(async acceptedFiles => {
        await uploadImage(acceptedFiles);
    }, [images, imageOrder]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} className="sortable" style={{
            backgroundImage: `url("${SITE_URL}assets/images/cil-image-plus.svg")`,
            backgroundSize: "25%",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#eeeeee",
        }}>
            <div className="d-flex justify-content-center position-absolute" style={{
                top: "95px",
                left: "15px",
            }}>
                {
                    isDragActive ? "Drop files here" : "Click or drag and drop to upload"
                }
            </div>
            <input {...getInputProps()} type="file" accept="image/*" multiple />
        </div>
    )
};

export default UploadZone;
