import CIcon from "@coreui/icons-react";
import {CAvatar} from "@coreui/react";
import {
    cilCalendar,
    cilClock,
    cilLocationPin,
} from "@coreui/icons";
import {getProfilePictureUri} from "../../Utils/UriUtils";
import LocationMap from "../Layout/Alerts/LocationMap";

const Program = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="col-12 d-flex justify-content-between align-itmes-center mt-3 mb-4">
                        <div className="user_name">
                            <h3 className="d-flex justify-content-start align-items-center">Beginner Pickleball
                                <span className="ms-2">
                                </span>
                            </h3>
                            <div className="flex-row pt-1">
                                <span className="align-items-center pe-4"><CIcon icon={cilCalendar}></CIcon>&nbsp; Nov 3, 2024 - Mar 7, 2024 </span>
                                <span className="align-items-center pe-4"><CIcon icon={cilClock}></CIcon>&nbsp; Mondays, 05:00 PM - 06:30 PM </span>
                                <span className="align-items-center"><CIcon icon={cilLocationPin}></CIcon>&nbsp; Highland Park Pickleball Court</span>
                            </div>
                        </div>
                    </div> 
                    <div className="rounded-3 overflow-hidden">
                        <h2 className="accordion-header" id="headingOne">
                            <img 
                                style={{
                                    height:"450px",
                                    width:"1300px",
                                    objectFit: "cover",
                                    objectPosition: "100% 50%"
                                }}
                                src={"https://i.postimg.cc/cJ8ZG5ms/365368536.jpg"}
                            />
                        </h2>
                    </div>
                </div>
            </section>
            <section>
                <div className="container py-4">
                    <div className="row pt-4">
                        <div className="col-md-8">
                            <h3 className="pb-2">Description</h3>
                            <span>Join our beginner boot camp! You will learn the basic stroke mechanics, rules/scoring, and basic shots needed to get you playing matches on your own! No equipment needed!</span>
                            <div className="py-3"><hr/></div>
                            <h3>Instructor</h3>
                            <div className="flex-row align-items-center py-3">
                                <CAvatar className="avatar-60" size="xl" shape="rounded-circle" src={getProfilePictureUri("https://i.postimg.cc/BZynkHsc/813882900.jpg", "../../../assets")}/>
                                <h5 className="ps-3">Jerry Tudor</h5>
                            </div>
                            <span>
                            With 7 years of experience in pickleball, I'm passionate about helping players of all levels improve their skills and have fun on the court. I offer personalized coaching focused on technique, strategy, and confidence. Let's take your pickleball to the next level!
                            </span>
                        </div>
                        <div className="col-md-4">
                            <div className="book_card box_shadow px-3 py-4 rounded-4"> 
                                <div className="heading">
                                    <h5>16/20 spots available</h5>
                                    <h3 className="pt-2">$100.00</h3>
                                    <small>12 sessions</small>
                                    <div className="pt-3">
                                        <button type="button" className="btn rounded-pill"
                                            style={{backgroundColor: "#ff7e67",
                                                    color: "#ffffff",
                                                    height: "40px",
                                                    width: "50%"
                                            }}>
                                            Enroll
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-3"><hr/></div>
                    <div className="col-12 py-2">
                        <div className="venue_map">
                            <LocationMap locationId={"ChIJXfkIbDvXDIgRubyX_KDD_dY"} size={"450px"} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Program;