import {CButton, CCard, CCardBody, CFormSelect, CFormLabel, CFormSwitch} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserProfile, updateManagerSettings} from "../../../redux/action/action";

const UserPreference = () => {
    document.title = "User Preference | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Customize your expereince with DoubleSpot!"
    );
    const dispatch = useDispatch();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const [formData, setFormData] = useState({});

    const updateForm = (event) => {
        setFormData({...formData, 
                    [event.target.name]: event.target.name === "emailNotification" ? 
                        event.target.checked ? 1 : 0 : 
                        event.target.value
                    });
    };

    const submitForm = () => {
        dispatch(updateManagerSettings(formData));
    };

    useEffect(() => {
        dispatch(getUserProfile());
    }, [])

    useEffect(() => {
        if (userDetail?.manager) {
            const defaultFormData = {};
            const settings = ["defaultView", "dayViewPreference", "emailNotification"];
            for (const setting of settings) {
                defaultFormData[setting] = userDetail?.manager[setting];
            }
            setFormData(defaultFormData);
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>User Preference</h2>
                    <p className="modal-subtitle mb-0">Customize your expereince with DoubleSpot!</p>
                    <hr/>
                    <div class="w-25 pb-3">
                        <div class="py-1"><h4>Calendar</h4></div>
                        <div class="pb-2">
                            <CFormLabel htmlFor="defaultView">
                                <div style={{fontWeight: 500}}>Default View</div>
                            </CFormLabel>
                            <CFormSelect name="defaultView"
                                value={formData?.defaultView} onChange={updateForm}>
                                <option value="0">Month View</option>
                                <option value="1">Day View</option>
                            </CFormSelect>
                        </div>
                        <div class="pb-2">
                            <CFormLabel htmlFor="dayViewPreference">
                                <div style={{fontWeight: 500}}>Day View Layout</div>
                            </CFormLabel>
                            <CFormSelect name="dayViewPreference"
                                value={formData?.dayViewPreference} onChange={updateForm}>
                                <option value="0">Vertical</option>
                                <option value="1">Horizontal</option>
                            </CFormSelect>
                        </div>
                    </div>
                    <div>
                        <div class="py-1"><h4>Email</h4></div>
                        <div class="py-2">
                            <div class="flex-row">
                                <CFormLabel htmlFor="emailNotification" className="mb-0">
                                    <div className="edit-venue-form-label pe-3">Email Notification</div>
                                </CFormLabel>
                                <CFormSwitch name="emailNotification" checked={formData?.emailNotification} onChange={updateForm}/>
                            </div>
                            <p style={{color: "grey"}}>Turn on notification to receive emails about booking request.</p>
                        </div>
                    </div>
                    <div class="flex-row pt-1">
                        <div style={{width: "70px"}}>
                            <CButton type="submit" style={{backgroundColor: "#ff7e67", color: "white"}}
                                        onClick={submitForm}>Save</CButton>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
};

export default UserPreference;
