
import React, { useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DEFAULT_URL, setAlert } from '../../redux/action/action';

const ContactUs = () => {
    document.title = "Contact Us | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Make contact with DoubleSpot as a venue provider and establish connections today!"
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData , setFormData ] = useState({})
    const handleChange = (e) => setFormData({...formData , [e.target.name] : e.target.value});

    const submitForm = (e) => {
        e.preventDefault();
        axios.post(`${DEFAULT_URL}addContactus`,formData)
            .then(function (response) {
                dispatch(setAlert(response.data.message, 'success'));
            })
            .catch(function (error) {
                dispatch(setAlert(error.data.message, 'success'));
            });

        navigate('/provider/home')
    }

    return (
        <>



            <section class="contact_us pb-2">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-lg-5 col-md-6">
                            <div class="contact_detail">
                                <div class="title">
                                    Contact Us:
                                </div>
                                <div class="contact_icon">
                                    <div class="box">
                                        <i><img src="../assets/icons/call.png" alt="" /></i>
                                        <div class="text">
                                            <h4>Phone</h4>
                                            <small>(+1) 123 45 678 - 987 650 4321</small>
                                        </div>
                                    </div>
                                    <div class="box">
                                        <i><img src="../assets/icons/message_icon2.png" alt="" /></i>
                                        <div class="text">
                                            <h4>Email</h4>
                                            <small>info@example.com</small>
                                        </div>
                                    </div>
                                    <div class="box">
                                        <i><img src="../assets/icons/location.png" alt="" /></i>
                                        <div class="text">
                                            <h4>Address</h4>
                                            <small>9907 Salford road, east London Uk 2807</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <form onSubmit={(e) => submitForm(e)}>
                                <div class="form-group">
                                    <label for="text">Name</label>
                                    <input type="text" name="full_name" className="form-control box_shadow" onChange={(e) => handleChange(e)} placeholder="Enter Name" required />
                                </div>
                                <div class="form-group">
                                    <label for="text">Email</label>
                                    <input type="email" name="email" className="form-control box_shadow"  onChange={(e) => handleChange(e)} placeholder="Enter Email" required />
                                </div>
                                <div class="form-group">
                                    <label for="text">Message</label>
                                    <textarea name="msg" id="tarea"  onChange={(e) => handleChange(e)} className="form-control box_shadow"
                                              placeholder="Write here.." required></textarea>
                                </div>
                                <div class="form-group">
                                    <button type="submit" className="more_btn ms-auto">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>


            <div class="location_map pb-md-3">
                <iframe class="border-0 w-100"
                        src="https://maps.google.com/maps?q=New York, NY, USA&t=&z=8&ie=UTF8&iwloc=&output=embed"
                        frameborder="0" style={{height: '350px'}} allowfullscreen="" aria-hidden="false"
                        tabindex="0"></iframe>
            </div>

        </>
    )
}

export default ContactUs
