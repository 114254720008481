import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_PLACES_API_KEY } from "../../../redux/action/action";
import { useNavigate } from "react-router-dom";

const BannerSection = ({ title, showSearchBar, imageSource }) => {

    const [formData, setFormData] = useState({})
    const [searchPlace, setPlace] = useState(null);
    const [locationChanged, setLocationChanged] = useState(false);
    const navigate = useNavigate()

    const handlePlaceSelect = (place) => {
        setPlace(place?.formatted_address);
    };

    const handleData = (e) => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    })

    useEffect(() => {
        formData.place = "Champaign, IL, USA"
        localStorage.setItem('searchData', JSON.stringify(formData))
    }, [])

    const searchVenue = (e) => {
        e.preventDefault()
        if (locationChanged == true){
            formData.place = searchPlace
        }
        localStorage.setItem('searchData', JSON.stringify(formData))
        navigate('/search-page')
    }

    return (
        <>
            <section className="banner">
                <div id="carouselExampleDark" className="carousel carousel-dark slide">

                    <div className="carousel-inner position-relative">
                        <div className={`carousel-item ${showSearchBar ? "tall" : "short"} active`} data-bs-interval="10000">
                            <img src={imageSource || "../../../assets/images/collage.png"} className="d-block w-100" alt="doublespot.com" />
                        </div>
                    </div>
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-xl-10 col-lg-11">
                                    <h1>{title || "Create Memories With Us"}</h1>
                                    {showSearchBar ? <form onSubmit={(e) => searchVenue(e)}
                                           className=" banner_form d-md-flex align-itmes-center justify-content-between px-3 overflow-hidden">
                                        <div className="form-floating w-100">
                                            <Autocomplete
                                                apiKey={GOOGLE_PLACES_API_KEY}
                                                onPlaceSelected={(place) => {
                                                    handlePlaceSelect(place);
                                                }}
                                                value={(locationChanged == true || searchPlace) ? searchPlace : ""}
                                                onChange={(e) => {
                                                    setPlace(e.target.value);
                                                    setLocationChanged(true);
                                                    console.log(searchPlace)
                                                }}
                                                placeholder="Enter the location"
                                                required="true"
                                                className="form-control h-100 border-0 bg-transparent border-end placeHolder"
                                                id="date"
                                                style={{paddingTop: '2rem'}}
                                            />
                                            <label for="floatingSelect" style={{color: "black"}}>Where?</label>
                                        </div>
                                        <div className="form-floating w-100">
                                            <input
                                                type="date"
                                                className="form-control h-100 border-0 bg-transprent border-end"
                                                id="date"
                                                name="date"
                                                // required="true"
                                                onChange={handleData}
                                                style={{paddingTop: "2rem"}}
                                            />
                                            <label for="date" style={{color: "black"}}>Date</label>
                                        </div>
                                        <div className="form-floating w-100">
                                            <select
                                                className="form-select h-100 border-0 bg-transprent"
                                                id="floatingSelect3"
                                                aria-label="Floating label select example"
                                                name="size"
                                                onChange={handleData}
                                                style={{paddingTop: "2rem"}}
                                                // required="true"
                                            >
                                                <option value="10">1-10 people</option>
                                                <option value="30">11-30 people</option>
                                                <option value="50">30-50 people</option>
                                                <option value="100">50-100 people</option>
                                                <option value="101">100+ people</option>
                                            </select>
                                            <label for="floatingSelect3" style={{color: "black"}}>Party size</label>
                                        </div>
                                        <div className="btn_group ">
                                            <button type="submit" className="btn"><i
                                                className="fa-solid fa-magnifying-glass"></i> <span
                                                className="d-md-none">Search</span></button>
                                        </div>
                                    </form> : null}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}


export default BannerSection;