import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React from "react";

const Option = props => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                &nbsp;
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const MultiSelectCheckbox = ({ value, name, onChange, options, styles, creatable, onCreateOption }) => {
    return creatable ? (
        <CreatableSelect
            isMulti
            closeMenuOnSelect={false}
            menuShouldScrollIntoView={false}
            hideSelectedOptions={false}
            value={value}
            name={name}
            onChange={onChange}
            options={options}
            styles={styles}
            components={{Option}}
            onCreateOption={onCreateOption}
        />
    ) : (
        <ReactSelect
            isMulti
            closeMenuOnSelect={false}
            menuShouldScrollIntoView={false}
            hideSelectedOptions={false}
            value={value}
            name={name}
            onChange={onChange}
            options={options}
            styles={styles}
            components={{Option}}
        />
    );
}

export default MultiSelectCheckbox;
