import DataTable from "react-data-table-component";
import React from "react";
import {useNavigate} from "react-router-dom";
import {CButton} from "@coreui/react";
import {columns} from "../../../../Utils/ProgramUtils";

const SessionList = ({ data, onTaskStatusChange }) => {

    const navigate = useNavigate();
    const customStyles = {
        headRow: {
            style: {
                backgroundColor: '#f5f5f5',
                minHeight: "36px",
            },
        },
        rows: {
            style: {
                backgroundColor: '#fafafa',
                cursor: "pointer"
            },
        },
    };
    
    const onRowClicked = (row, event) => {
        navigate("/business/programs/session/1");
    }

    return <DataTable
        columns={[
            {
                name: "",
                selector: () => "",
                width: "96px",
            },
            ...columns(onTaskStatusChange),
            {
                name: '',
                cell: row => (
                        <div class="py-2">
                            <CButton color="dark" variant="outline">Edit</CButton>
                        </div>
                ),
                Width: "90px",
                right: true,
            }
        ]}
        data={data?.sessions || []}
        customStyles={customStyles}
        highlightOnHover={true}
        onRowClicked={onRowClicked}
    />
}

export default SessionList;
