export const bookingsToCalendarEvents = (bookings, eventTypeFilter, venueFilter, showPending=true) => {
    return bookings
        .filter(
            booking => venueFilter[booking.venueId]
        )
        .filter(
            booking => {
                if (!booking.eventtype_id) {
                    return eventTypeFilter[0];
                } else {
                    return eventTypeFilter[booking.eventtype_id]
                }
            }
        )
        .filter(
            booking => showPending || booking.status !== 2
        )
        .map(
            booking => {
                let colorIndex = booking.eventtype_color;
                return {
                    title: booking.title,
                    start: `${booking.date}T${booking.start_time}`,
                    end: `${booking.end_date}T${booking.end_time}`,
                    modalId: booking.id,
                    resourceId: booking.venueId,
                    description: `Venue booked by ${booking.booked_by_name} with payment ${booking.booked_price}$`,
                    classNames: [
                        booking.status === 1 || booking.status === 3 ? "confirmed" : "pending",
                        `calendar-event-color-${colorIndex ? colorIndex : "gray"}`
                    ],
                };
            }
        );
};
