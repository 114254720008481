import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import TimePicker from "../../../components/TimePicker";
import {weekDays} from "../../../../Utils/DateTimeUtils";
import {useState} from "react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";

const AddSessionModal = ({ visible, onClose, sessionFormData, setSessionFormData, venues, eventTags, setEventTags}) => {
    const blockTimeRepeatDaysDefault = {};
    weekDays.map(weekDay => {
        blockTimeRepeatDaysDefault[`repeat${weekDay}`] = false;
    });

    const [blockTimeRepeatDays, setBlockTimeRepeatDays] = useState(blockTimeRepeatDaysDefault);

    const updateSessionForm = (event) => {
        let newValue = event.target.value;

        if (event.target.name === "repeat") {
            newValue = event.target.checked;
            if (event.target.checked) {
                if (sessionFormData.date) {
                    const day = (new Date(sessionFormData.date)).getUTCDay();
                    setBlockTimeRepeatDays({...blockTimeRepeatDaysDefault, [`repeat${weekDays[day]}`]: true})
                }
            } else {
                setBlockTimeRepeatDays(blockTimeRepeatDaysDefault);
            }
        } else if (event.target.name === "date") {
            const day = (new Date(event.target.value)).getUTCDay();
            setBlockTimeRepeatDays({...blockTimeRepeatDaysDefault, [`repeat${weekDays[day]}`]: true})
        }
        setSessionFormData({...sessionFormData, [event.target.name]: newValue});
    }

    const updateSessionFormMultiselect = (selectedOptions, actionMeta) => {
        setSessionFormData({...sessionFormData, [actionMeta.name]: selectedOptions});
    }

    const updateSessionFormTimePicker = (selectedOption, actionMeta) => {
        setSessionFormData({...sessionFormData, [actionMeta.name]: selectedOption.value});
    }

    const onCreateOption = (name) => {
        setEventTags([...eventTags, {
            id: name,
            name,
        }]);

        const newTag = {
            label: name,
            value: name,
        }
        setSessionFormData({
            ...sessionFormData,
            tags: sessionFormData.tags ? [...sessionFormData.tags, newTag] : [newTag]
        });
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <div>
                    <CModalTitle>Add Session for Beginner Pickleball</CModalTitle>
                    <p className="modal-subtitle mb-0">Add a Session for Beginner Pickleball</p>
                </div>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="date">Start Date</CFormLabel>
                            <CFormInput required type="date" name="date" 
                                value={sessionFormData.date}
                                onChange={updateSessionForm}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                            <CFormInput required type="date" name="end_date" 
                                value={sessionFormData.end_date}
                                onChange={updateSessionForm}/>
                        </div>
                    </div>
                    <div className="flex-row align-items-center pb-3">
                        <div>For every</div>
                        <div class="px-1">
                            {
                                weekDays.map((weekDay) =>
                                    <div className="day-of-the-week-circle-container" onClick={
                                        () => setBlockTimeRepeatDays({
                                            ...blockTimeRepeatDays,
                                            [`repeat${weekDay}`]: !blockTimeRepeatDays[`repeat${weekDay}`]
                                        })
                                    }>
                                        <div
                                            className={`d-flex day-of-the-week-circle ${blockTimeRepeatDays[`repeat${weekDay}`] ? "active" : "inactive"}`}>
                                            {weekDay[0]}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="start_time">Start Time</CFormLabel>
                            <TimePicker name="start_time" 
                                value={sessionFormData.start_time}
                                onChange={updateSessionFormTimePicker}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="start_time">End Time</CFormLabel>
                            <TimePicker name="end_time" 
                                value={sessionFormData.end_time}
                                onChange={updateSessionFormTimePicker}/>
                        </div>
                    </div>
                    <CFormLabel htmlFor="instructor">Instructor</CFormLabel>
                    <div className="pb-3">
                        <CFormInput required type="text" name="instructor" onChange={updateSessionForm}/>
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="venue">Venue</CFormLabel>
                        <MultiSelectCheckbox
                            name="venue"
                            onChange={updateSessionFormMultiselect}
                            value={sessionFormData.venue}
                            options={venues.map(venue => ({
                                label: venue.name,
                                value: venue.id,
                            }))}
                        />
                    </div>
                    <CFormLabel htmlFor="size">Size</CFormLabel>
                    <div className="flex-row align-items-center pb-3">
                        <div className="pe-2">
                            <CFormInput type="number" required min="0" name="size"
                                        onChange={updateSessionForm} style={{width: "100px"}}/>
                        </div>
                        <span>people</span>
                    </div>
                    <CFormLabel htmlFor="price">Price</CFormLabel>
                    <div className="flex-row align-items-center pb-3">
                        <span>$</span>
                        <div className="px-2">
                            <CFormInput type="number" required min="0" name="price"
                                        onChange={updateSessionForm} style={{width: "100px"}}/>
                        </div>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default AddSessionModal;