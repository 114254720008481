import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
    CFormLabel,
    CFormSwitch,
    CImage
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {setAlert, SITE_URL} from "../../../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import {users} from "constants";

const CreateProgram = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Login.userDetail);
    const [formData, setFormData] = useState({});
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');
    const [pageTitle, setPageTitle] = useState('Create Program')

    const updateForm = (event) => {
        setFormData({...formData, 
                    [event.target.name]: event.target.value
                    });
    }

    const updateImage = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            setFormData({...formData, image: file});
            fileReader.onload = () => setImage(fileReader.result);
            fileReader.readAsDataURL(file);
        }
    }

    const submitForm = () => {
        //create new program or edit program
    }

    useEffect(() => {
        const programId = parseInt(document.location.pathname.split('/')[4]);
        if (programId) {//check if have program id
            document.title = `Edit Program: Beginner Pickleball | DoubleSpot Business`;
            document.querySelector('meta[name="description"]').setAttribute(
                "content",
                `Edit program Beginner Pickleball with DoubleSpot's business dashboard.`
            );
            setPageTitle("Edit Program");
            //retrieve current program detail
            setName("Beginner Pickleball");
            setDescription(`Join our beginner boot camp! You will learn the basic stroke mechanics,\
                rules/scoring, and basic shots needed to get you playing matches on your own! No equipment needed!`);
            setImage('https://i.postimg.cc/cJ8ZG5ms/365368536.jpg');
        } else {
            document.title = `Create Program | DoubleSpot Business`;
            document.querySelector('meta[name="description"]').setAttribute(
                "content",
                `Create New Program with DoubleSpot's business dashboard.`
            );
        }
    }, []);//trigger: programDetail

    // useEffect(() => {
    //     if (updateProgramSuccess || createProgramSuccess) {
    //         navigate("/business/programs/list");
    //     }
    // }, [updateProgramSuccess, createProgramSuccess]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.programAccess) {
                dispatch(setAlert('You do not have the permissions to edit or create a program.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>{pageTitle}</h2>
                    <hr/>
                    <div class="w-50">
                        <div class="py-2">
                            <CFormLabel htmlFor="name">
                                <div className="edit-venue-form-label">Name</div>
                            </CFormLabel>
                            <CFormInput type="text" name="name" placeholder="Beginner Tennis"
                                        defaultValue={name} onChange={updateForm}/>
                        </div>
                        <div class="py-2">
                            <CFormLabel htmlFor="description">
                                <div className="edit-venue-form-label">Description</div>
                            </CFormLabel>
                            <ReactQuill id="description-editor" theme="snow" name="description" value={description}
                                        placeholder="Enter a short description for this program..."
                                        onChange={(value, delta, source, editor) => setDescription(value)}/>
                        </div>
                        <div class="flex-row py-2 align-items-center">
                            <div class="col-8">
                                <CFormLabel htmlFor="business_banner">
                                    <div className="edit-venue-form-label">Image</div>
                                </CFormLabel>
                                <CFormInput type="file" name="business_banner" accept="image/*" onChange={updateImage}/>
                            </div>
                            <div class="px-3">
                                {image ? 
                                    <CImage src={image} thumbnail/> :
                                    null
                                }
                            </div>
                        </div>
                        <div class="py-2 flex-row">
                            <CFormLabel htmlFor="public">
                                <div className="edit-venue-form-label pe-3">Make Public</div>
                            </CFormLabel>
                            <CFormSwitch name="public"/>
                        </div>
                        <div class="flex-row pt-3">
                            <div style={{width: "85px"}}>
                                <CButton type="submit" style={{backgroundColor: "#ff7e67", color: "white"}}
                                         onClick={submitForm}>Submit</CButton>
                            </div>
                            <div class="flex-column">
                                <CButton color="secondary" type="button" variant="outline"
                                         role="button" onClick={() => navigate("/business/programs/list ")}>Cancel</CButton>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
}

export default CreateProgram;
