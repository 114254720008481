import {CButton} from "@coreui/react";

export const columns = (onEditButtonClick, onDeleteButtonClick) => [
    {
        name: 'Name',
        selector: row => row.name,
        width: "200px",
        wrap: true,
        sortable: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onEditButtonClick(row)}>
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    }
];

export const applyFilters = (eventTags, filters) => {
    return eventTags.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(entry.name.toLowerCase().includes(searchTerm))) {
                return false;
            }
        }

        return true;
    });
};
