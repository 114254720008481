import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProviderProfile, setAlert} from "../../../redux/action/action";
import {
    CCard,
    CCardBody,
    CCardText,
    CCol,
    CContainer,
    CProgress,
    CRow,
} from "@coreui/react";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const SubscriptionPlan = () => {
    document.title = "Orgainzation Subscription Plan | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View your organization's subscription plan with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const providerDetail = useSelector(state => state.Apis.providerDetail);
    const [businessPlan, setBusinessPlan] = useState({});

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editBusinessAccess) {
                dispatch(setAlert('You do not have the permissions to view your organization\'s DoubleSpot subscription plan.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        if (userDetail?.bussnessId || userDetail.role === users.ROLE_VENUE_OWNER) {
            dispatch(getProviderProfile());
                }
    }, [userDetail])

    useEffect(() => {
        setBusinessPlan(providerDetail?.business_plan);
    }, [providerDetail])

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Subscription Plan</h2>
                    <hr/>
                    <CContainer className="pt-1 px-3">
                        <CCard className="my-3 box-shadow-5">
                            <CCardBody>
                                <CCardText>
                                    <CRow className="px-3">
                                        <CCol xs={4}><h4 className="m-1">Manager Accounts</h4></CCol>
                                        <CCol xs={6} className="align-self-center pe-5">
                                            <CProgress 
                                                value={businessPlan?.managers ? 
                                                    100 * (parseInt(businessPlan?.managers.length) / parseInt(businessPlan?.manager_max_count)) :
                                                    0
                                                } 
                                                height={12}
                                            />
                                        </CCol>
                                        <CCol xs={2} className="align-self-center">
                                            <span className="m-1 font-19">
                                                {businessPlan?.managers ? businessPlan?.managers.length : 0}/{businessPlan?.manager_max_count} Created
                                            </span>
                                        </CCol>
                                    </CRow>
                                </CCardText>
                            </CCardBody>
                        </CCard>
                        <CCard className="mt-3 mb-0 box-shadow-5">
                            <CCardBody>
                                <CCardText>
                                    <CRow className="px-3">
                                        <CCol xs={4}><h4 className="m-1">Venue Profiles</h4></CCol>
                                        <CCol xs={6} className="align-self-center pe-5">
                                            <CProgress 
                                                value={businessPlan?.venues ?
                                                    100 * (parseInt(businessPlan?.venues.length) / parseInt(businessPlan?.venue_max_count)) :
                                                    0
                                                } 
                                                height={12}
                                            />
                                        </CCol>
                                        <CCol xs={2} className="align-self-center">
                                            <span className="m-1 font-19">
                                                {businessPlan?.venues ? businessPlan?.venues.length : 0}/{businessPlan?.venue_max_count} Created
                                            </span>
                                        </CCol>
                                    </CRow>
                                </CCardText>
                            </CCardBody>
                        </CCard>
                        <CRow>
                            <CCol xs={4}>
                                <CCard className="my-3 box-shadow-5">
                                    <CCardBody>
                                        <CCardText className="ps-3">
                                                <h4 className="m-1">Online Payment System</h4>
                                                {businessPlan?.process_payment ?
                                                    <span className="m-1 align-self-center primary-color font-19">Enabled</span>
                                                    : <span className="m-1 darkgray font-19">Disabled</span>
                                                }       
                                        </CCardText>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol xs={8}>
                                <CCard className="my-3 box-shadow-5">
                                    <CCardBody>
                                        <CCardText className="ps-3">
                                            <CRow> 
                                                <CCol xs={4} className="align-self-center">
                                                    <h4 className="m-1">DoubleSpot AI</h4>
                                                </CCol>
                                                <CCol xs={1} className="ps-4 pe-0" style={{height: "65px"}}>
                                                        <div class="vr" style={{height: "4em"}}></div>
                                                </CCol>
                                                <CCol xs={7} className="ps-0">
                                                    <CRow>
                                                        <CCol xs={8}><h5 className="mb-1 mt-1">Custom GPT</h5></CCol>
                                                        <CCol xs={4}>
                                                            {businessPlan?.chatbotId ?
                                                                <span className="m-1 align-self-center primary-color font-19">Enabled</span>
                                                                : <span className="mb-1 mt-1 darkgray font-19">Disabled</span>
                                                            }  
                                                        </CCol>
                                                    </CRow>
                                                    <CRow>
                                                        <CCol xs={8}><h5 className="mb-1 mt-1">Custom GPT Leads</h5></CCol>
                                                        <CCol xs={4}>
                                                            {businessPlan?.chatbot_leads ?
                                                                <span className="m-1 align-self-center primary-color font-19">Enabled</span>
                                                                : <span className="mb-1 mt-1 darkgray font-19">Disabled</span>
                                                            }  
                                                        </CCol>
                                                    </CRow>
                                                </CCol>
                                            </CRow>
                                        </CCardText>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow> 
                    </CContainer>
                </CCardBody>
            </CCard>
        </div>
    );
}

export default SubscriptionPlan;
