import {CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {useEffect, useState} from "react";
import {
    cilArrowThickBottom,
    cilArrowThickLeft,
    cilArrowThickRight,
    cilArrowThickToBottom,
    cilArrowThickToLeft,
    cilArrowThickTop,
    cilArrowThickToRight,
    cilArrowThickToTop,
    cilXCircle,
} from "@coreui/icons";
import {createPortal} from "react-dom";

const ResourceCell = ({ resource, calendarView, resources, setResources, venueFilter, setVenueFilter }) => {
    const [isHorizontal, setIsHorizontal] = useState(false);
    const [isFirst, setIsFirst] = useState(false);
    const [isLast, setIsLast] = useState(false);
    const [isOnly, setIsOnly] = useState(false);

    const frontButtonClick = () => {
        let newResources = resources;
        const currentIndex = resource.extendedProps.order;
        newResources = [
            newResources[currentIndex],
            ...newResources,
        ];
        newResources.splice(currentIndex + 1, 1);
        setResources(newResources.map((entry, index) => ({
            ...entry,
            order: index,
        })));
    };

    const forwardButtonClick = () => {
        const newResources = resources;
        const currentIndex = resource.extendedProps.order;
        if (currentIndex === 0) return;
        const temp = newResources[currentIndex];
        newResources[currentIndex] = newResources[currentIndex - 1];
        newResources[currentIndex - 1] = temp;
        setResources(newResources.map((entry, index) => ({
            ...entry,
            order: index,
        })));
    };

    const backwardButtonClick = () => {
        const newResources = resources;
        const currentIndex = resource.extendedProps.order;
        if (currentIndex === newResources.length - 1) return;
        const temp = newResources[currentIndex];
        newResources[currentIndex] = newResources[currentIndex + 1];
        newResources[currentIndex + 1] = temp;
        setResources(newResources.map((entry, index) => ({
            ...entry,
            order: index,
        })));
    };

    const endButtonClick = () => {
        let newResources = resources;
        const currentIndex = resource.extendedProps.order;
        newResources = [
            ...newResources,
            newResources[currentIndex],
        ];
        newResources.splice(currentIndex, 1);
        setResources(newResources.map((entry, index) => ({
            ...entry,
            order: index,
        })));
    };

    const removeButtonClick = () => {
        const newResources = resources;
        const currentIndex = resource.extendedProps.order;
        newResources.splice(currentIndex, 1);
        setResources(newResources.map((entry, index) => ({
            ...entry,
            order: index,
        })));
        setVenueFilter({
            ...venueFilter,
            [resource.id]: false,
        });
    };

    useEffect(() => {
        setIsHorizontal(calendarView === "resourceTimelineDay");
    }, [calendarView]);

    useEffect(() => {
        const order = resource.extendedProps.order;
        setIsFirst(order === 0);
        setIsLast(order === resources.length - 1);
        setIsOnly(resources.length === 1);
    }, [resource.extendedProps.order, resources]);

    return (
        <div
            className="flex-row justify-content-between align-items-center ps-3 pe-2"
            style={{ minHeight: isHorizontal ? "50px" : undefined }}
        >
            <div>{resource.title}</div>
            <CDropdown className="px-2">
                <CDropdownToggle color="light">Order</CDropdownToggle>
                {/*createPortal is being used because all other methods hide the dropdown behind the cell*/}
                {
                    createPortal(
                        <CDropdownMenu>
                            <CDropdownItem role="button" onClick={frontButtonClick} disabled={isFirst}>
                                <CIcon icon={isHorizontal ? cilArrowThickToTop : cilArrowThickToLeft} className="primary-color"/>
                                &nbsp;Bring To Front
                            </CDropdownItem>
                            <CDropdownItem role="button" onClick={forwardButtonClick} disabled={isFirst}>
                                <CIcon icon={isHorizontal ? cilArrowThickTop : cilArrowThickLeft} className="primary-color"/>
                                &nbsp;Bring Forward
                            </CDropdownItem>
                            <CDropdownItem role="button" onClick={backwardButtonClick} disabled={isLast}>
                                <CIcon icon={isHorizontal ? cilArrowThickBottom : cilArrowThickRight} className="primary-color"/>
                                &nbsp;Send Backward
                            </CDropdownItem>
                            <CDropdownItem role="button" onClick={endButtonClick} disabled={isLast}>
                                <CIcon icon={isHorizontal ? cilArrowThickToBottom : cilArrowThickToRight} className="primary-color"/>
                                &nbsp;Send To Back
                            </CDropdownItem>
                            <CDropdownDivider/>
                            <CDropdownItem role="button" onClick={removeButtonClick} disabled={isOnly}>
                                <CIcon icon={cilXCircle} className="primary-color"/>
                                &nbsp;Remove
                            </CDropdownItem>
                        </CDropdownMenu>,
                        document.body,
                    )
                }
            </CDropdown>
        </div>
    );
};

export default ResourceCell;
