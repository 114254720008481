import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {  Provider } from 'react-redux';
import { combineReducers ,createStore , applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga'
import reportWebVitals from './reportWebVitals';
import Login from './redux/reducers/login'
import Alert from './redux/reducers/Alert'
import Apis from './redux/reducers/Apis'
import HandleModals from './redux/reducers/HandleModals'
import App from './App';
import rootSaga from './redux/saga/rootSaga';
import { useLocation } from 'react-router-dom';
const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({Login,Alert,Apis,HandleModals})
const store = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
        <App/>
      </Provider>
  </React.StrictMode>
);


reportWebVitals();
