import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail } from "../../redux/action/action";
import ErrorPage from "../../ErrorPage";
import Loading from "./Loading";
import VerificationSuccess from "./VerificationSuccess";

function VerifyEmail() {
    const dispatch = useDispatch();
    const authenticated = useSelector(state => state.Login.isUserAuthenticated);
    const [verified, setVerified] = useState(null);

    useEffect(() => {
        const pathArray = window.location.pathname.split('/');
        const data = {
            id: pathArray[2],
            link: pathArray[3]
        };
        dispatch(verifyEmail(data));
    }, []);

    useEffect(() => {
        console.log("USER DETAIL UPDATED!", JSON.stringify(authenticated));
        setVerified(authenticated);
    }, [authenticated]);

    console.log("VERIFIED?", JSON.stringify(verified));
    if (verified == null) {
        return <><Loading></Loading></>
    }
    if (verified) {
        return <><VerificationSuccess></VerificationSuccess></>
    } else {
        return <><ErrorPage></ErrorPage></>
    }
}

export default VerifyEmail;
