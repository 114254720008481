import React from "react";
import { Link } from "react-router-dom";
import './App.css'

function ErrorPage() {
    document.title = "Not Found | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Oops! You've reached DoubleSpot's 404 page."
    );

    return (<>
        <section class="page_404">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-10 ">
                        <div class="four_zero_four_bg">
                            <h1 class="text-center ">404</h1>
                        </div>
                        <div class="content_box_404 text-center">
                            <h3 className="h2 ">
                                Look like you're lost
                            </h3>
                            <p className="">The page you are looking for is not available.</p>
                            <Link to={'/'}> <a  class="link_404">Go to Home</a></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default ErrorPage