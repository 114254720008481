import {  put, takeEvery, takeLatest } from 'redux-saga/effects';
import { SHOW_ALERT, SHOW_ALERT_SUCCESS } from '../action/action';

function* setAlertSaga(action) {
  yield console.log("Call the action " , action.type ,action.payload)
  yield put({type: SHOW_ALERT_SUCCESS , payload:action.payload})
}

function* alertSaga() {
  yield takeEvery(SHOW_ALERT, setAlertSaga);
}

export default alertSaga;